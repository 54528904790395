import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgFocusedHomeIcon = props => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M12 2 3 8.667V22h6V12h6v10h6V8.667L12 2Z"
      fill="#27332F"
    />
  </Svg>
);

export default SvgFocusedHomeIcon;
