import styled from '@emotion/native';
import {CustomText as Text} from '../..//Home/CustomText';

const FilterViewPressable = styled.TouchableOpacity`\
  margin-right: 8px;
`;

const FilterArea = styled.View`
  margin-bottom: 24px;
`;
const FilterHeadView = styled.View``;
const FilterHeadText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.body16};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const FilterMenuView = styled.View`
  flex-direction: row;
  flex-wrap: wrap;
`;

const SortMenuView = styled.View`
  flex-wrap: wrap;
`;

const SortItemForm = styled.TouchableOpacity<{isActive: boolean}>`
  height: 40px;
  width: 100%;
  background: ${({theme, isActive}) =>
    isActive ? theme.colors.prapBlue1 : null};
  justify-content: center;

  padding: 0 8px;
  border-radius: 8px;
`;

const FilterItemForm = styled.TouchableOpacity<{isActive: boolean}>`
  font-size: 12px;
  background: ${({theme, isActive}) =>
    isActive ? theme.colors.prapBlue4 : theme.colors.prapGray6};

  padding: 6px 16px;
  margin-right: 8px;
  border-radius: 20px;
  margin-top: 12px;
`;
const ScrollItemText = styled(Text)<{isActive: boolean}>`
  color: ${({theme, isActive}) =>
    isActive
      ? process.env.FLAVOR === 'namuh' || process.env.FLAVOR === 'qv'
        ? theme.colors.infoWhite
        : theme.colors.prapBlue1
      : theme.colors.prapGray2};
  font-family: 'SpoqaHanSansNeo-Medium';
`;

const FundedHeader = styled.View`
  padding-top: 24px;
  padding-bottom: 24px;
  padding-left: 20px;
  background: #fff;

  flex-direction: row;
`;

const SortText = styled(Text)<{isSort: string}>`
  color: ${({theme, isSort}) =>
    isSort === '정렬' ? theme.colors.prapGray1 : theme.colors.prapGray5};
  font-size: ${({theme}) => theme.fonts.headline18};
  font-family: 'SpoqaHanSansNeo-Bold';
`;

const FilterText = styled(Text)<{isSort: string}>`
  color: ${({theme, isSort}) =>
    isSort === '필터' ? theme.colors.prapGray1 : theme.colors.prapGray5};
  font-size: ${({theme}) => theme.fonts.headline18};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const FundedProductView = styled.View`
  margin-bottom: 100px;
  padding: 0 15px;
`;

const SelectProductCTA = styled.TouchableOpacity`
  flex: 3;
  padding: 14px;
  background-color: ${({theme}) => theme.colors.prapBlue2};
  border-radius: 50px;
  align-items: center;
  right: 0;
`;

const SelectProductText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray6};
  font-size: ${({theme}) => theme.fonts.body16};
  font-family: 'SpoqaHanSansNeo-Bold';
`;

const SelectProductArea = styled.View`
  align-items: center;
  flex-direction: row;
`;
const FixedArea = styled.View`
  position: absolute;
  bottom: 0;
  width: 100%;
  padding: 10px 20px 20px;
  background-color: white;
`;
const InitiallizeFilterCTA = styled.TouchableOpacity`
  flex: 2;
  padding: 14px;
  background-color: #fff;
  border-radius: 50px;
  align-items: center;
`;
const InitiallizeFilterText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-family: 'SpoqaHanSansNeo-Bold';
`;

export {
  FilterViewPressable,
  FilterArea,
  FilterHeadView,
  FilterHeadText,
  FilterMenuView,
  SortMenuView,
  SortItemForm,
  FilterItemForm,
  ScrollItemText,
  FundedHeader,
  SortText,
  FilterText,
  FundedProductView,
  SelectProductArea,
  SelectProductCTA,
  SelectProductText,
  FixedArea,
  InitiallizeFilterCTA,
  InitiallizeFilterText,
};
