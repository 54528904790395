import {
  CATEGORY,
  FRACTIONAL_PLATFORM,
  P2P_PLATFORM,
  SNEAKERS_BRAND,
  SNEAKERS_PLATFORM,
  SNEAKERS_PRICE,
  SORT_OPTION,
  STARTUP_PLATFORM,
  UNLISTED_PLATFORM,
} from './constants';

export const korToEng = {
  [CATEGORY.FRACTIONAL]: 'fractional-investments',
  [CATEGORY.P2P]: 'p2p-investments',
  [CATEGORY.SNEAKERS]: 'sneakers',
  [CATEGORY.STARTUP]: 'startups',
  [CATEGORY.UNLISTED]: 'unlisted-stocks',
  //P2P
  [P2P_PLATFORM.DAILY_FUND]: 'dailyfunding',
  [P2P_PLATFORM.V_FUNDING]: 'vfunding',
  [P2P_PLATFORM.PEOPLE_FUND]: 'peoplefund',
  [P2P_PLATFORM.HELLO_FUNDING]: 'hellofunding',
  [P2P_PLATFORM.EIGHT_PERCENT]: 'eightpercent',

  //Fractional
  [FRACTIONAL_PLATFORM.SOTWO]: 'sotwo',
  [FRACTIONAL_PLATFORM.ART_N_GUIDE]: 'artnguide',
  [FRACTIONAL_PLATFORM.ART_TOGOTHER]: 'arttogether',
  [FRACTIONAL_PLATFORM.TESSA]: 'tessa',
  [FRACTIONAL_PLATFORM.TREASURER]: 'treasurer',

  // Sneakers
  [SNEAKERS_PLATFORM.REPLE]: 'reple',
  [SNEAKERS_PLATFORM.KREAM]: 'kream',
  [SNEAKERS_PLATFORM.SOLD_OUT]: 'soldout',
  [SNEAKERS_BRAND.NIKE]: 'nike',
  [SNEAKERS_BRAND.ADIDAS]: 'adidas',
  [SNEAKERS_BRAND.VANS]: 'vans',
  [SNEAKERS_BRAND.CONVERSE]: 'converse',
  [SNEAKERS_BRAND.JORDAN]: 'jordan',
  [SNEAKERS_BRAND.ASICS]: 'asics',
  [SNEAKERS_BRAND.NEW_BALANCE]: 'newbalance',
  [SNEAKERS_PRICE.LE_100K]: '100000',
  [SNEAKERS_PRICE.LE_300K]: '100000-300000',
  [SNEAKERS_PRICE.LE_500K]: '300000-500000',
  [SNEAKERS_PRICE.ME_500K]: '500000',

  // Startup
  [STARTUP_PLATFORM.CROWDY]: 'crowdy',
  [STARTUP_PLATFORM.FUNDING_4U]: 'funding4u',
  [STARTUP_PLATFORM.OH_MY_COMPANY]: 'ohmycompany',
  [STARTUP_PLATFORM.OPEN_TRADE]: 'opentrade',
  [STARTUP_PLATFORM.WADIZ]: 'wadiz',

  //Unlisted
  [UNLISTED_PLATFORM.SEOUL_EXCHANGE]: 'seoulexchange',
  [UNLISTED_PLATFORM.U_STOCK_PLUS]: 'ustockplus',

  //Sort
  [SORT_OPTION.TOTAL_AMOUNT]: '-total_amount',
  [SORT_OPTION.L_TOTAL_AMOUNT]: 'total_amount',
  [SORT_OPTION.MIN_PRICE]: '-minimum_price',
  [SORT_OPTION.L_MIN_PRICE]: 'minimum_price',
  [SORT_OPTION.GOAL_PERCENT]: 'percent',
  [SORT_OPTION.L_GOAL_PERCENT]: '-percent',
  [SORT_OPTION.COUNT]: '-count',
  [SORT_OPTION.L_COUNT]: 'count',
  [SORT_OPTION.END_DATE]: '-end_date',
  [SORT_OPTION.START_DATE]: 'start_date',
  [SORT_OPTION.PRAP_POPULAR]: 'prap',
  [SORT_OPTION.L_SNKRS_PRICE]: 'price-low',
  [SORT_OPTION.H_SNKRS_PRICE]: 'price-high',
  [SORT_OPTION.PLATFORM_POPULAR]: 'popular',
  [SORT_OPTION.FEE]: '-fee',
  [SORT_OPTION.L_FEE]: 'fee',
};

export const engToKor = {
  'p2p-investments': [CATEGORY.P2P],
  'fractional-investments': [CATEGORY.FRACTIONAL],
  sneakers: [CATEGORY.SNEAKERS],
  'unlisted-stocks': [CATEGORY.UNLISTED],
  startups: [CATEGORY.STARTUP],

  dailyfunding: [P2P_PLATFORM.DAILY_FUND],
  vfunding: [P2P_PLATFORM.V_FUNDING],
  peoplefund: [P2P_PLATFORM.PEOPLE_FUND],
  hellofunding: [P2P_PLATFORM.HELLO_FUNDING],
  eightpercent: [P2P_PLATFORM.EIGHT_PERCENT],
  honestfund: [P2P_PLATFORM.HONEST_FUND],

  sotwo: [FRACTIONAL_PLATFORM.SOTWO],
  artnguide: [FRACTIONAL_PLATFORM.ART_N_GUIDE],
  arttogether: [FRACTIONAL_PLATFORM.ART_TOGOTHER],
  tessa: [FRACTIONAL_PLATFORM.TESSA],
  treasurer: [FRACTIONAL_PLATFORM.TREASURER],

  reple: [SNEAKERS_PLATFORM.REPLE],
  kream: [SNEAKERS_PLATFORM.KREAM],
  soldout: [SNEAKERS_PLATFORM.SOLD_OUT],

  crowdy: [STARTUP_PLATFORM.CROWDY],
  funding4u: [STARTUP_PLATFORM.FUNDING_4U],
  ohmycompany: [STARTUP_PLATFORM.OH_MY_COMPANY],
  opentrade: [STARTUP_PLATFORM.OPEN_TRADE],
  wadiz: [STARTUP_PLATFORM.WADIZ],

  ustockplus: [UNLISTED_PLATFORM.U_STOCK_PLUS],
  seoulexchange: [UNLISTED_PLATFORM.SEOUL_EXCHANGE],
  sampalcommunications: [UNLISTED_PLATFORM.SAMPAL_COMMUNICATIONS],
};
