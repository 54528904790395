import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgRemoveIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M12.667 2 2 12.667l.94.94L13.607 2.94l-.94-.94Z" fill="#8D8F98" />
    <Path d="M2.94 2 2 2.94l10.667 10.667.94-.94L2.94 2Z" fill="#8D8F98" />
  </Svg>
);

export default SvgRemoveIcon;
