import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import LoginButton from '../Login/LoginButton';
import SimpleList from '../SimpleList/SimpleList';
import {TabParamList} from '../TabScreen';

const MoreScreen = ({}: NativeStackScreenProps<TabParamList, 'More'>) => {
  return (
    <SafeAreaView>
      <SimpleList
        ListHeaderComponent={LoginButton}
        sections={[
          {
            data: [
              {
                label: '공지사항',
                linkTo: '/announcements',
              },
              {
                label: '이벤트',
                linkTo: '/promotions',
              },
            ],
          },
          {
            data: [
              {
                label: '서비스 이용안내',
                linkTo: '/privacy-and-terms',
              },
              {
                label: '앱 설정',
                linkTo: '/settings',
              },
            ],
          },
        ]}
      />
    </SafeAreaView>
  );
};

export default MoreScreen;
