import { useTheme } from '@emotion/react';
import * as React from 'react';
import Svg, { Circle, ClipPath, Defs, G, Path } from 'react-native-svg';

const SvgMoreIcon = props => {
  const theme = useTheme();
  return (
    <Svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <G clipPath="url(#MoreIcon_svg__a)" stroke={process.env.FLAVOR === 'qv' ? theme.colors.infoWhite: '#666'} strokeWidth={2}>
        <Circle cx={3} cy={12} r={2} />
        <Circle cx={12} cy={12} r={2} />
        <Circle cx={21} cy={12} r={2} />
      </G>
      <Defs>
        <ClipPath id="MoreIcon_svg__a">
          <Path fill={process.env.FLAVOR === 'qv' ? theme.colors.infoWhite: '#666'} transform="rotate(90 12 12)" d="M0 0h24v24H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

export default SvgMoreIcon;
