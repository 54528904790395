import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const SvgMoreRankingIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G clipPath="url(#MoreRankingIcon_svg__a)" fill="#666">
      <Path d="m3.825 7.42-2.536 4.392a.667.667 0 0 0 .62.999l.95-.061a.667.667 0 0 1 .64.37l.422.853a.667.667 0 0 0 1.175.037l2.336-4.044a4.635 4.635 0 0 1-1.328-.367l-1.551 2.687a2 2 0 0 0-1.498-.865l1.622-2.81a4.68 4.68 0 0 1-.852-1.19Z" />
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 8.667A3.333 3.333 0 1 0 8 2a3.333 3.333 0 0 0 0 6.667ZM8 10A4.667 4.667 0 1 0 8 .667 4.667 4.667 0 0 0 8 10Z"
      />
      <Path d="m10 9.551 1.578 2.735a2 2 0 0 1 1.497-.865l-1.671-2.895c.338-.36.62-.775.83-1.229l2.607 4.515a.667.667 0 0 1-.62.999l-.95-.061a.666.666 0 0 0-.64.37l-.422.853a.667.667 0 0 1-1.175.037L8.689 9.95A4.633 4.633 0 0 0 10 9.55Z" />
    </G>
    <Defs>
      <ClipPath id="MoreRankingIcon_svg__a">
        <Path fill="#fff" transform="rotate(90 8 8)" d="M0 0h16v16H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgMoreRankingIcon;
