import styled from '@emotion/native';
import React from 'react';
import {FlatList} from 'react-native';
import {ColumnSkeletonView} from '../Investments/style/Skeleton';
import {usePlatformList} from './api/usePlatformList';
import {PlatformsForm} from './PlatformsForm';
import {PlatformListSkeleton} from './style/Skeleton';

export default function Ranking({category}: {category: string}) {
  const platformRankingData = usePlatformList();

  console.log(platformRankingData);

  if (platformRankingData.isLoading) {
    return (
      <>
        <ColumnSkeletonView>
          <PlatformListSkeleton />
          <PlatformListSkeleton />
          <PlatformListSkeleton />
          <PlatformListSkeleton />
          <PlatformListSkeleton />
          <PlatformListSkeleton />
          <PlatformListSkeleton />
          <PlatformListSkeleton />
        </ColumnSkeletonView>
      </>
    );
  }

  return (
    <FlatList
      ListHeaderComponent={() => <></>}
      data={platformRankingData?.data?.pages[0].result[category]}
      renderItem={({item: platform, index: rank}) => (
        <PlatformsForm platform={platform} rank={rank} type="ranking" />
      )}
      ItemSeparatorComponent={ItemSeparator}
    />
  );
}

const ItemSeparator = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;
