import styled from '@emotion/native';
import {login} from '@react-native-seoul/kakao-login';
import React from 'react';
import {useToast} from 'react-native-toast-notifications';
import {CustomText as Text} from '../Home/CustomText';
import RightArrowIcon from '../More/RightArrowIcon';
const LoginButton = ({}: {}) => {
  const toast = useToast();

  return (
    <StyledLoginButton
      onPress={async () => {
        try {
          const token = await login();
          toast.show('Kakao logged in');
          console.log('kakao login token:', JSON.stringify(token));
        } catch (error) {
          toast.show('Kakao login error! ' + error);
        }
      }}>
      <StyledText>
        <Bold>로그인</Bold>을 해주세요
      </StyledText>
      <RightArrowIcon />
    </StyledLoginButton>
  );
};

const StyledLoginButton = styled.Pressable`
  margin: 28px 24px;
  flex-direction: row;
  align-items: center;
`;

const StyledText = styled(Text)`
  ${({theme}) => theme.fonts.body18};
  flex: 1;
`;

const Bold = styled(Text)`
  font-weight: bold;
`;

export default LoginButton;
