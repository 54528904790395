import styled from '@emotion/native';
import React, {ReactNode} from 'react';
import {InvestmentCarousel} from '../CarouselView/InvestmentCarousel';
import {useCurations} from './api/useCuration';
import {CustomText as Text} from './CustomText';
export const Curation = ({
  heading,
  fetchType,
  id,
}: {
  heading: ReactNode;
  fetchType: string;
  id: number;
}) => {
  const curationData = useCurations(id);

  return (
    <StyledView>
      <Heading>{heading}</Heading>
      <InvestmentCarousel
        pagesPerView={2.5}
        gap={16}
        fetchType={fetchType}
        data={curationData}
      />
    </StyledView>
  );
};

const StyledView = styled.View`
  margin-bottom: 50px;
`;

const Heading = styled(Text)`
  ${({theme}) => theme.fonts.headline18};
  ${({theme}) => theme.colors.prapGray1};
  padding: 24px 16px 12px;
  font-family: 'SpoqaHanSansNeo-Bold';
`;
