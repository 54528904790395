import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useEffect} from 'react';
import {ScrollView} from 'react-native-gesture-handler';
import {StackParamList} from '../App';
import {CustomText as Text} from '../Home/CustomText';
import {ListAnnouncement} from './AnnouncementsScreen';

const AnnouncementScreen = ({
  navigation,
  route,
}: NativeStackScreenProps<StackParamList, 'Announcement'>) => {
  useEffect(() => {
    navigation.setOptions({title: '곶감'});
  }, [navigation]);

  route.params.id;

  return (
    <ScrollView>
      <ListAnnouncement
        announcement={{
          title: '제목',
          id: route.params.id,
        }}
      />
      <Separator />
      <Body>
        안녕하세요 프랩입니다. 서비스를 이용해 주시는 회원 여러분께 감사드리며,
        [흑호의 곶감찾기] 이벤트 당첨자를 안내드립니다. 📍이벤트 당첨자
        송*지(4801) 송*지(4801) 송*지(4801) 송*지(4801) 언제나 회원님들의
        목소리에 귀 기울이며, 보다 나은 서비스를 이용할 수 있도록 최선을
        다하겠습니다. 감사합니다.
      </Body>
    </ScrollView>
  );
};

export default AnnouncementScreen;

export interface AnnouncementState {
  id: string;
  title: string;
  created?: string;
}

const Separator = styled.View`
  margin: 0 16px;
  height: 1px;
  background-color: ${({theme}) => theme.colors.prapGray5};
`;

const Body = styled(Text)`
  margin: 16px;
  ${({theme}) => theme.fonts.body13};
`;
