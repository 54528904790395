import styled from '@emotion/native';
import {ReactElement} from 'react';

const WebContainer = ({children}: {children: ReactElement}) => {
  return <StyledView>{children}</StyledView>;
};
export default WebContainer;

const StyledView = styled.View`
  // margin: 0 auto;
  height: 100%;
  // aspect-ratio: ${(9 / 21).toString()};
`;
