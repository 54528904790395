import styled from '@emotion/native';
import {CustomText as Text} from '../../Home/CustomText';

export const Thumbnail = ({image, rank}: {image: string; rank?: number}) => {
  return (
    <StyledThumbnail>
      <ThumbnailImage
        source={{uri: image === '' ? undefined : image}}
        // defaultSource={{uri: 'https://via.placeholder.com/150'}}
      />
      {rank !== undefined ? <Rank>{rank + 1}</Rank> : undefined}
    </StyledThumbnail>
  );
};

const StyledThumbnail = styled.View`
  width: 106px;
  height: 106px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;

const ThumbnailImage = styled.Image`
  resize-mode: contain;
  width: 100%;
  height: 100%;
`;

const Rank = styled(Text)`
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  min-width: 20px;
  border-radius: 4px;
  text-align: center;
  padding-left: 4px;
  padding-right: 4px;
  ${({theme}) => theme.fonts.body12};
  font-weight: bold;
  color: ${({theme}) => theme.colors.infoWhite};
  background-color: ${({theme}) => theme.colors.infoBlue};
`;
