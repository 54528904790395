import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {DateTime} from 'luxon';
import React from 'react';
import {FlatList, Linking} from 'react-native';
import RelativeTimeFormat from 'relative-time-format';
import ko from 'relative-time-format/locale/ko.json';
import {StackParamList} from '../App';
import {CustomText as Text} from '../Home/CustomText';
import {USER_HASH_ID} from '../Investments/InvestmentAPI/constants';
import {useUserNotification} from './api/useUserNotification';

RelativeTimeFormat.addLocale(ko);

const dummyNotifications: NotificationState[] = [
  {
    category: '투자상품',
    body: '테사에서 ‘Frag, Prab’ 모집이 시작되었습니다. 지금 확인해보세요!',
    sent: '2022-01-01',
  },
  {
    category: '투자상품',
    body: '테사에서 ‘Frag, Prab’ 모집이 시작되었습니다. 지금 확인해보세요!',
    sent: '2022-04-25',
  },
  {
    category: '투자상품',
    body: '테사에서 ‘Frag, Prab’ 모집이 시작되었습니다. 지금 확인해보세요!',
    sent: '2022-04-26',
  },
];

export default function NotificationsScreen({
  route,
}: NativeStackScreenProps<StackParamList, 'Notifications'>) {
  const notifications = useUserNotification(USER_HASH_ID);

  if (notifications.isError) <></>;
  if (notifications.isLoading) <></>;
  return (
    <FlatList
      data={notifications.data?.pages.map(page => page.results).flat()}
      renderItem={({item: notification}) => (
        <Notification notification={notification} />
      )}
      keyExtractor={item => item.id}
    />
  );
}

export interface NotificationState {
  url?: string;
  thumbnail?: string;
  category?: string;
  body: string;
  sent: string;
}

const Notification = ({notification}: {notification: NotificationState}) => {
  console.log(notification);
  return (
    <StyledNotification onPress={() => Linking.openURL(notification.url)}>
      <Thumbnail source={{uri: `${notification.image}`}} />
      <Texts>
        <Category numberOfLines={1}>{notification.type}</Category>
        <Body numberOfLines={2} ellipsizeMode="tail">
          {notification.content}
        </Body>
        <Sent numberOfLines={1}>
          {formatToNarrowTime(DateTime.fromISO(notification.sent))}
        </Sent>
      </Texts>
    </StyledNotification>
  );
};

const StyledNotification = styled.Pressable`
  padding: 16px;
  flex-direction: row;
`;

const Thumbnail = styled.Image`
  width: 48px;
  aspect-ratio: 1;
`;

const Texts = styled.View`
  margin-left: 22px;
  flex: 1;
`;

const Category = styled(Text)`
  ${({theme}) => theme.fonts.body10};
  color: ${({theme}) => theme.colors.prapGray3};
`;

const Body = styled(Text)`
  ${({theme}) => theme.fonts.body14};
  color: ${({theme}) => theme.colors.prapGray1};
`;

const Sent = styled(Text)`
  margin-top: 4px;
  ${({theme}) => theme.fonts.body10};
  color: ${({theme}) => theme.colors.prapGray3};
`;

const narrowTimeFormat = new RelativeTimeFormat('ko-KR', {
  style: 'narrow',
  numeric: 'always',
});

export function formatToNarrowTime(datetime: DateTime) {
  const duration = DateTime.now().diff(datetime);
  if (duration.as('minutes') < 1) {
    return '방금 전';
  } else if (duration.as('hours') < 1) {
    return narrowTimeFormat.format(
      -Math.floor(duration.as('minutes')),
      'minute',
    );
  } else if (duration.as('days') < 1) {
    return narrowTimeFormat.format(-Math.floor(duration.as('hours')), 'hour');
  } else {
    return datetime.toISODate();
  }
}
