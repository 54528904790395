import styled from '@emotion/native';
import {CustomText as Text} from '../../Home/CustomText';

const InfoView = styled.View`
  flex: 1;
`;

const OptionView = styled.View`
  justify-content: flex-end;
  border: ${({theme}) => `1px solid ${theme.colors.prapBlue1}`};
  border-radius: 15px;
  padding: 2px 8px;
`;
const ItemOption = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body13};
  color: ${({theme}) => `${theme.colors.prapBlue1}`};
`;

const ItemContainer = styled.Pressable`
  align-items: center;
  padding: 16px;
  flex-direction: row;
`;

const ItemSellerWrap = styled.View``;
const ItemSeller = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
`;
const ItemPrice = styled(Text)`
  font-size: ${({theme}) => theme.fonts.headline18};
`;

const EmptyCompArea = styled.View`
  align-items: center;
  margin-top: 60px;
`;

const EmptyCompText = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => `${theme.colors.prapGray4}`};
`;

export {
  InfoView,
  OptionView,
  ItemOption,
  ItemContainer,
  ItemPrice,
  ItemSeller,
  ItemSellerWrap,
  EmptyCompArea,
  EmptyCompText,
};
