import styled, {css} from '@emotion/native';
import React, {PropsWithChildren, useState} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {useInvestmentViewMutation} from '../Clippings/clippingApi/useInvestmentViewMutation';
import {useRecentlyPostMutation} from '../Clippings/clippingApi/useRecentlyItemMutation';
import {CustomText} from '../Home/CustomText';
import {engToKor} from '../Investments/InvestmentAPI/translate';
import {SmallInvestment} from '../Investments/SmallInvestment';
import {
  RowSkeletonView,
  SmallInvestmentSkeleton,
} from '../Investments/style/Skeleton';
import LinkPressable from '../LinkPressable/LinkPressable';
export const InvestmentCarousel = ({
  style,
  gap = 0,
  pagesPerView = 1,
  fetchType = 'sneakers',
  data,
}: PropsWithChildren<{
  gap?: number;
  style?: StyleProp<ViewStyle>;
  pagesPerView?: number;
  fetchType?: string;
  data?: any;
}>) => {
  const [investmentType, setInvestmentType] = useState(fetchType);
  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const pageWidth = scrollViewWidth / pagesPerView;
  // const {data, isLoading} = useFilterInvestments(investmentType, {
  //   status: ['모집중', '모집예정'],
  // });
  const postRecentlyItem = useRecentlyPostMutation();
  const investmentView = useInvestmentViewMutation();

  const addRecentlyItem = (category: string, id: number, url?: string) => {
    postRecentlyItem.mutate({category: category, id: id});
    investmentView.mutate({category: category, id: id});
    console.log(url);
  };

  if (data.isLoading) {
    return (
      <RowSkeletonView>
        <SmallInvestmentSkeleton />
        <SmallInvestmentSkeleton />
        <SmallInvestmentSkeleton />
      </RowSkeletonView>
    );
  }

  if (data.isError) {
    return <CustomText>준비 중입니다.</CustomText>;
  }

  return (
    <View style={style}>
      <StyledScrollView
        contentOffset={{x: 0, y: 0}}
        onLayout={event => setScrollViewWidth(event.nativeEvent.layout.width)}
        scrollEventThrottle={125}
        horizontal
        contentContainerStyle={contentContainerStyle({gap})}
        showsHorizontalScrollIndicator={false}
        decelerationRate="fast"
        pagingEnabled
        snapToInterval={pageWidth}>
        {/* data?.pages[0].result.map((item, i */}
        {data?.data.pages[0]?.results.map((item, i) => {
          if (fetchType === 'sneakers' || fetchType === 'unlisted-stocks') {
            return (
              <LinkPressable
                key={`${item.name}-key-${i}`}
                to={{
                  screen: 'InvestmentDetails',
                  params: {
                    category: item.category,
                    id: item.id,
                  },
                }}>
                <Page width={pageWidth} gap={gap}>
                  <SmallInvestment investment={item} productType={fetchType} />
                </Page>
              </LinkPressable>
            );
          } else {
            return (
              <LinkPressable
                key={`${item.name}-key-${i}`}
                to={{
                  screen: 'Bridge',
                  params: {
                    category: item.category,
                    id: item.id,
                    url: item.url,
                    platform: engToKor[item.platform][0],
                  },
                }}>
                <Page width={pageWidth} gap={gap}>
                  <SmallInvestment investment={item} productType={fetchType} />
                </Page>
              </LinkPressable>
            );
          }
        })}
      </StyledScrollView>
    </View>
  );
  // }
};

const StyledScrollView = styled.ScrollView``;

const contentContainerStyle = ({gap}: {gap: number}) =>
  css`
    flex-direction: row;
    padding-left: ${`${gap / 2}px`};
    padding-right: ${`${gap / 2}px`};
  `;

const Page = styled.View<{width: number; gap: number}>`
  width: ${({width}) => `${width}px`};
  padding-left: ${({gap}) => `${gap / 2}px`};
  padding-right: ${({gap}) => `${gap / 2}px`};
`;

const AutoPage = styled.View<{width: number}>`
  width: ${({width}) => `${width}px`};
`;

const Indicator = styled.View`
  position: absolute;
  right: 12px;
  bottom: 8px;
  background-color: #212223b2;
  padding: 2px 10px;
  border-radius: 24px;
`;
