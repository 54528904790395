import axios from 'axios';
import {useMutation, useQueryClient} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

interface RecentlyItem {
  id?: number;
  category?: string;
  deleteId?: number;
}

/// POST Recently ITEM

const postRecentlyItem = async (addItem: RecentlyItem) => {
  const response = await axios
    .post(`${PRAP_API_ENDPOINT}/recently_viewed/`, addItem, {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    })
    .catch(error => {
      console.log('postRecentlyItem:', 'error.config:', error.config);
    });

  return response;
};

export const useRecentlyPostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(postRecentlyItem, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries([`${USER_HASH_ID}_recently`]);
    },
    onError: error => {
      console.error(error);
    },
  });
};
