import styled, {css} from '@emotion/native';
import React, {PropsWithChildren, useEffect, useRef, useState} from 'react';
import {ScrollView, StyleProp, View, ViewStyle} from 'react-native';

import {CustomText as Text} from '../Home/CustomText';
import LinkPressable from '../LinkPressable/LinkPressable';
import {_AUTO_DATA} from './testData';

const CarouselView = ({
  auto,
  children,
  style,
  gap = 0,
  pagesPerView = 1,
  indicatorDisabled = false,
  fetchType,
}: PropsWithChildren<{
  auto?: boolean;
  gap?: number;
  style?: StyleProp<ViewStyle>;
  pagesPerView?: number;
  indicatorDisabled?: boolean;
  fetchType?: string;
}>) => {
  const [page, setPage] = useState(0);
  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const pageWidth = scrollViewWidth / pagesPerView;
  const carousel = useRef<ScrollView>(null);
  const check = useRef(null);

  useEffect(() => {
    if (auto) {
      const timer = setInterval(() => {
        if (page < _AUTO_DATA.length - 1) {
          carousel.current?.scrollTo({
            x: (pageWidth + gap) * (page + 1),
            animated: true,
          });
        } else {
          carousel.current?.scrollTo({x: 0});
        }
      }, 2000);
      return () => clearInterval(timer);
    }
  }, [auto, pageWidth, gap, page]);

  return (
    <View style={style}>
      <StyledScrollView
        ref={carousel}
        contentOffset={{x: 0, y: 0}}
        onLayout={event => setScrollViewWidth(event.nativeEvent.layout.width)}
        onScroll={event => {
          setPage(
            Math.round(event.nativeEvent.contentOffset.x / (pageWidth + gap)),
          );
        }}
        scrollEventThrottle={125}
        horizontal
        contentContainerStyle={contentContainerStyle({gap})}
        showsHorizontalScrollIndicator={false}
        decelerationRate="fast"
        pagingEnabled
        snapToInterval={pageWidth}>
        {_AUTO_DATA.map(item => {
          return (
            <AutoPage width={pageWidth} key={item.id}>
              <LinkPressable to="/contents/1">
                <View
                  ref={check}
                  style={css`
                    height: 188px;
                    background-color: ${item.color};
                  `}>
                  <BannerImage source={require('./image/namuh.png')} />
                </View>
              </LinkPressable>
            </AutoPage>
          );
        })}
      </StyledScrollView>
      {indicatorDisabled ? undefined : (
        <Indicator>
          <IndicatorText>
            {page + 1} / {_AUTO_DATA.length}
          </IndicatorText>
        </Indicator>
      )}
    </View>
  );
};

export default CarouselView;

const StyledScrollView = styled.ScrollView``;

const BannerImage = styled.Image`
  width: 100%;
  height: 100%;
  resize-mode: cover;
`;

const contentContainerStyle = ({gap}: {gap: number}) =>
  css`
    flex-direction: row;
    padding-left: ${`${gap / 2}px`};
    padding-right: ${`${gap / 2}px`};
  `;

const Page = styled.View<{width: number; gap: number}>`
  width: ${({width}) => `${width}px`};
  padding-left: ${({gap}) => `${gap / 2}px`};
  padding-right: ${({gap}) => `${gap / 2}px`};
`;

const AutoPage = styled.View<{width: number}>`
  width: ${({width}) => `${width}px`};
`;

const Indicator = styled.View`
  position: absolute;
  right: 12px;
  bottom: 8px;
  background-color: #212223b2;
  padding: 2px 10px;
  border-radius: 24px;
`;

const IndicatorText = styled(Text)`
  ${({theme}) => theme.fonts.body10};
  color: white;
`;
