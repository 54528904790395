import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import {StackParamList} from '../App';
import {CustomText} from '../Home/CustomText';

const NotFoundScreen = ({}: NativeStackScreenProps<
  StackParamList,
  'NotFound'
>) => {
  return (
    <StyledView>
      <CustomText>페이지를 찾을 수 없습니다.</CustomText>
    </StyledView>
  );
};

const StyledView = styled.View`
  padding: 12px;
`;

export default NotFoundScreen;
