import {atom} from 'jotai';
import {focusAtom} from 'jotai/optics';
import {atomWithStorage} from 'jotai/utils';
import {Platform} from 'react-native';

const asyncScrapItemAtom =
  Platform.OS !== 'web'
    ? atom({
        sneakers: {},
        'p2p-investments': {},
        'fractional-investments': {},
        startups: {},
        'unlisted-stocks': {},
      })
    : atomWithStorage('scrapItem', {
        sneakers: {},
        'p2p-investments': {},
        'fractional-investments': {},
        startups: {},
        'unlisted-stocks': {},
      });

const asyncRecentlyItemAtom = atom({});

const asyncP2PItemAtom = focusAtom(asyncScrapItemAtom, optic =>
  optic.prop('p2p-investments'),
);
const asyncFractionalItemAtom = focusAtom(asyncScrapItemAtom, optic =>
  optic.prop('fractional-investments'),
);
const asyncSneakersItemAtom = focusAtom(asyncScrapItemAtom, optic =>
  optic.prop('sneakers'),
);
const asyncStartupItemAtom = focusAtom(asyncScrapItemAtom, optic =>
  optic.prop('startups'),
);
const asyncUnlistedItemAtom = focusAtom(asyncScrapItemAtom, optic =>
  optic.prop('unlisted-stocks'),
);

export {
  asyncScrapItemAtom,
  asyncRecentlyItemAtom,
  asyncFractionalItemAtom,
  asyncP2PItemAtom,
  asyncSneakersItemAtom,
  asyncStartupItemAtom,
  asyncUnlistedItemAtom,
};
