import styled from '@emotion/native';
import {Slider} from '@miblanchard/react-native-slider';
import React, {useState} from 'react';
import {StyleSheet, View} from 'react-native';
import {CustomText as Text} from '../Home/CustomText';

const borderWidth = 4;

const trackMarkStyles = StyleSheet.create({
  activeMark: {
    borderColor: 'red',
    borderWidth,
    left: -borderWidth / 2,
  },
  inactiveMark: {
    borderColor: 'grey',
    borderWidth,
    left: -borderWidth / 2,
  },
});
const DEFAULT_VALUE = 0.2;

export const SliderContainer = (props: {
  caption: string;
  children: React.ReactElement;
  sliderValue?: Array<number>;
  trackMarks?: Array<number>;
}) => {
  const {caption, sliderValue, trackMarks} = props;
  const [value, setValue] = useState(sliderValue ? sliderValue : DEFAULT_VALUE);

  let renderTrackMarkComponent: React.ReactNode;

  if (trackMarks?.length && (!Array.isArray(value) || value?.length === 1)) {
    renderTrackMarkComponent = (index: number) => {
      const currentMarkValue = trackMarks[index];
      const currentSliderValue =
        value || (Array.isArray(value) && value[0]) || 0;
      const style =
        currentMarkValue > Math.max(currentSliderValue)
          ? trackMarkStyles.activeMark
          : trackMarkStyles.inactiveMark;

      return <View style={style} />;
    };
  }

  const renderChildren = () => {
    return React.Children.map(props.children, (child: React.ReactElement) => {
      if (!!child && child.type === Slider) {
        return React.cloneElement(child, {
          onValueChange: setValue,
          renderTrackMarkComponent,
          trackMarks,
          value,
        });
      }

      return child;
    });
  };

  return (
    <SliderView>
      <SliderTitleView>
        <SliderValueTitleText>
          {`${value[0]?.toLocaleString('ko-KR')} 원`} -{' '}
          {`${value[1]?.toLocaleString('ko-KR')} 원`}
        </SliderValueTitleText>
      </SliderTitleView>
      {renderChildren()}
    </SliderView>
  );
};

const SliderView = styled.View``;
const SliderTitleView = styled.View`
  justify-content: center;
`;

const SliderValueTitleText = styled(Text)`
  color: ${({theme}) => theme.colors.prapBlue2};
  font-size: ${({theme}) => theme.fonts.body16};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
