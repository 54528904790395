import styled, {css} from '@emotion/native';
import React, {PropsWithChildren, useState} from 'react';
import {StyleProp, View, ViewStyle} from 'react-native';
import {engToKor} from '../Investments/InvestmentAPI/translate';
import {SmallInvestment} from '../Investments/SmallInvestment';
import LinkPressable from '../LinkPressable/LinkPressable';
export const FollowCarousel = ({
  style,
  gap = 0,
  pagesPerView = 1,
  data,
  carouselType,
}: PropsWithChildren<{
  gap?: number;
  style?: StyleProp<ViewStyle>;
  pagesPerView?: number;
  data?: any;
  carouselType?: string;
}>) => {
  const [scrollViewWidth, setScrollViewWidth] = useState(0);
  const pageWidth = scrollViewWidth / pagesPerView;
  console.log(data);

  return (
    <View style={style}>
      <StyledScrollView
        contentOffset={{x: 0, y: 0}}
        onLayout={event => setScrollViewWidth(event.nativeEvent.layout.width)}
        scrollEventThrottle={125}
        horizontal
        contentContainerStyle={contentContainerStyle({gap})}
        showsHorizontalScrollIndicator={false}
        decelerationRate="fast"
        pagingEnabled
        snapToInterval={pageWidth}>
        {/* data?.pages[0].result.map((item, i */}
        {data.map((item, i) => {
          return (
            <LinkPressable
              key={`${item.title}-key-${i}`}
              to={{
                screen: 'Bridge',
                params: {
                  category: item.category,
                  id: item.id,
                  url: item.url,
                  platform: engToKor[item.platform][0],
                },
              }}>
              <Page width={pageWidth} gap={gap}>
                <SmallInvestment
                  investment={item}
                  productType={item.category}
                  carouselType={carouselType}
                />
              </Page>
            </LinkPressable>
          );
        })}
      </StyledScrollView>
    </View>
  );
  // }
};

const StyledScrollView = styled.ScrollView``;

const contentContainerStyle = ({gap}: {gap: number}) =>
  css`
    flex-direction: row;
    padding-left: ${`${gap / 2}px`};
    padding-right: ${`${gap / 2}px`};
  `;

const Page = styled.View<{width: number; gap: number}>`
  width: ${({width}) => `${width}px`};
  padding-left: ${({gap}) => `${gap / 2}px`};
  padding-right: ${({gap}) => `${gap / 2}px`};
`;
