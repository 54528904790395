import styled, {css} from '@emotion/native';
import {NavigationAction} from '@react-navigation/native';
import React from 'react';
import {GestureResponderEvent, Pressable, SectionList} from 'react-native';
import {CustomText as Text} from '../Home/CustomText';
import LinkPressable from '../LinkPressable/LinkPressable';
const SimpleList = ({
  ListHeaderComponent,
  sections,
}: {
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  sections: {data: ItemState[]}[];
}) => {
  return (
    <SectionList
      style={sectionListStyle()}
      ListHeaderComponent={ListHeaderComponent}
      sections={sections}
      renderItem={({item}) =>
        item.linkTo ? (
          <LinkPressable to={item.linkTo} action={item.linkAction}>
            <Item item={item} />
          </LinkPressable>
        ) : (
          <Pressable onPress={item.handlePress}>
            <Item item={item} />
          </Pressable>
        )
      }
      keyExtractor={item => item.label}
      SectionSeparatorComponent={({leadingSection, trailingItem}) =>
        leadingSection && trailingItem ? <SectionSeparator /> : null
      }
    />
  );
};

export default SimpleList;

export interface ItemState {
  label: string;
  description?: string;
  value?: string;
  linkTo?: string;
  linkAction?: NavigationAction;
  handlePress?: (event: GestureResponderEvent) => void;
}

const sectionListStyle = () => css`
  padding: 12px 0;
`;

const Item = ({item}: {item: ItemState}) => {
  return (
    <StyledItem>
      <LabelAndDescription>
        <Label>{item.label}</Label>
        <Description>{item.description}</Description>
      </LabelAndDescription>
      <Value>{item.value}</Value>
    </StyledItem>
  );
};

const StyledItem = styled.View`
  flex-direction: row;
  align-items: center;
`;

const LabelAndDescription = styled.View`
  flex: 1;
  font-size: 16px;
  margin: 12px 24px;
`;

const Label = styled(Text)`
  ${({theme}) => theme.fonts.body16};
  color: ${({theme}) => theme.colors.prapGray1};
`;

const Description = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray3};
`;

const Value = styled(Text)`
  ${({theme}) => theme.fonts.body16};
  color: ${({theme}) => theme.colors.prapGray3};
  margin: 12px 24px;
`;

const SectionSeparator = styled.View`
  background-color: ${({theme}) => theme.colors.prapGray6};
  height: 12px;
  margin: 12px 0;
`;
