import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import {FlatList} from 'react-native';
import {CustomText as Text} from '../Home/CustomText';
import LinkPressable from '../LinkPressable/LinkPressable';
import {MoreStackParamList} from '../More/MoreStackScreen';
import {AnnouncementState} from './AnnouncementScreen';

const AnnouncementsScreen = ({}: NativeStackScreenProps<
  MoreStackParamList,
  'More'
>) => {
  return (
    <FlatList
      data={[
        {id: '1', title: '흑호의 곶감찾기 당첨자 안내', created: '2022-12-12'},
        {
          id: '2',
          title: '프랩 서비스 정기점검 안내 (11/30 AM 3:00 ~ AM 7:50)',
          created: '2022-12-12',
        },
        {
          id: '3',
          title: '프랩 개인정보처리방침 개정 안내 (2021.12.21)',
          created: '2022-12-12',
        },
      ]}
      renderItem={({item: announcement}) => (
        <LinkPressable to={`/announcements/${announcement.id}`}>
          <ListAnnouncement announcement={announcement} />
        </LinkPressable>
      )}
      ItemSeparatorComponent={ItemSeparator}
    />
  );
};

export default AnnouncementsScreen;

export const ListAnnouncement = ({
  announcement,
}: {
  announcement: AnnouncementState;
}) => {
  const isNew = true;

  return (
    <StyledListAnnouncement>
      <Title numberOfLines={2}>{announcement.title}</Title>
      <CreatedAndNew>
        <Created>{announcement.created}</Created>
        {isNew ? <New>NEW</New> : null}
      </CreatedAndNew>
    </StyledListAnnouncement>
  );
};

const StyledListAnnouncement = styled.View`
  padding: 16px;
`;

const Title = styled(Text)`
  ${({theme}) => theme.fonts.body16};
  color: ${({theme}) => theme.colors.prapGray1};
`;

const CreatedAndNew = styled.View`
  flex-direction: row;
`;

const Created = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray5};
`;

const New = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.infoRed};
  margin-left: 8px;
`;

const ItemSeparator = styled.View`
  margin: 0 16px;
  height: 1px;
  background-color: ${({theme}) => theme.colors.prapGray5};
`;
