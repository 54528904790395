import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {useEffect, useRef, useState} from 'react';
import {Animated} from 'react-native';
import {UnlistedStocksFilter} from '../Filter/UnlistedStocksFilter';
import {CustomText as Text} from '../Home/CustomText';
import {useFilterInvestments} from '../Investments/InvestmentAPI/useFilterInvestments';
import Investments from '../Investments/Investments';
import {
  CarouselSkeleton,
  ColumnSkeletonView,
  MediumInvestmentSkeleton,
} from '../Investments/style/Skeleton';
import {CategorySearchBar} from './CategorySearchBar';
import {CategoryTabParamList} from './CategoryTabScreen';
import SvgCategoryFilterIcon from './icon/CategoryFilterIcon';
import SvgRemoveIcon from './icon/RemoveIcon';
import SvgSearchIcon from './icon/SearchIcon';
import SvgSortIcon from './icon/SortIcon';
import {
  CategoryHeader,
  CTACombo,
  FilterArea,
  FilterContainer,
  FilterCTA,
  FilterText,
  RemoveIconArea,
  SearchArea,
  SearchCTA,
  StyledCategoryView,
} from './style/public.style';

export const UnlistedStocksScreen = ({
  route,
}: NativeStackScreenProps<CategoryTabParamList, 'UnlistedStocks'>) => {
  const productType = 'unlisted-stocks';
  const [filterOption, setFilterOption] = useState({
    platform: [],
    status: [],
    sortOption: [],
  });
  const unlistedStocksData = useFilterInvestments(productType, filterOption);

  const filterRef = useRef<BottomSheetModal>(null);
  const [searchbarVisible, setSearchBarVisible] = useState(false);
  const [isSort, setSort] = useState('');
  const translation = useRef(new Animated.Value(-100)).current;
  useEffect(() => {
    Animated.timing(translation, {
      toValue: searchbarVisible ? 0 : -300,
      duration: 750,
      useNativeDriver: true,
    }).start();
  }, [searchbarVisible]);

  useEffect(() => {
    console.log(filterOption);
    unlistedStocksData.refetch();
  }, [filterOption]);

  const openFilter = () => {
    filterRef.current?.snapToIndex(1);
  };
  const openSearchBar = () => setSearchBarVisible(true);
  const closeSearchBar = () => setSearchBarVisible(false);

  if (unlistedStocksData.isLoading) {
    return (
      <>
        <CarouselSkeleton />
        <ColumnSkeletonView>
          <MediumInvestmentSkeleton />
          <MediumInvestmentSkeleton />
          <MediumInvestmentSkeleton />
          <MediumInvestmentSkeleton />
          <MediumInvestmentSkeleton />
        </ColumnSkeletonView>
      </>
    );
  }

  if (unlistedStocksData.isError) {
    return <Text>오류가 발생했습니다.</Text>;
  }
  return (
    <>
      <StyledCategoryView>
        <CategoryHeader>
          <CTACombo>
            <SearchArea>
              <SearchCTA onPress={openSearchBar}>
                <FilterText>검색</FilterText>
                <SvgSearchIcon />
              </SearchCTA>
            </SearchArea>
            <FilterArea>
              <FilterCTA
                onPress={(_, option = '정렬') => {
                  setSort(option);
                  openFilter();
                }}>
                <FilterText>정렬</FilterText>
                <SvgSortIcon />
              </FilterCTA>
              <FilterCTA
                onPress={(_, option = '필터') => {
                  setSort(option);
                  openFilter();
                }}>
                <FilterText>필터</FilterText>
                <SvgCategoryFilterIcon />
              </FilterCTA>
            </FilterArea>
          </CTACombo>
          {searchbarVisible ? (
            <FilterContainer
              style={{
                transform: [{translateY: translation}],
              }}>
              <CategorySearchBar
                setFilterOption={setFilterOption}
                filterOption={filterOption}
              />
              <RemoveIconArea onPress={closeSearchBar}>
                <SvgRemoveIcon />
              </RemoveIconArea>
            </FilterContainer>
          ) : null}
        </CategoryHeader>
        <Investments
          investmentData={unlistedStocksData}
          productType={productType}
        />
      </StyledCategoryView>
      <UnlistedStocksFilter
        setFilterOption={setFilterOption}
        filterOption={filterOption}
        bottomSheetRef={filterRef}
        isSort={isSort}
        setSort={setSort}
      />
    </>
  );
};
