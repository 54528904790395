import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import React, {useState} from 'react';
import {FlatList} from 'react-native';
import {korToEng} from '../Investments/InvestmentAPI/translate';
import MediumInvestment from '../Investments/MediumInvestment';

import {SearchTabParamList} from './SearchTabScreen';

export default function SearchScreen({
  route,
}: MaterialTopTabScreenProps<SearchTabParamList>) {
  const {data, category} = route?.params;
  const [toggle, setToggle] = useState(false);
  const onToggleSnackbar = () => setToggle(!toggle);
  const onDismissSnackbar = () => setToggle(false);

  console.log(data);
  console.log(
    data.data?.pages[0].filter(investment => investment[category])[0][category]
      .data.results,
  );

  if (data.isLoading) {
    return <></>;
  }
  if (data.isError) {
    return <></>;
  }

  return (
    <>
      <FlatList
        data={
          data.data?.pages[0].filter(investment => investment[category])[0][
            category
          ].data.results
        }
        renderItem={({item: investment, index: rank}) => (
          <MediumInvestment
            investment={investment}
            rank={rank}
            productType={korToEng[investment.category]}
            snackbarVisible={onToggleSnackbar}
            scrap={false}
          />
        )}
        ListEmptyComponent={<></>}
        onEndReached={() => (data.hasNextPage ? data.fetchNextPage() : null)}
        onEndReachedThreshold={0.5}
      />
    </>
  );
}
