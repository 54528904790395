import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgFluctuationUpIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="m8 5 5 6H3l5-6Z" fill="#FB5353" />
  </Svg>
);

export default SvgFluctuationUpIcon;
