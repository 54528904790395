import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const SvgFocusedPlatformsIcon = props => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G clipPath="url(#FocusedPlatformsIcon_svg__a)" fill="#27332F">
      <Path d="M4 21V10h16v11H4Z" />
      <Path
        d="M3 4h6v6a3 3 0 1 1-6 0V4ZM9 4h6v6a3 3 0 1 1-6 0V4ZM15 4h6v6a3 3 0 1 1-6 0V4Z"
        stroke="#FEFEFE"
      />
    </G>
    <Defs>
      <ClipPath id="FocusedPlatformsIcon_svg__a">
        <Path fill="#fff" transform="rotate(90 12 12)" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgFocusedPlatformsIcon;
