import styled from '@emotion/native';
import debounce from 'lodash-es/debounce';
import {useState} from 'react';
import {
  Keyboard,
  NativeSyntheticEvent,
  TextInputKeyPressEventData,
} from 'react-native';
import {FilterInfo} from '../Investments/InvestmentAPI/useFilterInvestments';

export const CategorySearchBar = ({
  setFilterOption,
  filterOption,
}: {
  setFilterOption: any;
  filterOption: FilterInfo;
}) => {
  const [searchValue, setSearchValue] = useState('');
  const setInputState = v => setSearchValue(v);
  const debounceInput = debounce(q => {
    setInputState(q);
  }, 500);

  const changeTextValue = e => {
    debounceInput(e);
  };
  const handleKeyDownEnter = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (e) {
      console.log(searchValue);
      Keyboard.dismiss();
      setFilterOption(prev => {
        return {
          ...prev,
          searchByTitle: searchValue,
        };
      });
    }
  };
  return (
    <SearchBarArea>
      <SearchBarTextInput
        placeholder="상품명이나 플랫폼명으로 찾아보세요"
        onChangeText={e => changeTextValue(e)}
        onSubmitEditing={e => handleKeyDownEnter(e)}
        multiline={false}
        blurOnSubmit={false}
        returnKeyType="search"
        defaultValue={filterOption.searchByTitle}
        style={{
          textAlignVertical: 'center',
        }}
      />
    </SearchBarArea>
  );
};

const SearchBarArea = styled.View`
  flex-direction: row;
  align-items: center;

  background-color: ${({theme}) => theme.colors.infoWhite};
  width: 92%;
`;

const SearchBarTextInput = styled.TextInput`
  flex: 1;
  background-color: ${({theme}) => theme.colors.prapGray6};
  border-radius: 20px;
  padding: 12px; 16px;

  justify-content: center;
`;
