import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {Dispatch, SetStateAction, useEffect, useState} from 'react';
import {ScrollView} from 'react-native';
import {StackParamList} from '../App';
import {useInvestmentViewMutation} from '../Clippings/clippingApi/useInvestmentViewMutation';
import {useRecentlyPostMutation} from '../Clippings/clippingApi/useRecentlyItemMutation';
import {CustomText as Text} from '../Home/CustomText';
import {useInvestmentDetails} from './api/useInvestmentDetails';
import {Asks, Bids} from './BidsAndAsks';

export default function InvestmentDetailsScreen({
  route,
}: NativeStackScreenProps<StackParamList, 'InvestmentDetails'>) {
  const {category, id} = route.params;

  const postRecentlyItem = useRecentlyPostMutation();
  const investmentView = useInvestmentViewMutation();

  useEffect(() => {
    postRecentlyItem.mutate({
      category: category,
      id: parseInt(id),
    });
    investmentView.mutate({
      category: category,
      id: parseInt(id),
    });
  }, []);

  const investmentDetails = useInvestmentDetails(category, id);

  const [tab, setTab] = useState<'asks' | 'bids'>('asks');

  if (investmentDetails.isLoading) {
    return <></>;
  }
  if (investmentDetails.isError) {
    return <></>;
  }

  console.log(investmentDetails.data.prices);

  return (
    <ScrollView stickyHeaderIndices={[1]}>
      <InvestmentDetailsInfo
        image={investmentDetails.data.image}
        type={investmentDetails.data.category}
        name={investmentDetails.data.name}
        copValue={investmentDetails.data.valuation}
        price={investmentDetails.data.price}
      />
      <TabContainer>
        <Tabs tab={tab} setTab={setTab} />
        {tab == 'asks' ? (
          <Asks
            category={investmentDetails.data.category}
            platform={investmentDetails.data.platform}
            prices={investmentDetails.data.prices.filter(
              (price: any) => price.type === 'buy',
            )}
          />
        ) : tab == 'bids' ? (
          <Bids
            category={investmentDetails.data.category}
            platform={investmentDetails.data.platform}
            prices={investmentDetails.data.prices.filter(
              (price: any) => price.type === 'sell',
            )}
          />
        ) : null}
      </TabContainer>
    </ScrollView>
  );
}

const InvestmentDetailsInfo = ({
  name,
  copValue,
  price,
  type,
  image,
}: {
  name: string;
  copValue: number;
  price: number;
  type: string;
  image?: string;
}) => {
  return (
    <ProductInfoContainer>
      <ProductNameAndCopValueContainer>
        {type === 'unlisted-stocks' ? null : (
          <StyledImageView source={{uri: image}} />
        )}
        <ProductName type={type}>{name}</ProductName>
        {copValue ? (
          <ProductCopValueWrap>
            <ProductCopValueLabel>기업가치</ProductCopValueLabel>
            <ProductCopValue>{copValue}</ProductCopValue>
          </ProductCopValueWrap>
        ) : null}
      </ProductNameAndCopValueContainer>
      <ProductPriceContainer>
        <ProductPriceWrap>
          <ProductPriceLabel>구매 최저가</ProductPriceLabel>
          <ProductPriceText>
            {`${Math.round(price).toLocaleString()}원`}
          </ProductPriceText>
        </ProductPriceWrap>
        <ProductPriceWrap>
          <ProductPriceLabel>판매 최고가</ProductPriceLabel>
          <ProductPriceText>
            {`${Math.round(price).toLocaleString()}원`}
          </ProductPriceText>
        </ProductPriceWrap>
      </ProductPriceContainer>
    </ProductInfoContainer>
  );
};

const ProductInfoContainer = styled.View`
  background-color: white;
  justify-content: center;
  align-items: center;
  padding: 24px 0;
`;
const ProductNameAndCopValueContainer = styled.View`
  justify-content: center;
  align-items: center;
`;
const ProductName = styled(Text)<{type: string}>`
  font-size: ${({theme, type}) =>
    type === 'unlisted-stocks'
      ? theme.fonts.headline24
      : theme.fonts.headline18};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const ProductCopValueWrap = styled.View`
  flex-direction: row;
  margin-top: 16px;
`;
const ProductCopValueLabel = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
  margin-right: 32px;
`;
const ProductCopValue = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
`;
const ProductPriceContainer = styled.View`
  justify-content: center;
  align-items: center;
  margin-top: 21px;
`;
const ProductPriceWrap = styled.View`
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ProductPriceLabel = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body14};
  margin-right: 45px;
`;
const ProductPriceText = styled(Text)`
  font-size: ${({theme}) => theme.fonts.headline18};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const ProductPriceOption = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
`;
const StyledImageView = styled.Image`
  margin-top: 24px;
  margin-bottom: 30px;
  width: 80%;
  height: 100px;
`;

const TabContainer = styled.View`
  margin-top: 12px;
  background-color: white;
`;

const Tabs = ({
  tab,
  setTab,
}: {
  tab: 'asks' | 'bids';
  setTab: Dispatch<SetStateAction<'asks' | 'bids'>>;
}) => {
  return (
    <StyledTabs>
      <TabButton onPress={() => setTab('asks')}>
        <TabText highlight={tab == 'asks'}>구매가</TabText>
        <BottomLine highlight={tab == 'asks'} />
      </TabButton>
      <TabButton onPress={() => setTab('bids')}>
        <TabText highlight={tab == 'bids'}>판매가</TabText>
        <BottomLine highlight={tab == 'bids'} />
      </TabButton>
    </StyledTabs>
  );
};

const StyledTabs = styled.View`
  padding: 8px;
  justify-content: center;
  flex-direction: row;
`;

const TabButton = styled.Pressable`
  padding: 16px 16px 8px;
`;

const TabText = styled(Text)<{highlight: boolean}>`
  ${({theme}) => theme.fonts.body14};
  color: ${({highlight}) => (highlight ? 'black' : '#adadad')};
  font-family: 'SpoqaHanSansNeo-Bold';
`;

const BottomLine = styled.View<{highlight: boolean}>`
  width: 32px;
  height: 2px;
  margin-top: 8px;
  align-self: center;
  background-color: ${({highlight, theme}) =>
    highlight ? theme.colors.prapBlue1 : 'transparent'};
`;
