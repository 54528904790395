import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {PRAP_API_ENDPOINT} from '../../Investments/InvestmentAPI/constants';

const getHomeSub = async ({
  page = 1,
  segmentType,
}: {
  page?: number;
  segmentType?: string;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/${segmentType}/?page=${page}`;

  const {data} = await axios.get(`${ENDPOINT}`);

  return {result: data.results, next: data.next};
};

export function useHomeSegmentation(segmentType: string) {
  return useInfiniteQuery(
    [`${segmentType}_segment`],
    ({pageParam = 1}) =>
      getHomeSub({
        page: pageParam,
        segmentType: segmentType,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response.next) {
          return pages.length + 1;
        }
      },
    },
  );
}
