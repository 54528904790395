import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import {HomeTopTabParamList} from './HomeTopTabScreen';
import {InvestmentSegment} from './InvestmentSegment';

const ClosedSoonScreen =
  ({}: MaterialTopTabScreenProps<HomeTopTabParamList>) => {
    return <InvestmentSegment segment="closed-soon" />;
  };

export default ClosedSoonScreen;
