import React from 'react';
import {Linking} from 'react-native';
import {engToKor} from '../../Investments/InvestmentAPI/translate';
import {
  InfoView,
  ItemContainer,
  ItemOption,
  ItemPrice,
  ItemSeller,
  ItemSellerWrap,
  OptionView,
} from './public.style';

export const BidOrAsk = ({
  price,
  category,
  platform,
}: {
  price: any;
  category: string;
  platform: string;
}) => {
  const handlePress = (url: string) => {
    url ? Linking.openURL(url) : null;
  };

  if (price.platform == 'stockplus') price.platform = 'ustockplus';

  return (
    <ItemContainer onPress={() => handlePress(price.url)}>
      <InfoView>
        <ItemSellerWrap>
          <ItemSeller>{engToKor[price.platform][0]}</ItemSeller>
        </ItemSellerWrap>
        <ItemPrice>{price.price.toLocaleString('ko-KR')}원</ItemPrice>
        <ItemOption>
          {category === 'unlisted-stocks'
            ? `${price?.minimumAmount}주`
            : `${price?.size}mm`}
        </ItemOption>
      </InfoView>

      {price.option ? (
        <OptionView>
          <ItemOption>{price?.option}</ItemOption>
        </OptionView>
      ) : null}
    </ItemContainer>
  );
};
