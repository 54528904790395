import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgUnlistedStockIcon = props => (
  <Svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M29.857 3.667H6.286a2.62 2.62 0 0 0-2.62 2.619v31.428a2.62 2.62 0 0 0 2.62 2.62h23.571a2.62 2.62 0 0 0 2.619-2.62V6.286a2.619 2.619 0 0 0-2.619-2.62Z"
      fill="#B8C9F2"
    />
    <Path
      d="m9.232 23.807 2.082 2.082 5.199-6.233 4.151 4.151 6.246-8.315"
      stroke="#fff"
      strokeWidth={2}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <Path
      d="M39.09 22H28.482c-.687 0-1.244.557-1.244 1.244v15.845c0 .687.557 1.244 1.244 1.244H39.09c.686 0 1.243-.557 1.243-1.244V23.244c0-.687-.556-1.244-1.243-1.244Z"
      fill="#A5E4EB"
    />
    <Path
      d="M32.476 37.714V22h-3.928a1.31 1.31 0 0 0-1.31 1.31v15.845a1.31 1.31 0 0 0 1.31 1.178h1.31a2.62 2.62 0 0 0 2.618-2.619Z"
      fill="#ABA5E3"
    />
  </Svg>
);

export default SvgUnlistedStockIcon;
