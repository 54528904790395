import styled from '@emotion/native';
import {useLinkTo, useNavigation} from '@react-navigation/native';
import {debounce} from 'lodash-es';
import React, {useState} from 'react';
import {
  Keyboard,
  Modal,
  NativeSyntheticEvent,
  Pressable,
  TextInputKeyPressEventData,
} from 'react-native';
import {USER_HASH_ID} from '../Investments/InvestmentAPI/constants';
import LinkPressable from '../LinkPressable/LinkPressable';
import MoreIcon from '../More/MoreIcon';
import {useUserNotification} from '../Notifications/api/useUserNotification';
import {CustomText as Text} from './CustomText';
import BellIcon from './icon/BellIcon';
import MoreClippingIcon from './icon/MoreClippingIcon';
import MoreFollowingIcon from './icon/MoreFollowingIcon';
import MoreRankingIcon from './icon/MoreRankingIcon';
import MoreRecentlyIcon from './icon/MoreRecentlyIcon';

export const HomeSearchBar = ({}) => {
  const [searchValue, setSearchValue] = useState('');
  const setInputState = v => setSearchValue(v);

  const debounceInput = debounce(q => {
    setInputState(q);
  }, 500);

  const changeTextValue = e => {
    debounceInput(e);
  };
  const handleKeyDownEnter = (
    e: NativeSyntheticEvent<TextInputKeyPressEventData>,
  ) => {
    if (e) {
      Keyboard.dismiss();
      console.log(e.nativeEvent.text);
      e.currentTarget.clear();
      navigation.navigate('Search', {
        value: e.nativeEvent.text,
      });
    }
  };

  const [isModalVisible, setModalVisible] = useState(false);
  const notification = useUserNotification(USER_HASH_ID);

  const navigation = useNavigation();
  const linkTo = useLinkTo();
  return (
    <>
      <SearchBarArea>
        <SearchBarTextInput
          placeholder="상품명이나 플랫폼명으로 찾아보세요."
          onChangeText={e => changeTextValue(e)}
          onSubmitEditing={e => {
            handleKeyDownEnter(e);
          }}
          multiline={false}
          blurOnSubmit={false}
          returnKeyType="search"
          style={{
            textAlignVertical: 'center',
          }}
        />
        <CTACombo>
          <SearchBarCTAForm>
            <LinkPressable to="/notifications">
              <IconContainer>
                <BellIcon />
              </IconContainer>
              <SearchBarText>소식</SearchBarText>
            </LinkPressable>
          </SearchBarCTAForm>

          {process.env.FLAVOR == 'namuh' || process.env.FLAVOR == 'qv' ? (
            <SearchBarCTAForm>
              <Pressable onPress={() => setModalVisible(!isModalVisible)}>
                <IconContainer>
                  <MoreIcon />
                </IconContainer>
                <SearchBarText>더보기</SearchBarText>
              </Pressable>
            </SearchBarCTAForm>
          ) : null}
        </CTACombo>
      </SearchBarArea>
      <StyledMoreLinkContainer
        animationType="fade"
        transparent={true}
        visible={isModalVisible}
        onDismiss={() => setModalVisible(false)}>
        <Pressable
          style={{
            flex: 1,
            backgroundColor: 'transparent',
          }}
          onPress={() => setModalVisible(false)}
        />
        <StyledModalView>
          <StyledMoreLink
            onPress={() => {
              setModalVisible(false);
              linkTo('/nh-more/platform-following');
            }}>
            <MoreSelectMenu>
              <MoreIconContainer>
                <MoreFollowingIcon />
              </MoreIconContainer>
              <MoreItemText>팔로우하는 플랫폼</MoreItemText>
            </MoreSelectMenu>
          </StyledMoreLink>
          <StyledMoreLink
            onPress={() => {
              setModalVisible(false);
              linkTo('/nh-more/platform-ranking');
            }}>
            <MoreSelectMenu>
              <MoreIconContainer>
                <MoreRankingIcon />
              </MoreIconContainer>
              <MoreItemText>플랫폼 랭킹</MoreItemText>
            </MoreSelectMenu>
          </StyledMoreLink>
          <StyledMoreLink
            onPress={() => {
              setModalVisible(false);
              linkTo('/nh-more/investment-clippings');
            }}>
            <MoreSelectMenu>
              <MoreIconContainer>
                <MoreClippingIcon />
              </MoreIconContainer>
              <MoreItemText>스크랩한 상품</MoreItemText>
            </MoreSelectMenu>
          </StyledMoreLink>
          <StyledMoreLink
            onPress={() => {
              setModalVisible(false);
              linkTo('/nh-more/recently-viewed');
            }}>
            <MoreSelectMenu>
              <MoreIconContainer>
                <MoreRecentlyIcon />
              </MoreIconContainer>
              <MoreItemText>최근 본 상품</MoreItemText>
            </MoreSelectMenu>
          </StyledMoreLink>
        </StyledModalView>
      </StyledMoreLinkContainer>
    </>
  );
};

export default HomeSearchBar;

const MoreIconContainer = styled.View`
  margin-right: 8px;
`;

const StyledMoreLinkContainer = styled(Modal)``;
const StyledMoreLink = styled.Pressable`
  flex: 1;
`;
const MoreItemText = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  ${({theme}) => theme.colors.prapGray1};
`;

const StyledModalView = styled.View`
  position: absolute;
  background: white;
  right: 40px;
  top: 70px;
  padding: 16px 16px;
  padding-right: 24px;
  height: 200px;

  box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const MoreSelectMenu = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 10px 0;
  margin: 4px 0;
`;

const CTACombo = styled.View`
  flex-direction: row;
  height: 100%;
  text-align: center;
`;

const SearchBarCTAForm = styled.View`
  width: 40px;
  align-items: center;
  justify-content: center;
`;
const SearchBarText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray4};
  font-size: ${({theme}) => theme.fonts.body10};
  text-align: center;
`;

const IconContainer = styled.View`
  height: 24px;
  align-items: center;
  justify-content: center;
`;

const SearchBarBG = styled.View`
  background-color: ${({theme}) => theme.colors.infoWhite};
  flex: 1;
  padding: 8px 0;
  padding-left: 8px;
  padding-right: 16px;
`;
const SearchBarArea = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-right: 15px;
  padding-left: 15px;
`;

const SearchBarTextInput = styled.TextInput`
  flex: 1;
  background-color: ${({theme}) => theme.colors.prapGray6};
  border-radius: 20px;
  padding: 4px 16px;
  min-height: 40px;

  justify-content: center;
`;
