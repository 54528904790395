import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgSortIcon = props => (
  <Svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="m2.15 8.125.9.905V1h1.03v8.03l.9-.905.73.73L3.565 11 1.42 8.855l.73-.73ZM10.27 3.875l-.9-.905V11H8.34V2.97l-.9.905-.73-.73L8.855 1 11 3.145l-.73.73Z"
      fill="#666"
    />
  </Svg>
);

export default SvgSortIcon;
