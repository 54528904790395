import Axios from 'axios';
import {registerRootComponent} from 'expo';
import 'expo-dev-client';
import {Platform} from 'react-native';
import {getUniqueId, getUserAgentSync} from 'react-native-device-info';
import App from './App';

console.log('FLAVOR:', process.env.FLAVOR);

// https://axios-http.com/docs/config_defaults
Axios.defaults.baseURL = process.env.API_URL ?? 'https://api.prap.kr';
Axios.defaults.headers.common['Authorization'] = `Bearer ${
  process.env.BEARER_TOKEN ??
  Platform.select({
    native: 'Test ' + getUniqueId(),
    web: 'Test ' + getUserAgentSync(),
  })
}`;

console.info(
  'Axios Authorization:',
  Axios.defaults.headers.common['Authorization'],
);

// registerRootComponent calls AppRegistry.registerComponent('main', () => App);
// It also ensures that whether you load the app in Expo Go or in a native build,
// the environment is set up appropriately
registerRootComponent(App);
