import styled from '@emotion/native';
import React from 'react';
import {FollowCarousel} from '../CarouselView/FollowCarousel';
import {CustomText as Text} from '../Home/CustomText';
import {engToKor} from '../Investments/InvestmentAPI/translate';
import LinkPressable from '../LinkPressable/LinkPressable';
import {
  useFollowDeleteMutation,
  useFollowPostMutation,
} from './api/useFollowPlatformMutation';
import SvgFollowCheckIcon from './icon/FollowCheckIcon';

export const PlatformsForm = ({
  platform,
  rank,
  type,
}: {
  platform: any;
  rank: number;
  type: string;
}) => {
  const postFollowPlatform = useFollowPostMutation();
  const deleteFollowPlatform = useFollowDeleteMutation();

  const addPlatform = (platformId: string) => {
    postFollowPlatform.mutate({platform: platformId});
  };
  const removePlatform = (deleteId: number) => {
    deleteFollowPlatform.mutate({deleteId: deleteId});
  };

  const FollowButton = ({following}: {following: boolean}) => {
    console.log(platform.name);
    return platform.followId === null ? (
      <StyledFollowButton
        following={following}
        onPress={event => {
          addPlatform(platform.name);
          event.preventDefault();
        }}>
        <FollowButtonText following={following}>팔로우</FollowButtonText>
      </StyledFollowButton>
    ) : (
      <StyledFollowButton
        following={following}
        onPress={event => {
          removePlatform(platform.followId);
          event.preventDefault();
        }}>
        <IconContainer>
          <SvgFollowCheckIcon />
        </IconContainer>
        <FollowButtonText following={following}>팔로잉</FollowButtonText>
      </StyledFollowButton>
    );
  };

  const FollowingForm = () => {
    return (
      <StyledRankingPlatform>
        <LinkPressable to={`/platforms/${platform.platformId}`}>
          <FollowHeaderView>
            <Logo source={{uri: platform.image}} />
            <NameAndCategory>
              <Name>{engToKor[platform.title]}</Name>
            </NameAndCategory>
            <FollowButton following={platform.followId} />
          </FollowHeaderView>
        </LinkPressable>
        <FollowCarousel
          pagesPerView={2.5}
          gap={16}
          data={platform.investments}
          carouselType="following"
        />
      </StyledRankingPlatform>
    );
  };

  const RankingForm = () => {
    console.log(platform);
    return (
      <LinkPressable to={`/platforms/${platform.platform}`}>
        <StyledRankingPlatform>
          <FollowHeaderView>
            <Logo source={{uri: platform.image}} />
            <NameAndCategory>
              <Name>{engToKor[platform.name]}</Name>
            </NameAndCategory>
            <FollowButton following={platform.followId} />
          </FollowHeaderView>
        </StyledRankingPlatform>
      </LinkPressable>
    );
  };
  return type === 'following' ? <FollowingForm /> : <RankingForm />;
};

const IconContainer = styled.View`
  margin-right: 4px;
`;
const FollowHeaderView = styled.View`
  flex-direction: row;
  align-items: center;
  padding: 12px 16px;
`;

export const StyledFollowButton = styled.Pressable<{following: boolean}>`
  padding: 6px 16px;
  background-color: ${({following, theme}) =>
    following ? theme.colors.prapGray6 : theme.colors.prapBlue4};
  border-radius: 14px;
  flex-direction: row;
  align-items: center;
`;

const FollowButtonText = styled(Text)<{following: boolean}>`
  ${({theme}) => theme.fonts.body11};
  color: ${({following, theme}) =>
    following
      ? process.env.FLAVOR === 'namuh' || process.env.FLAVOR === 'qv'
        ? theme.colors.prapGray2
        : theme.colors.prapBlue1
      : theme.colors.infoWhite};
  font-family: 'SpoqaHanSansNeo-Medium';
`;

const StyledRankingPlatform = styled.View`
  margin: 12px 0;
`;

const Rank = styled(Text)`
  ${({theme}) => theme.fonts.body18};
  color: ${({theme}) => theme.colors.prapGray2};
  min-width: 24px;
`;

const Logo = styled.Image`
  width: 40px;
  height: 40px;
  border-width: 1px;
  border-color: ${({theme}) => theme.colors.prapGray6};
  border-radius: 20px;
  margin-left: 10px;
`;

const NameAndCategory = styled.View`
  flex: 1;
  margin: 0 20px;
`;

const Name = styled(Text)`
  ${({theme}) => theme.fonts.body16};
  color: ${({theme}) => theme.colors.prapGray1};
`;

const Category = styled(Text)`
  ${({theme}) => theme.fonts.body13};
  color: ${({theme}) => theme.colors.prapGray3};
`;
