import {useAtomValue} from 'jotai';
import React, {useState} from 'react';
import {FlatList} from 'react-native';
import SvgEmptyComp from '../Details/EmptyComp';
import {EmptyCompArea, EmptyCompText} from '../Details/style/public.style';
import {korToEng} from '../Investments/InvestmentAPI/translate';
import MediumInvestment from '../Investments/MediumInvestment';
import {asyncScrapItemAtom} from './clippingApi/atoms';

export default function Clippings({category}: {category: string}) {
  const [toggle, setToggle] = useState(false);

  const onToggleSnackbar = () => setToggle(!toggle);
  const onDismissSnackbar = () => setToggle(false);

  const userScrapItem = useAtomValue(asyncScrapItemAtom);
  console.log(userScrapItem);

  if (userScrapItem[category].isLoading) {
    return <></>;
  }
  if (userScrapItem[category].isError) {
    return <></>;
  }

  return (
    <>
      <FlatList
        data={userScrapItem[category]?.data?.pages.flatMap(
          page => page.result.results,
        )}
        renderItem={({item: investment, index: rank}) => (
          <MediumInvestment
            investment={investment}
            rank={rank}
            productType={korToEng[category]}
            snackbarVisible={onToggleSnackbar}
            scrap={false}
          />
        )}
        ListEmptyComponent={
          <EmptyCompArea>
            <SvgEmptyComp />
            <EmptyCompText>등록된 상품이 없어요.</EmptyCompText>
          </EmptyCompArea>
        }
      />
    </>
  );
}
