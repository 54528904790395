import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import {FlatList} from 'react-native';
import {CustomText as Text} from '../Home/CustomText';
import LinkPressable from '../LinkPressable/LinkPressable';
import {MoreStackParamList} from '../More/MoreStackScreen';
import {PromotionState} from './PromotionScreen';
const PromotionsScreen = ({}: NativeStackScreenProps<
  MoreStackParamList,
  'More'
>) => {
  return (
    <FlatList
      data={
        [
          {
            id: '1',
            title: 'Foo',
            remaining: '2022-12-12',
            listImage: 'https://placeimg.com/360/145/any/1',
          },
          {
            id: '2',
            title: 'Bar',
            remaining: '2022-12-12',
            listImage: 'https://placeimg.com/360/145/any/2',
          },
        ] as PromotionState[]
      }
      renderItem={({item: promotion}) => (
        <LinkPressable to={`/promotions/${promotion.id}`}>
          <ListPromotion promotion={promotion} />
        </LinkPressable>
      )}
    />
  );
};

export default PromotionsScreen;

const ListPromotion = ({promotion}: {promotion: PromotionState}) => {
  return (
    <StyledListPromotion>
      <ListImage source={{uri: promotion.listImage}} />
      <Remaining>
        <RemainingText>{promotion.remaining}</RemainingText>
      </Remaining>
    </StyledListPromotion>
  );
};

const StyledListPromotion = styled.View``;

const ListImage = styled.Image`
  width: 100%;
  resize-mode: contain;
  aspect-ratio: 2.48;
`;

const Remaining = styled.View`
  position: absolute;
  margin: 12px;
  right: 0;
  bottom: 0;
  background-color: #212223b2;
  border-radius: 10px;
  padding: 2px 10px;
`;

const RemainingText = styled(Text)`
  color: ${({theme}) => theme.colors.infoWhite};
`;
