import styled from '@emotion/native';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import {useAtom} from 'jotai';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import {asyncScrapItemAtom} from './clippingApi/atoms';
import InvestmentClippingsTabScreen, {
  InvestmentClippingsTabParamList,
  investmentClippingsTabPathConfig,
} from './InvestmentClippingsTabScreen';
import RecentlyViewedTabScreen, {
  RecentlyViewedTabParamList,
  recentlyViewedTabPathConfig,
} from './RecentlyViewedTabScreen';

export type ClippingsTabParamList = {
  InvestmentClippingsTab: InvestmentClippingsTabParamList;
  RecentlyViewedTab: RecentlyViewedTabParamList;
};

export const clippingsTabPathConfig: PathConfig<ClippingsTabParamList> = {
  screens: {
    InvestmentClippingsTab: investmentClippingsTabPathConfig,
    RecentlyViewedTab: recentlyViewedTabPathConfig,
  },
};

const ClippingsTab = createMaterialTopTabNavigator<ClippingsTabParamList>();

export default function ClippingsTabScreen() {
  const [userScrapItem] = useAtom(asyncScrapItemAtom);

  console.log(userScrapItem);
  return (
    <StyledSafeAreaView edges={['left', 'right']}>
      <StyledClippingsTabNavigator>
        <ClippingsTab.Screen
          name="InvestmentClippingsTab"
          component={InvestmentClippingsTabScreen}
          options={{title: '스크랩한 상품'}}
        />
        <ClippingsTab.Screen
          name="RecentlyViewedTab"
          component={RecentlyViewedTabScreen}
          options={{title: '최근 본 상품'}}
        />
      </StyledClippingsTabNavigator>
    </StyledSafeAreaView>
  );
}

const StyledSafeAreaView = styled(SafeAreaView)`
  flex: 1;
`;

const StyledClippingsTabNavigator = styled(ClippingsTab.Navigator)``;
