import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

const getReviewList = async ({
  page = 1,
  userId,
  platformName,
}: {
  page?: number;
  userId?: string;
  platformName?: string;
}) => {
  // platform-reviews/?platform=platformName
  const ENDPOINT = `${PRAP_API_ENDPOINT}/platform-reviews/?platform=${platformName}`;

  const {data} = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${USER_HASH_ID}`,
    },
  });

  return data;
};

export function useReviewList(name: string) {
  return useInfiniteQuery(
    [`${name}_reviews`],
    ({pageParam = 1}) =>
      getReviewList({
        page: pageParam,
        platformName: name,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response?.next) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
}
