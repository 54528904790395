import styled from '@emotion/native';
import {CustomText as Text} from '../../Home/CustomText';

export const ShortFigures = ({
  figures,
}: {
  figures: {label: string; approx?: boolean; value: string}[];
}) => {
  return (
    <StyledShortFigures>
      {figures.map(item => (
        <ShortFigure key={item.label}>
          <ShortFigureLabel>{item.label}</ShortFigureLabel>
          <ShortFigureValue>
            {item.approx ? <ShortFigureApprox>약</ShortFigureApprox> : null}
            {item.value}
          </ShortFigureValue>
        </ShortFigure>
      ))}
    </StyledShortFigures>
  );
};

const StyledShortFigures = styled.View`
  margin-top: 6px;
  margin-bottom: 6px;
  flex-direction: row;
  justify-content: space-between;
`;

export const ShortFigure = styled.View``;

const ShortFigureLabel = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray3};
`;

const ShortFigureValue = styled(Text)`
  ${({theme}) => theme.fonts.body16};
  color: ${({theme}) => theme.colors.prapGray1};
`;

const ShortFigureApprox = styled(Text)`
  ${({theme}) => theme.fonts.body12};
  color: ${({theme}) => theme.colors.prapGray5};
`;
