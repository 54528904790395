import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {
  CATEGORY,
  PRAP_API_ENDPOINT,
} from '../../Investments/InvestmentAPI/constants';
import {FilterInfo} from '../../Investments/InvestmentAPI/useFilterInvestments';

import {korToEng} from '../../Investments/InvestmentAPI/translate';

export const useSearchAll = filterInfo => {
  return useInfiniteQuery(
    [`${filterInfo.searchByTitle}_searched`],
    ({pageParam = 1}) => getSearchAll({page: pageParam, value: filterInfo}),
    {
      getNextPageParam: (response, pages) => {
        console.log(response);
        if (response.next === null) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
};

const getSearchAll = async ({
  page = 1,
  value,
}: {
  page?: number;
  value?: FilterInfo;
}) => {
  const categorys = [
    korToEng[CATEGORY.FRACTIONAL],
    korToEng[CATEGORY.SNEAKERS],
    korToEng[CATEGORY.STARTUP],
    korToEng[CATEGORY.P2P],
    korToEng[CATEGORY.UNLISTED],
  ];

  const fetchAllSearch = async () => {
    const response = await Promise.all(
      categorys.map(async category => {
        const ENDPOINT = `${PRAP_API_ENDPOINT}/${category}`;
        const filteredSearchParams = new URLSearchParams();

        if (value?.searchByTitle) {
          if (category === 'sneakers' || category === 'unlisted-stocks') {
            filteredSearchParams.append(
              'name__icontains',
              value.searchByTitle.toString(),
            );
          } else {
            filteredSearchParams.append(
              'title__icontains',
              value.searchByTitle.toString(),
            );
          }
        }

        const data = await axios.get(
          `${ENDPOINT}/?${filteredSearchParams}&page=${page}`,
        );
        console.log(page);
        return {
          [category]: data,
          next: data.data.next,
        };
      }),
    );
    return response;
  };
  return fetchAllSearch();
};
