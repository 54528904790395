import axios from 'axios';
import {useMutation, useQueryClient} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

interface Review {
  platform?: string;
  content?: string;
  star?: number;
  deleteId?: number;
}

/// POST SCRAP ITEM

const postReview = async (addReview: Review) => {
  console.log(addReview);
  const response = await axios
    .post(`${PRAP_API_ENDPOINT}/platform-reviews/`, addReview, {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    })
    .catch(error => {
      console.log('postReview:', 'error.config:', error.config);
    });

  return response;
};

export const useReviewPostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(postReview, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries([`${variable.platform}_reviews`]);
      queryClient.invalidateQueries([`platform-info`]);
    },
    onError: error => {
      console.error(error);
    },
  });
};

/// DELETE SCRAP ITEM

const deleteReview = async (deleteReview: Review) => {
  const response = await axios
    .delete(`${PRAP_API_ENDPOINT}/platform-reviews/${deleteReview.deleteId}/`, {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    })
    .catch(error => {
      console.log('deleteReview:', 'error:', error);
    });

  return response;
};

export const useReviewDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteReview, {
    onMutate: async item => {
      await queryClient.cancelQueries(USER_HASH_ID);
    },
    onSuccess: (data, variable) => {
      console.log(variable);
      queryClient.invalidateQueries([`${variable.platform}_reviews`]);
      queryClient.invalidateQueries([`platform-info`]);
    },
    onError: error => {
      console.error(error);
    },
  });
};
