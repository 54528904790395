import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import axios from 'axios';
import React, {useState} from 'react';
import {useQuery} from 'react-query';
import {StackParamList} from '../App';
import {CustomText as Text} from '../Home/CustomText';
import {PRAP_API_ENDPOINT} from '../Investments/InvestmentAPI/constants';
import {useFilterInvestments} from '../Investments/InvestmentAPI/useFilterInvestments';
import Investments from '../Investments/Investments';

const ContentScreen = ({
  route,
}: NativeStackScreenProps<StackParamList, 'Content'>) => {
  const {id} = route.params;

  const content = useContent(id);

  const [filterOption, setFilterOption] = useState({
    status: ['모집예정', '모집중'],
    platform: [],
    totalAmountLow: '',
    totalAmountHigh: '',
    minAmountLow: '',
    minAmountHigh: '',
    sortOption: [],
  });
  const fractionalInvestmentData = useFilterInvestments(
    'fractional-investments',
    filterOption,
  );

  if (content.isLoading || fractionalInvestmentData.isLoading) return <></>;

  if (content.isError || fractionalInvestmentData.isError)
    return <Text>오류가 발생했습니다.</Text>;

  if (content.isSuccess && fractionalInvestmentData.isSuccess)
    return (
      <>
        <Investments
          ListHeaderComponent={
            <ListHeader>
              <BannerImage
                source={require('../CarouselView/image/namuh.png')}
              />
              <Body>{content.data.body}</Body>
              <Heading>가벼운 시드머니로 지금 바로 투자해보세요.</Heading>
            </ListHeader>
          }
          productType={'fractional-investments'}
          investmentData={fractionalInvestmentData}
        />
      </>
    );
};

export default ContentScreen;

export interface ContentState {
  id: string;
  body: string;
}

const ListHeader = styled.View`
  flex-direction: column;
`;

const BannerImage = styled.Image`
  width: 100%;
  resize-mode: cover;
  height: 180px;
`;

const Body = styled(Text)`
  ${({theme}) => theme.fonts.body13};
  padding: 32px 16px;
`;

const Heading = styled(Text)`
  ${({theme}) => theme.fonts.headline18};
  padding: 16px;
`;

async function getContent(id: string): Promise<ContentState> {
  return (await axios.get(`${PRAP_API_ENDPOINT}/contents/${id}/`)).data;
}

function useContent(id: string) {
  return useQuery(['content'], () => getContent(id));
}
