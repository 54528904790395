import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

const getPlatformInfo = async ({
  page = 1,
  userId,
  platformId,
}: {
  page?: number;
  userId?: string;
  platformId?: number;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/platforms/${platformId}/`;

  const {data} = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${userId}`,
    },
  });

  return data;
};

export function usePlatformInfo(platformId: number) {
  return useInfiniteQuery(
    [`platform-info`],
    ({pageParam = 1}) =>
      getPlatformInfo({
        page: pageParam,
        platformId: platformId,
        userId: USER_HASH_ID,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response?.next) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
}
