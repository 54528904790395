import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgRightArrowIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.529 7.529 4 13.057l.943.943 6.471-6.471-6.471-6.472L4 2l5.529 5.529Z"
      fill="#939498"
    />
  </Svg>
);

export default SvgRightArrowIcon;
