import styled from '@emotion/native';
import {PropsWithChildren} from 'react';
import {CustomText as Text} from '../../Home/CustomText';

export const Title = ({children}: PropsWithChildren<{}>) => {
  return <StyledTitle numberOfLines={2}>{children}</StyledTitle>;
};

const StyledTitle = styled(Text)`
  ${({theme}) => theme.fonts.body13};
  color: ${({theme}) => theme.colors.prapGray1};
  margin-bottom: 2px;
`;
