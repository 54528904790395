import axios from 'axios';
import {useQuery} from 'react-query';
import {PRAP_API_ENDPOINT} from '../../Investments/InvestmentAPI/constants';

const getUserRecentlyItem = async ({userId}: {userId?: string}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/recently_viewed/`;

  const {data} = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${userId}`,
    },
  });

  return data;
};

export function useUserRecentlyItem(userId: string) {
  return useQuery(`${userId}_recently`, () =>
    getUserRecentlyItem({userId: userId}),
  );
}
