import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgScrapIcon = props => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M17 4v14.55l-4-2.29-1-.56-1 .56-4 2.29V4h10Zm.83-2H6.17A1.09 1.09 0 0 0 5 3v19l7-4 7 4V3a1.09 1.09 0 0 0-1.17-1Z"
      fill="#AFB1B8"
    />
  </Svg>
);

export default SvgScrapIcon;
