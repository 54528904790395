/**
 * Sample React Native App
 * https://github.com/facebook/react-native
 *
 * Generated with the TypeScript template
 * https://github.com/react-native-community/react-native-template-typescript
 *
 * @format
 */
import {LogBox} from 'react-native';

import {css, ReactNativeStyle} from '@emotion/native';
import {Theme, ThemeProvider} from '@emotion/react';
import NetInfo from '@react-native-community/netinfo';
import {
  DefaultTheme,
  LinkingOptions,
  NavigationContainer,
  NavigatorScreenParams,
  Theme as NavigationTheme,
} from '@react-navigation/native';
import {createNativeStackNavigator} from '@react-navigation/native-stack';
import {useFonts} from 'expo-font';
import React, {useEffect} from 'react';
import {AppState, AppStateStatus, Linking, Platform} from 'react-native';
import {Provider as PaperProvider} from 'react-native-paper';
import {SafeAreaProvider} from 'react-native-safe-area-context';
import {ToastProvider} from 'react-native-toast-notifications';
import {
  focusManager,
  onlineManager,
  QueryClient,
  QueryClientProvider,
} from 'react-query';
import AnnouncementScreen from './Announcements/AnnouncementScreen';
import AnnouncementsScreen from './Announcements/AnnouncementsScreen';
import {BottomSheetModalProvider} from './BottomSheet/BottomSheet';
import BridgeScreen from './Bridge/BridgeScreen';
import CategoryTabScreen, {
  categoryTabPathConfig,
} from './Category/CategoryTabScreen';
import codePush from './codePush';
import ContentScreen from './Contents/ContentScreen';
import InvestmentDetailsScreen from './Details/InvestmentDetailsScreen';
import NHMoreTabScreen, {
  NHMoreTabParamList,
  nhMoreTabPathConfig,
} from './Home/NHMoreTabScreen';
import messaging, {useLogToken} from './messaging';
import NotFoundScreen from './NotFound/NotFoundScreen';
import NotificationsScreen from './Notifications/NotificationsScreen';
import PlatformDetailsScreen from './Platforms/PlatformDetailsScreen';
import PlatformsTabScreen from './Platforms/PlatformsTabScreen';
import ReviewScreen from './Platforms/ReviewScreen';
import WriteReviewScreen from './Platforms/WriteReviewScreen';
import PrivacyAndTermsScreen from './PrivacyAndTerms/PrivacyAndTermsScreen';
import PromotionScreen from './Promotions/PromotionScreen';
import PromotionsScreen from './Promotions/PromotionsScreen';
import {useSetDefaults} from './remoteConfig';
import SearchTabScreen from './Search/SearchTabScreen';
import SettingsScreen from './Settings/SettingsScreen';
import TabScreen, {TabParamList, tabPathConfig} from './TabScreen';
import WebContainer from './WebContainer';
/** @see https://react-query.tanstack.com/react-native#online-status-management */
onlineManager.setEventListener(setOnline => {
  return NetInfo.addEventListener(state => {
    setOnline(state.isConnected ? state.isConnected : undefined);
  });
});

/** @see https://react-query.tanstack.com/react-native#refetch-on-app-focus */
function onAppStateChange(status: AppStateStatus) {
  if (Platform.OS !== 'web') {
    focusManager.setFocused(status === 'active');
  }
}

/** @see https://reactnavigation.org/docs/typescript/#type-checking-screens-and-params-in-nested-navigator */
export type StackParamList = {
  Tab: NavigatorScreenParams<TabParamList>;
  Notifications: undefined;
  Announcement: {id: string};
  Promotion: {id: string};
  Settings: undefined;
  Review: {name: string; id: string};
  WriteReview: {name: string};
  PlatformDetails: {id: string};
  PlatformsTab: undefined;
  NotFound: undefined;
  Bridge: {category: string; id: string; url: string; 'platform-name'?: string};
  InvestmentDetails: {category: string; id: string};
  Category: {type: string};
  Clipping: undefined;
  Search: undefined;
  Announcements: undefined;
  Promotions: undefined;
  PrivacyAndTerms: undefined;
  Content: {id: string};
  NHMoreTab: NHMoreTabParamList;
};

const Stack = createNativeStackNavigator<StackParamList>();

const queryClient = new QueryClient();

/** @see https://reactnavigation.org/docs/configuring-links */
const linking: LinkingOptions<StackParamList> = {
  prefixes: ['prap:/'],
  config: {
    screens: {
      Tab: tabPathConfig,
      Notifications: 'notifications',
      Announcement: 'announcements/:id',
      Promotion: 'promotions/:id',
      Settings: 'settings',
      Review: 'review/:name',
      WriteReview: 'write-review/:name',
      Bridge: 'bridge',
      InvestmentDetails: 'investment-details/:category/:id',
      PlatformsTab: 'platforms',
      PlatformDetails: 'platforms/:id',
      Category: categoryTabPathConfig,
      Clipping: 'clipping',
      Announcements: 'announcements',
      Promotions: 'promotions',
      PrivacyAndTerms: 'privacy-and-terms',
      Content: 'contents/:id',
      NHMoreTab: nhMoreTabPathConfig,
      NotFound: '*',
    },
  },
  async getInitialURL() {
    // Check if app was opened from a deep link
    const url = await Linking.getInitialURL();

    if (url != null) {
      return url;
    }

    // Check if there is an initial firebase notification
    const message = await messaging().getInitialNotification();

    // Get deep link from data
    // if this is undefined, the app will open the default/home page
    return message?.data?.url;
  },
  subscribe(listener) {
    const onReceiveURL = ({url}: {url: string}) => listener(url);

    // Listen to incoming links from deep linking
    const subscription = Linking.addEventListener('url', onReceiveURL);

    // Listen to firebase push notifications
    const unsubscribeNotification = messaging().onNotificationOpenedApp(
      message => {
        const url = message.data?.url;

        if (url) {
          // Any custom logic to check whether the URL needs to be handled

          // Call the listener to let React Navigation handle the URL
          listener(url);
        }
      },
    );

    return () => {
      // Clean up the event listeners
      subscription.remove();
      unsubscribeNotification();
    };
  },
};

const App = codePush(() => {
  /** @see https://react-query.tanstack.com/react-native#refetch-on-app-focus */
  useEffect(() => {
    const subscription = AppState.addEventListener('change', onAppStateChange);

    return () => subscription.remove();
  }, []);

  useLogToken();

  useSetDefaults();
  React.useEffect(() => {
    LogBox.ignoreLogs(['VirtualizedLists should never be nested']);
  }, []);

  /** @see https://docs.expo.dev/versions/latest/sdk/font/ */
  const [fontsLoaded] = useFonts({
    'SpoqaHanSansNeo-Bold': require('spoqa-han-sans/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Bold.otf'),
    'SpoqaHanSansNeo-Light': require('spoqa-han-sans/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Light.otf'),
    'SpoqaHanSansNeo-Medium': require('spoqa-han-sans/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Medium.otf'),
    'SpoqaHanSansNeo-Regular': require('spoqa-han-sans/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Regular.otf'),
    'SpoqaHanSansNeo-Thin': require('spoqa-han-sans/Subset/SpoqaHanSansNeo/SpoqaHanSansNeo-Thin.otf'),
  });

  if (!fontsLoaded) {
    return null;
  }

  return (
    <QueryClientProvider client={queryClient}>
      <SafeAreaProvider>
        <PaperProvider>
          <ThemeProvider theme={theme}>
            <ToastProvider>
              <BottomSheetModalProvider>
                <WebContainer>
                  <NavigationContainer
                    linking={linking}
                    theme={theme.navigation}>
                    <Stack.Navigator
                      screenOptions={{
                        headerStyle: {
                          backgroundColor:
                            process.env.FLAVOR === 'qv'
                              ? '#151F6D'
                              : theme.colors.bgGray,
                        },
                        headerTintColor:
                          process.env.FLAVOR === 'qv'
                            ? theme.colors.infoWhite
                            : undefined,
                        headerShadowVisible: false,
                      }}>
                      <Stack.Screen
                        name="Tab"
                        component={TabScreen}
                        options={{headerShown: false}}
                      />
                      <Stack.Screen
                        name="Notifications"
                        options={{
                          title:
                            process.env.FLAVOR == 'namuh' ||
                            process.env.FLAVOR == 'qv'
                              ? '소식'
                              : '알림',
                        }}
                        component={NotificationsScreen}
                      />
                      <Stack.Screen
                        name="Announcement"
                        options={{title: '공지사항'}}
                        component={AnnouncementScreen}
                      />
                      <Stack.Screen
                        name="Promotion"
                        options={{title: '이벤트'}}
                        component={PromotionScreen}
                      />
                      <Stack.Screen
                        name="Settings"
                        component={SettingsScreen}
                        options={{title: '앱 설정'}}
                      />
                      <Stack.Screen
                        name="Review"
                        options={{title: '리뷰'}}
                        component={ReviewScreen}
                      />
                      <Stack.Screen
                        name="WriteReview"
                        options={{title: '리뷰 쓰기'}}
                        component={WriteReviewScreen}
                      />
                      <Stack.Screen
                        name="Bridge"
                        options={{title: '로딩'}}
                        component={BridgeScreen}
                      />
                      <Stack.Screen
                        name="InvestmentDetails"
                        options={{title: '투자 상품 정보'}}
                        component={InvestmentDetailsScreen}
                      />
                      <Stack.Screen
                        name="PlatformsTab"
                        options={{title: ''}}
                        component={PlatformsTabScreen}
                      />
                      <Stack.Screen
                        name="PlatformDetails"
                        options={{title: '플랫폼 정보'}}
                        component={PlatformDetailsScreen}
                      />
                      <Stack.Screen
                        name="Category"
                        options={{title: '카테고리'}}
                        component={CategoryTabScreen}
                      />
                      <Stack.Screen
                        name="Search"
                        options={{title: '전체검색'}}
                        component={SearchTabScreen}
                      />
                      <Stack.Screen
                        name="Announcements"
                        component={AnnouncementsScreen}
                        options={{title: '공지사항'}}
                      />
                      <Stack.Screen
                        name="Promotions"
                        component={PromotionsScreen}
                        options={{title: '이벤트'}}
                      />
                      <Stack.Screen
                        name="PrivacyAndTerms"
                        component={PrivacyAndTermsScreen}
                        options={{title: '서비스 이용안내'}}
                      />
                      <Stack.Screen
                        name="Content"
                        component={ContentScreen}
                        options={{title: '콘텐츠'}}
                      />
                      <Stack.Screen
                        name="NHMoreTab"
                        component={NHMoreTabScreen}
                        options={{title: '더보기'}}
                      />
                      <Stack.Screen
                        name="NotFound"
                        options={{title: '페이지를 찾을 수 없습니다'}}
                        component={NotFoundScreen}
                      />
                    </Stack.Navigator>
                  </NavigationContainer>
                </WebContainer>
              </BottomSheetModalProvider>
            </ToastProvider>
          </ThemeProvider>
        </PaperProvider>
      </SafeAreaProvider>
    </QueryClientProvider>
  );
});

export default App;
/** @see https://emotion.sh/docs/typescript#define-a-theme */
declare module '@emotion/react' {
  export interface Theme {
    colors: {
      prapBlue1: string;
      prapBlue2: string;
      prapBlue3: string;
      prapBlue4: string;
      prapGray1: string;
      prapGray2: string;
      prapGray3: string;
      prapGray4: string;
      prapGray5: string;
      prapGray6: string;
      infoBlue: string;
      infoRed: string;
      infoYellow: string;
      infoWhite: string;
      bgGray: string;
    };
    fonts: {
      body10: ReactNativeStyle;
      body11: ReactNativeStyle;
      body12: ReactNativeStyle;
      body13: ReactNativeStyle;
      body14: ReactNativeStyle;
      body16: ReactNativeStyle;
      body18: ReactNativeStyle;
      headline18: ReactNativeStyle;
      headline24: ReactNativeStyle;
      headline36: ReactNativeStyle;
    };
    navigation: NavigationTheme;
  }
}

const theme: Theme = {
  colors: {
    //primary blue
    prapBlue1:
      process.env.FLAVOR === 'namuh'
        ? '#84C13D'
        : process.env.FLAVOR === 'qv'
        ? '#FFAA1A'
        : '#2D22A5',
    prapBlue2:
      process.env.FLAVOR === 'namuh'
        ? '#84C13D'
        : process.env.FLAVOR === 'qv'
        ? '#FFAA1A'
        : '#3A56E9',
    prapBlue3:
      process.env.FLAVOR === 'namuh'
        ? '#84C13D'
        : process.env.FLAVOR === 'qv'
        ? '#FFAA1A'
        : '#8295F6',
    prapBlue4:
      process.env.FLAVOR === 'namuh'
        ? '#84C13D'
        : process.env.FLAVOR === 'qv'
        ? '#FFAA1A'
        : '#E0E4FA',

    //achromatic grey
    prapGray1: '#212123',
    prapGray2: '#676A70',
    prapGray3: '#8D8F98',
    prapGray4: '#AFB1B8',
    prapGray5: '#DBDCDF',
    prapGray6: '#F0F2F5',

    //etc color
    infoBlue: '#5378FB',
    infoRed: '#FB5353',
    infoYellow: '#FFB23F',
    infoWhite: '#FEFEFE',

    // bg
    bgGray: '#f9f9f9',
  },
  fonts: {
    body10: css`
      font-size: 10px;
      line-height: 15px;
    `,
    body11: css`
      font-size: 11px;
      line-height: 16.5px;
    `,
    body12: css`
      font-size: 12px;
      line-height: 18px;
    `,
    body13: css`
      font-size: 13px;
      line-height: 19.5px;
    `,
    body14: css`
      font-size: 14px;
      line-height: 21px;
    `,
    body16: css`
      font-size: 16px;
      line-height: 24px;
    `,
    body18: css`
      font-size: 18px;
      line-height: 27px;
    `,
    headline18: css`
      font-size: 18px;
      line-height: 27px;
      font-weight: bold;
    `,
    headline24: css`
      font-size: 24px;
      line-height: 27px;
      font-weight: bold;
    `,
    headline36: css`
      font-size: 36px;
      line-height: 54px;
      font-weight: bold;
    `,
  },
  navigation: {
    ...DefaultTheme,
    colors: {
      ...DefaultTheme.colors,
      background: '#f9f9f9',
    },
  },
};
