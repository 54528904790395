import * as React from 'react';
import Svg, {G, Path, Defs, ClipPath} from 'react-native-svg';

const SvgSneakersIcon = props => (
  <Svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G clipPath="url(#SneakersIcon_svg__a)">
      <Path
        d="M2.122 30.49h39.757a2.244 2.244 0 0 0 1.545-.71A2.175 2.175 0 0 0 44 28.2a2.292 2.292 0 0 0-.288-1.151 2.336 2.336 0 0 0-.828-.861l-22.77-13.014a2.006 2.006 0 0 0-2.514.433 12.242 12.242 0 0 1-4.116 3.058 12.416 12.416 0 0 1-5.03 1.12c-2.435 0-2.278-.48-3.567-1.083a2.15 2.15 0 0 0-2.077.221c-.297.21-.535.493-.688.82a2.061 2.061 0 0 0-.189 1.048c-.346 1.702 0 3.42-.299 5.122-.298 1.455-1.571 3.435-1.571 4.364C0 28.88 0 29.159 2.122 30.49Z"
        fill="#F5D1D2"
      />
      <Path
        d="M0 28.84v1.392C0 31.768 1.461 33 3.253 33H32.59a71.35 71.35 0 0 0 8.69-.536c.758-.117 1.453-.544 1.953-1.202.5-.657.773-1.5.766-2.37 0 0-30.957-1.392-34.226-1.392-3.28.203-6.545.65-9.774 1.34Z"
        fill="#F8D494"
      />
      <Path
        d="M0 28.478v1.207c.006.28.076.555.204.804L44 28.942v-.402s-30.957-1.207-34.226-1.207c-3.28.17-6.545.553-9.774 1.145ZM36.944 24.006l.535-.898-17.364-9.934a2.006 2.006 0 0 0-2.515.433 13.064 13.064 0 0 1-2.168 1.919l17.285 9.687a3.186 3.186 0 0 0 2.364.247 3.135 3.135 0 0 0 1.863-1.454Z"
        fill="#ED747F"
      />
      <Path
        d="M22.565 16.84a.667.667 0 0 1-.377-.107.766.766 0 0 1-.376-.464.746.746 0 0 1 .078-.589l.77-1.346a.813.813 0 0 1 1.316-.082.753.753 0 0 1 .05.856l-.77 1.346a.78.78 0 0 1-.69.387Z"
        fill="#FFDA96"
      />
      <Path
        d="M25.3 18.373a.748.748 0 0 1-.393-.108.767.767 0 0 1-.367-.468.746.746 0 0 1 .085-.585l.77-1.346a.782.782 0 0 1 .477-.363.8.8 0 0 1 .83.276.772.772 0 0 1 .06.86l-.77 1.347a.812.812 0 0 1-.692.387ZM28.034 19.905a.715.715 0 0 1-.393-.108.775.775 0 0 1-.37-.466.763.763 0 0 1 .072-.586l.785-1.347a.78.78 0 0 1 .478-.362.802.802 0 0 1 .599.076.787.787 0 0 1 .368.47.765.765 0 0 1-.077.59l-.786 1.346a.78.78 0 0 1-.676.387ZM30.769 21.437a.859.859 0 0 1-.393-.092.779.779 0 0 1-.299-1.068l.786-1.346a.762.762 0 0 1 .474-.362.782.782 0 0 1 .595.083.763.763 0 0 1 .298 1.052l-.786 1.347a.78.78 0 0 1-.675.386ZM33.487 22.97a.796.796 0 0 1-.377-.093.779.779 0 0 1-.299-1.068l.77-1.346a.812.812 0 0 1 1.316-.082.753.753 0 0 1 .052.855l-.77 1.346a.78.78 0 0 1-.692.387ZM36.221 24.5a.907.907 0 0 1-.392-.092.763.763 0 0 1-.283-1.052l.77-1.347a.76.76 0 0 1 .47-.37.782.782 0 0 1 .598.076.763.763 0 0 1 .299 1.052l-.77 1.347a.812.812 0 0 1-.691.386Z"
        fill="#F8D494"
      />
    </G>
    <Defs>
      <ClipPath id="SneakersIcon_svg__a">
        <Path fill="#fff" d="M0 0h44v44H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgSneakersIcon;
