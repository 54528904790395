import { useTheme } from '@emotion/react';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgFollowCheckIcon = props => {
  const theme = useTheme();
  return (
    <Svg
      width={12}
      height={13}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.822 3.117a.5.5 0 0 1 .06.705l-5.6 6.667-3.636-3.635a.5.5 0 1 1 .708-.708l2.864 2.865 4.9-5.833a.5.5 0 0 1 .704-.06Z"
        fill={theme.colors.prapGray3}
      />
    </Svg>
  );
}

export default SvgFollowCheckIcon;
