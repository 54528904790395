import styled from '@emotion/native';
import {CustomText as Text} from '../../Home/CustomText';
const StyledProductContainer = styled.Pressable`
  padding: 18px 15px;

  margin-bottom: 8px;
`;

const PlatformProductBodyWrap = styled.View`
  flex-direction: row;
`;

const ProductImageWrap = styled.View`
  width: 106px;
  height: 106px;
  justify-content: center;
  align-items: center;
  background: ${({theme}) => theme.colors.prapGray6};
  margin-right: 16px;
`;

const ProductInfoContainer = styled.View`
  flex: 1;
`;
const ProductClippingWrap = styled.View`
  flex-direction: row;
  align-items: center;
  margin-bottom: 12px;
`;

const FilterItemForm = styled.TouchableOpacity`
  background: ${({theme}) => theme.colors.prapGray6};
  padding: 2px 12px;
  justify-content: center;
  border-radius: 20px;
`;
const ScrollItemText = styled(Text)`
  color: ${({theme}) => theme.colors.prapBlue2};
  font-size: ${({theme}) => theme.fonts.body12};
  font-family: 'SpoqaHanSansNeo-Medium';
`;

const ClipView = styled.View`
  flex: 1;
  align-items: flex-end;
`;
const ClippingCTA = styled.Pressable``;

const ProductNameWrap = styled.View``;

const ProductName = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body13};
  color: ${({theme}) => theme.colors.prapGray1};
  margin-bottom: 4px;
`;
const ProductAuthor = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray2};
`;
const ProductInfoWrap = styled.View`
  flex-direction: column;
`;
const PlatformInfoView = styled.View``;

export {
  StyledProductContainer,
  PlatformProductBodyWrap,
  ProductImageWrap,
  ProductInfoContainer,
  ProductClippingWrap,
  FilterItemForm,
  ScrollItemText,
  ClipView,
  ClippingCTA,
  ProductNameWrap,
  ProductName,
  ProductAuthor,
  ProductInfoWrap,
  PlatformInfoView,
};
