import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import React from 'react';
import {clippingsTabPathConfig} from '../Clippings/ClippingsTabScreen';
import InvestmentClippingsTabScreen from '../Clippings/InvestmentClippingsTabScreen';
import RecentlyViewedTabScreen from '../Clippings/RecentlyViewedTabScreen';
import FollowingScreen from '../Platforms/FollowingScreen';
import {platformsTabPathConfig} from '../Platforms/PlatformsTabScreen';
import RankingTabScreen from '../Platforms/RankingTabScreen';

export type NHMoreTabParamList = {
  Following: {productType: string};
  RankingTab: {productType: string};
  InvestmentClippings: {productType: string};
  RecentlyViewed: {productType: string};
};

export const nhMoreTabPathConfig: PathConfig<NHMoreTabParamList> = {
  path: 'nh-more',
  screens: {
    Following: platformsTabPathConfig.screens?.Following,
    RankingTab: platformsTabPathConfig.screens?.RankingTab,
    InvestmentClippings: clippingsTabPathConfig.screens?.InvestmentClippingsTab,
    RecentlyViewed: clippingsTabPathConfig.screens?.RecentlyViewedTab,
  },
};

const NHMoreTab = createMaterialTopTabNavigator<NHMoreTabParamList>();

const StyledMoreTabNavigator = styled(NHMoreTab.Navigator)``;

export default function NHMoreTabScreen() {
  const theme = useTheme();

  return (
    <>
      <StyledMoreTabNavigator
        screenOptions={{
          swipeEnabled: false,
          tabBarIndicatorStyle: {
            backgroundColor: theme.colors.prapBlue1,
          },
          tabBarLabelStyle: {
            fontSize: 11,
          },
          tabBarStyle: {
            backgroundColor:
              process.env.FLAVOR === 'qv' ? '#151F6D' : '#f9f9f9',
          },
          tabBarActiveTintColor:
            process.env.FLAVOR === 'qv'
              ? theme.colors.prapBlue1
              : theme.colors.prapGray1,
          tabBarInactiveTintColor:
            process.env.FLAVOR === 'qv'
              ? theme.colors.infoWhite
              : theme.colors.prapGray1,
        }}
        initialRouteName="InvestmentClippings">
        <NHMoreTab.Screen
          name="Following"
          component={FollowingScreen}
          options={{title: '팔로잉'}}
        />
        <NHMoreTab.Screen
          name="RankingTab"
          component={RankingTabScreen}
          options={{title: '플랫폼랭킹'}}
        />
        <NHMoreTab.Screen
          name="InvestmentClippings"
          component={InvestmentClippingsTabScreen}
          options={{title: '스크랩한상품'}}
        />
        <NHMoreTab.Screen
          name="RecentlyViewed"
          component={RecentlyViewedTabScreen}
          options={{title: '최근본상품'}}
        />
      </StyledMoreTabNavigator>
    </>
  );
}
