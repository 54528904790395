import {css} from '@emotion/native';
import {createBottomTabNavigator} from '@react-navigation/bottom-tabs';
import {NavigatorScreenParams, PathConfig} from '@react-navigation/native';
import React from 'react';
import ClippingsIcon from './Clippings/ClippingsIcon';
import ClippingsTabScreen, {
  ClippingsTabParamList,
  clippingsTabPathConfig,
} from './Clippings/ClippingsTabScreen';
import FocusedClippingsIcon from './Clippings/FocusedClippingsIcon';
import HomeTopTabScreen, {
  HomeTopTabParamList,
  homeTopTabPathConfig,
} from './Home/HomeTopTabScreen';
import FocusedHomeIcon from './Home/icon/FocusedHomeIcon';
import HomeIcon from './Home/icon/HomeIcon';
import FocusedMoreIcon from './More/FocusedMoreIcon';
import MoreIcon from './More/MoreIcon';
import MoreScreen from './More/MoreScreen';
import FocusedPlatformsIcon from './Platforms/icon/FocusedPlatformsIcon';
import PlatformsIcon from './Platforms/icon/PlatformsIcon';
import PlatformsTabScreen, {
  PlatformsTabParamList,
  platformsTabPathConfig,
} from './Platforms/PlatformsTabScreen';

const Tab = createBottomTabNavigator<TabParamList>();

const TabScreen = () => {
  return (
    <Tab.Navigator
      screenOptions={{
        headerShown: false,
        tabBarInactiveTintColor: '#b3b4b7',
        tabBarActiveTintColor: '#4c5d5e',
        tabBarStyle: tabBarStyle(),
      }}>
      <Tab.Screen
        name="HomeTopTab"
        component={HomeTopTabScreen}
        options={{
          title: '홈',
          tabBarIcon: ({focused}) =>
            focused ? <FocusedHomeIcon /> : <HomeIcon />,
        }}
      />
      <Tab.Screen
        name="PlatformsTab"
        component={PlatformsTabScreen}
        options={{
          title: '플랫폼',
          tabBarIcon: ({focused}) =>
            focused ? <FocusedPlatformsIcon /> : <PlatformsIcon />,
        }}
      />
      <Tab.Screen
        name="ClippingsTab"
        component={ClippingsTabScreen}
        options={{
          title: '스크랩',
          tabBarIcon: ({focused}) =>
            focused ? <FocusedClippingsIcon /> : <ClippingsIcon />,
        }}
      />
      <Tab.Screen
        name="More"
        component={MoreScreen}
        options={{
          title: '더보기',
          tabBarIcon: ({focused}) =>
            focused ? <FocusedMoreIcon /> : <MoreIcon />,
        }}
      />
    </Tab.Navigator>
  );
};

export default TabScreen;

/** @see https://reactnavigation.org/docs/typescript/#type-checking-screens-and-params-in-nested-navigator */
export type TabParamList = {
  HomeTopTab: NavigatorScreenParams<HomeTopTabParamList>;
  PlatformsTab: NavigatorScreenParams<PlatformsTabParamList>;
  ClippingsTab: NavigatorScreenParams<ClippingsTabParamList>;
  More: undefined;
};

export const tabPathConfig: PathConfig<TabParamList> = {
  screens: {
    HomeTopTab: homeTopTabPathConfig,
    PlatformsTab: platformsTabPathConfig,
    ClippingsTab: clippingsTabPathConfig,
    More: 'more',
  },
};

const tabBarStyle = () => css`
  ${process.env.FLAVOR == 'namuh' || process.env.FLAVOR == 'qv'
    ? 'display: none'
    : ''};
`;
