import {useTheme} from '@emotion/react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import React from 'react';
import {FractionalInvestmentsScreen} from './FractionalInvestmentsScreen';
import {P2PInvestmentsScreen} from './P2PInvestmentsScreen';
import {SneakersScreen} from './SneakersScreen';
import {StatupsScreen} from './StartupsScreen';
import {UnlistedStocksScreen} from './UnlistedStocksScreen';
export type CategoryTabParamList = {
  P2PInvestments: undefined;
  FractionalInvestments: undefined;
  Startups: undefined;
  UnlistedStocks: undefined;
  Sneakers: undefined;
};

export const categoryTabPathConfig: PathConfig<CategoryTabParamList> = {
  screens: {
    P2PInvestments: 'p2p-investments',
    FractionalInvestments: 'fractional-investments',
    Startups: 'startups',
    UnlistedStocks: 'unlisted-stocks',
    Sneakers: 'sneakers',
  },
};

const CategoryTab = createMaterialTopTabNavigator<CategoryTabParamList>();

export default function CategoryTabScreen() {
  const theme = useTheme();

  return (
    <CategoryTab.Navigator
      screenOptions={{
        tabBarIndicatorStyle: {
          backgroundColor: theme.colors.prapBlue1,
        },
        tabBarLabelStyle: {
          fontSize: 13,
        },
        tabBarScrollEnabled: true,
        tabBarItemStyle: {
          width: 96,
        },
        tabBarStyle: {
          backgroundColor: process.env.FLAVOR === 'qv' ? '#151F6D' : '#f9f9f9',
        },
        tabBarActiveTintColor:
          process.env.FLAVOR === 'qv'
            ? theme.colors.prapBlue1
            : theme.colors.prapGray1,
        tabBarInactiveTintColor:
          process.env.FLAVOR === 'qv'
            ? theme.colors.infoWhite
            : theme.colors.prapGray1,
      }}>
      <CategoryTab.Screen
        name="FractionalInvestments"
        component={FractionalInvestmentsScreen}
        options={{title: '조각투자'}}
      />
      <CategoryTab.Screen
        name="Sneakers"
        component={SneakersScreen}
        options={{title: '스니커즈리셀'}}
      />
      <CategoryTab.Screen
        name="Startups"
        component={StatupsScreen}
        options={{title: '스타트업펀딩'}}
      />
      <CategoryTab.Screen
        name="P2PInvestments"
        component={P2PInvestmentsScreen}
        options={{title: 'P2P투자'}}
      />

      <CategoryTab.Screen
        name="UnlistedStocks"
        component={UnlistedStocksScreen}
        options={{title: '비상장주식'}}
      />
    </CategoryTab.Navigator>
  );
}
