import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgCategoryFilterIcon = props => (
  <Svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M1 5.5h1.09a1.5 1.5 0 0 1 2.82 0H11v1H4.91a1.5 1.5 0 0 1-2.82 0H1v-1ZM5 6a1.365 1.365 0 0 1 0 0Zm-1.5.5a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1ZM2 6.26ZM11 9.5H9.91a1.5 1.5 0 0 1-2.82 0H1v-1h6.09a1.5 1.5 0 0 1 2.82 0H11v1ZM7 9a1.365 1.365 0 0 1 0 0Zm1.5-.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm1.475.24ZM11 3.5H9.91a1.5 1.5 0 0 1-2.82 0H1v-1h6.09a1.5 1.5 0 0 1 2.82 0H11v1ZM7 3a1.365 1.365 0 0 1 0 0Zm1.5-.5a.5.5 0 1 0 0 1 .5.5 0 0 0 0-1Zm1.475.24Z"
      fill="#676A70"
    />
  </Svg>
);

export default SvgCategoryFilterIcon;
