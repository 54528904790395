export const priceToKor = (price: any) => {
  if (!price) {
    return '';
  }
  const inputPrice = price.toString() ?? 0;
  let result = '';
  if (inputPrice.length < 8) {
    let row = price.toLocaleString('ko-KR');

    result = row;
  }

  if (inputPrice.length === 8) {
    let thousand = inputPrice.split('').splice(0, 4).join('');

    result = `${thousand}만`;
  }
  if (inputPrice.length > 8) {
    let million = inputPrice
      .split('')
      .splice(0, inputPrice.length - 8)
      .join('');
    let thousand = inputPrice
      .split('')
      .splice(inputPrice.length - 8, 1)
      .join();

    thousand === '0'
      ? (result = `${million}억`)
      : (result = `${million}.${thousand}억`);
  }

  return result;
};
