import styled from '@emotion/native';
import ContentLoader, {Circle, Rect} from 'react-content-loader/native';
import {Dimensions} from 'react-native';

const windowWidth = Dimensions.get('window').width;

export const PlatformListSkeleton = () => {
  return (
    <SmallSkeletonWrap>
      <ContentLoader
        speed={2}
        width={windowWidth}
        height={85}
        viewBox={`0 0 ${windowWidth} 50`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <Circle cx="30" cy="26" r="20" />
        <Rect x="80" y="15" rx="5" ry="5" width="220" height="16" />
      </ContentLoader>
    </SmallSkeletonWrap>
  );
};

export const RowSkeletonView = styled.View`
  flex-direction: row;
  padding: 0 15px;
`;

export const ColumnSkeletonView = styled.View`
  padding: 0 15px;
`;

const SmallSkeletonWrap = styled.View`
  margin-right: 16px;
`;
const MediumSkeletonWrap = styled.View`
  flex-direction: row;
`;
const CarouselSkeletonWrap = styled.View`
  margin-bottom: 8px;
`;
