import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgSearchIcon = props => (
  <Svg
    width={12}
    height={12}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M2 4.75a2.75 2.75 0 1 0 5.5 0 2.75 2.75 0 0 0-5.5 0Zm-1 0a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z"
      fill="#ADADAD"
    />
    <Path
      d="m6.965 7.67.705-.705 3.185 3.18-.71.71-3.18-3.185Z"
      fill="#ADADAD"
    />
  </Svg>
);

export default SvgSearchIcon;
