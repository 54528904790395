import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgMoreFollowingIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.333 2h5.334v4.667a2.667 2.667 0 0 1-5.333 0V2Zm1.334 1.333v3.334a1.333 1.333 0 1 0 2.667 0V3.333H2.667Z"
      fill="#666"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.333 2h5.334v4.667a2.667 2.667 0 1 1-5.333 0V2Zm1.334 1.333v3.334a1.333 1.333 0 1 0 2.667 0V3.333H6.667Z"
      fill="#666"
    />
    <Path
      d="M2 6.667V14h12V6.667a2 2 0 0 1-1.333 1.886v4.114H3.333V8.553A2 2 0 0 1 2 6.667Z"
      fill="#666"
    />
    <Path
      d="M5.886 7.333h.228A1.997 1.997 0 0 1 6 6.667c0 .234-.04.458-.114.666ZM9.886 7.333h.228A1.997 1.997 0 0 1 10 6.667c0 .234-.04.458-.114.666Z"
      fill="#666"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M9.334 2h5.333v4.667a2.667 2.667 0 1 1-5.333 0V2Zm1.333 1.333v3.334a1.333 1.333 0 0 0 2.667 0V3.333h-2.667Z"
      fill="#666"
    />
  </Svg>
);

export default SvgMoreFollowingIcon;
