export interface TestDataType {
  id: string;
  title?: string;
  color?: string;
  height?: string;
  image?: string;
  author?: string;
  productType?: string;
  sellerName?: string;

  // sellerAPI
  productInfo?: DetailData;
}

const dummyStockData: DetailData = {
  productName: '비바리퍼블리카',
  price: {
    lowest: 8900,
    highest: 9100,
    option: '1 당',
  },
  copValue: '20조 8467억',
};
const dummySneakersData: DetailData = {
  imageSrc:
    'https://img.soldout.co.kr/items/2020/09/07/db5f951e-c742-4448-a3a2-e42f0e90f4ae.png/soldout/resize/564x564/optimize',
  productName: '조던 1 레트로 하이 OG 블랙토 2016',
  price: {
    lowest: 1400000,
    highest: 1550000,
  },
};

export interface DetailData {
  productName: string;
  price: PriceInfo;
  copValue?: string;
  imageSrc?: string;
}

type PriceInfo = {
  lowest: number;
  highest: number;
  option?: string;
};

export const _AUTO_DATA: TestDataType[] = [
  {id: 'event0', color: 'gold', height: '174px'},
  {id: 'event1', color: 'crimson', height: '174px'},
  {id: 'event2', color: 'silver', height: '174px'},
  {id: 'event3', color: 'lime', height: '174px'},
  {id: 'event4', color: 'pink', height: '174px'},
];

export const _DATA: TestDataType[] = [
  {
    id: 'd1',
    title: 'The Great Doge Face',
    image: 'https://i.kym-cdn.com/entries/icons/original/000/013/564/doge.jpg',
    author: 'Elon Musk',
    productType: 'NFT',
    sellerName: '테사',
  },
  {
    id: 'd2',
    title: 'Starlink T-shirts',
    image:
      'https://i5.walmartimages.com/asr/e8da72d9-cb30-4c9e-97b6-7e1f715151e2_1.7c840356e89db120cfa577f10423ebec.jpeg',
    author: 'Elon Musk',
    productType: 'NFT',
    sellerName: '아트앤가이드',
  },
  {
    id: 'd3',
    title: 'DALL·E 2',
    image: 'https://openai.com/content/images/2021/08/openai-avatar.png',
    author: 'Elon Musk',
    productType: 'STOCK',
    productInfo: dummyStockData,
  },
  {
    id: 'd4',
    title: 'Model S',
    image:
      'https://tesla-cdn.thron.com/delivery/public/image/tesla/56cb8c41-e898-44ce-b6b7-fe9b9a05f529/bvlatuR/std/1200x628/MS-Social',
    author: 'Elon Musk',
    productType: 'SNEAKERS',
    productInfo: dummySneakersData,
  },
  {
    id: 'd5',
    title: 'iPhone',
    image:
      'https://upload.wikimedia.org/wikipedia/commons/thumb/b/b5/IPhone_13_Pro_vector.svg/800px-IPhone_13_Pro_vector.svg.png',
    author: 'Elon Musk',
    productType: 'SNEAKERS',
    sellerName: '테사',
    productInfo: dummySneakersData,
  },
];
interface TestDetailData {
  sellerName: string;
  price: number;
  option: number;
  imageSrc?: string;
}

export const dummyDetailData: TestDetailData[] = [
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 52,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 140,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 95,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 200,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 310,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 19,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 120,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 70,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 220,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 58,
  },
  {
    sellerName: '서울거래소 비상장',
    price: 520000,
    option: 33,
  },
];

export const dummyDetailSneakersData: TestDetailData[] = [
  {
    sellerName: 'KREAM',
    price: 1400000,
    option: 230,
  },
  {
    sellerName: 'KREAM',
    price: 1420000,
    option: 230,
  },
  {
    sellerName: 'KREAM',
    price: 1415000,
    option: 230,
  },
  {
    sellerName: 'KREAM',
    price: 1440000,
    option: 240,
  },
  {
    sellerName: 'KREAM',
    price: 1450000,
    option: 240,
  },
  {
    sellerName: 'KREAM',
    price: 1465000,
    option: 250,
  },
  {
    sellerName: 'KREAM',
    price: 1470000,
    option: 250,
  },
  {
    sellerName: 'KREAM',
    price: 1460000,
    option: 250,
  },
  {
    sellerName: 'KREAM',
    price: 1490000,
    option: 260,
  },
  {
    sellerName: 'KREAM',
    price: 1485000,
    option: 260,
  },
  {
    sellerName: 'KREAM',
    price: 1495000,
    option: 260,
  },

  {
    sellerName: 'KREAM',
    price: 1510000,
    option: 270,
  },
  {
    sellerName: 'KREAM',
    price: 1520000,
    option: 270,
  },
  {
    sellerName: 'KREAM',
    price: 1535000,
    option: 270,
  },
  {
    sellerName: 'KREAM',
    price: 1550000,
    option: 270,
  },
];

export type PlatformProduct = {
  imageSrc: string;
  productName: string;
  authorName: string;
  options: {
    subscription: number;
    minInvestment: number;
    fee?: number;
  };
  value: number;
  extra: string;
  status: string;
  type: string;
};

export const platformProductData: PlatformProduct[] = [
  {
    imageSrc:
      'https://dbm9jhyrx0h6k.cloudfront.net/service/1650361182924-a1d1e639-8d44-43f1-8288-ceaa7cc88e1c.png',
    productName: 'Body Drawing 76-2-2010-2(N.Y)',
    authorName: '이건용',
    options: {
      subscription: 436500000,
      minInvestment: 10000000,
    },
    value: 0.59,
    extra: '120명이 투자',
    status: '15일남음',
    type: 'fractional-investments',
  },
  {
    imageSrc:
      'https://dev.apiblue.net/so2/prod/upload/global/products/product_20220425164857000180-00000028.png',
    productName: 'Blow up',
    authorName: '에밀리 메이 스미스',
    options: {
      subscription: 436500000,
      minInvestment: 10000000,
    },
    value: 0.71,
    extra: '120명이 투자',
    status: '15일남음',
    type: 'sneakers',
  },
  {
    imageSrc:
      'https://dbm9jhyrx0h6k.cloudfront.net/service/1651654436803-5a76c61d-7045-4480-a2a1-f8085ec5ba5f.png',
    productName: 'Untitled',
    authorName: '에드가 플랜스',
    options: {
      subscription: 436500000,
      minInvestment: 10000000,
    },
    value: 0.88,
    extra: '120명이 투자',
    status: '15일남음',
    type: 'unlisted-stocks',
  },
  {
    imageSrc:
      'https://dbm9jhyrx0h6k.cloudfront.net/service/1649755097973-aad1df84-0e06-4fa3-9a4d-c90eee9e3479.png',
    productName: 'Red Bouquet',
    authorName: '힐러리 페시스',
    options: {
      subscription: 436500000,
      minInvestment: 10000000,
    },
    value: 0.24,
    extra: '120명이 투자',
    status: '15일남음',
    type: 'fractional-investments',
  },
  {
    imageSrc:
      'https://dbm9jhyrx0h6k.cloudfront.net/service/1650361182924-a1d1e639-8d44-43f1-8288-ceaa7cc88e1c.png',
    productName: 'Body Drawing 76-2-2010-2(N.Y)',
    authorName: '이건용',
    options: {
      subscription: 436500000,
      minInvestment: 10000000,
    },
    value: 0.41,
    extra: '120명이 투자',
    status: '15일남음',
    type: 'fractional-investments',
  },
];

type ReviewProductInfo = {
  productImageSrc: string;
  productName: string;
  authorName: string;
  return: number;
  returnPyear: number;
  holdingPeriod: number;
  subscription: string;
};

type ReviewData = {
  reviewerName: string;
  reviewDate: string;
  rating: number;
  reviewProductInfo: ReviewProductInfo;
  feedback: string;
};

export const dummyReviewData: ReviewData[] = [
  {
    reviewerName: '김이름',
    reviewDate: '2022.03.04',
    rating: 4,
    reviewProductInfo: {
      productImageSrc: platformProductData[0].imageSrc,
      productName: platformProductData[0].productName,
      authorName: platformProductData[0].authorName,
      return: 10,
      returnPyear: 10,
      holdingPeriod: 278,
      subscription: '10.32억원',
    },
    feedback:
      '[NFT투자후기] 누군가의 실수로 영혼이 새겨진 우주 보석에 고양이 털 뭉치가 엉켜버렸어요!🐈 우리는 이 사랑스러운 털 뭉치를 "샤이니 캣" 이라고 부르기로 했다네요, 민팅 프로젝트에 참여해 받았는데 수익률은 높지 않았지만 제 첫 NFT투자여서 만족스러워요.',
  },
  {
    reviewerName: '김이름',
    reviewDate: '2022.03.04',
    rating: 1,
    reviewProductInfo: {
      productImageSrc: platformProductData[1].imageSrc,
      productName: platformProductData[1].productName,
      authorName: platformProductData[1].authorName,
      return: 10,
      returnPyear: 10,
      holdingPeriod: 278,
      subscription: '10.32억원',
    },
    feedback:
      '[NFT투자후기] 누군가의 실수로 영혼이 새겨진 우주 보석에 고양이 털 뭉치가 엉켜버렸어요!🐈 우리는 이 사랑스러운 털 뭉치를 "샤이니 캣" 이라고 부르기로 했다네요, 민팅 프로젝트에 참여해 받았는데 수익률은 높지 않았지만 제 첫 NFT투자여서 만족스러워요.',
  },
  {
    reviewerName: '김이름',
    reviewDate: '2022.03.04',
    rating: 2,
    reviewProductInfo: {
      productImageSrc: platformProductData[2].imageSrc,
      productName: platformProductData[2].productName,
      authorName: platformProductData[2].authorName,
      return: 10,
      returnPyear: 10,
      holdingPeriod: 278,
      subscription: '10.32억원',
    },
    feedback:
      '[NFT투자후기] 누군가의 실수로 영혼이 새겨진 우주 보석에 고양이 털 뭉치가 엉켜버렸어요!🐈 우리는 이 사랑스러운 털 뭉치를 "샤이니 캣" 이라고 부르기로 했다네요, 민팅 프로젝트에 참여해 받았는데 수익률은 높지 않았지만 제 첫 NFT투자여서 만족스러워요.',
  },
  {
    reviewerName: '김이름',
    reviewDate: '2022.03.04',
    rating: 5,
    reviewProductInfo: {
      productImageSrc: platformProductData[3].imageSrc,
      productName: platformProductData[3].productName,
      authorName: platformProductData[3].authorName,
      return: 10,
      returnPyear: 10,
      holdingPeriod: 278,
      subscription: '10.32억원',
    },
    feedback:
      '[NFT투자후기] 누군가의 실수로 영혼이 새겨진 우주 보석에 고양이 털 뭉치가 엉켜버렸어요!🐈 우리는 이 사랑스러운 털 뭉치를 "샤이니 캣" 이라고 부르기로 했다네요, 민팅 프로젝트에 참여해 받았는데 수익률은 높지 않았지만 제 첫 NFT투자여서 만족스러워요.',
  },
  {
    reviewerName: '김이름',
    reviewDate: '2022.03.04',
    rating: 5,
    reviewProductInfo: {
      productImageSrc: platformProductData[4].imageSrc,
      productName: platformProductData[4].productName,
      authorName: platformProductData[4].authorName,
      return: 10,
      returnPyear: 10,
      holdingPeriod: 278,
      subscription: '10.32억원',
    },
    feedback:
      '[NFT투자후기] 누군가의 실수로 영혼이 새겨진 우주 보석에 고양이 털 뭉치가 엉켜버렸어요!🐈 우리는 이 사랑스러운 털 뭉치를 "샤이니 캣" 이라고 부르기로 했다네요, 민팅 프로젝트에 참여해 받았는데 수익률은 높지 않았지만 제 첫 NFT투자여서 만족스러워요.',
  },
];

export default {
  _AUTO_DATA,
  _DATA,
  dummyDetailData,
  dummyDetailSneakersData,
  platformProductData,
  dummyReviewData,
};
