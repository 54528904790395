import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {CustomText as Text} from '../../Home/CustomText';
import FluctuationDownIcon from '../icon/FluctuationDownIcon';
import FluctuationNoChangeIcon from '../icon/FluctuationNoChangeIcon';
import FluctuationUpIcon from '../icon/FluctuationUpIcon';

export const Fluctuation = ({
  label = '',
  value,
  caption = false,
}: {
  label?: string;
  value: number;
  caption?: boolean;
}) => {
  return (
    <StyledFluctuation>
      <FluctuationLabel caption={caption}>{label}</FluctuationLabel>
      <FluctuationIcon value={value} />
      <FluctuationValue value={value} caption={caption} />
    </StyledFluctuation>
  );
};

const StyledFluctuation = styled.View`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 2px;
`;

const FluctuationLabel = styled(Text)<{caption: boolean}>`
  ${({caption, theme}) => (caption ? theme.fonts.body10 : theme.fonts.body11)};
  margin-right: 4px;
`;

const FluctuationIcon = ({value}: {value: number}) => {
  if (Math.round(value * 100) > 0) {
    return <FluctuationUpIcon />;
  } else if (Math.round(value * 100) < 0) {
    return <FluctuationDownIcon />;
  } else {
    return <FluctuationNoChangeIcon />;
  }
};

const FluctuationValue = ({
  value,
  caption,
}: {
  value: number;
  caption: boolean;
}) => {
  const theme = useTheme();
  let color;
  if (Math.round(value * 100) > 0) {
    color = theme.colors.infoRed;
  } else if (Math.round(value * 100) < 0) {
    color = theme.colors.infoBlue;
  } else {
    color = theme.colors.prapGray3;
  }

  return (
    <StyledFluctuationValue color={color} caption={caption}>
      {value}%
    </StyledFluctuationValue>
  );
};

const StyledFluctuationValue = styled(Text)<{color: string; caption: boolean}>`
  ${({caption, theme}) => (caption ? theme.fonts.body11 : theme.fonts.body12)};
  color: ${({color}) => color};
`;
