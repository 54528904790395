import styled from '@emotion/native';
import {CustomText as Text} from '../../Home/CustomText';

export const LongFigures = ({
  figures,
}: {
  figures: {label: string; value: string}[];
}) => {
  return (
    <StyledLongFigures>
      {figures.map(item => (
        <LongFigure key={item.label}>
          <LongFigureLabel>{item.label}</LongFigureLabel>
          <LongFigureValue>{item.value}</LongFigureValue>
        </LongFigure>
      ))}
    </StyledLongFigures>
  );
};

const StyledLongFigures = styled.View`
  margin-top: 2px;
  margin-bottom: 2px;
  align-items: stretch;
`;

const LongFigure = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const LongFigureLabel = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray2};
`;

const LongFigureValue = styled(Text)`
  ${({theme}) => theme.fonts.body16};
  color: ${({theme}) => theme.colors.prapGray1};
`;
