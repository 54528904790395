/**
 * Generated by orval v6.8.1 🍺
 * Do not edit manually.
 * 프랩 API
 * OpenAPI spec version: 1.0.0
 */
import axios,{
  AxiosRequestConfig,
  AxiosResponse,
  AxiosError
} from 'axios'
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey
} from 'react-query'
import type {
  PaginatedFractionalInvestmentList,
  FractionalInvestmentsListParams,
  FractionalInvestment
} from '../../Models'

type AwaitedInput<T> = PromiseLike<T> | T;

      type Awaited<O> = O extends AwaitedInput<infer T> ? T : never;


export const fractionalInvestmentsList = (
    params?: FractionalInvestmentsListParams, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<PaginatedFractionalInvestmentList>> => {
    return axios.get(
      `/fractional-investments/`,{
        params,
    ...options}
    );
  }


export const getFractionalInvestmentsListQueryKey = (params?: FractionalInvestmentsListParams,) => [`/fractional-investments/`, ...(params ? [params]: [])];

    
export type FractionalInvestmentsListQueryResult = NonNullable<Awaited<ReturnType<typeof fractionalInvestmentsList>>>
export type FractionalInvestmentsListQueryError = AxiosError<unknown>

export const useFractionalInvestmentsList = <TData = Awaited<ReturnType<typeof fractionalInvestmentsList>>, TError = AxiosError<unknown>>(
 params?: FractionalInvestmentsListParams, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fractionalInvestmentsList>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFractionalInvestmentsListQueryKey(params);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fractionalInvestmentsList>>> = ({ signal }) => fractionalInvestmentsList(params, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof fractionalInvestmentsList>>, TError, TData>(queryKey, queryFn, queryOptions)

  return {
    queryKey,
    ...query
  }
}

export const fractionalInvestmentsRetrieve = (
    id: number, options?: AxiosRequestConfig
 ): Promise<AxiosResponse<FractionalInvestment>> => {
    return axios.get(
      `/fractional-investments/${id}/`,options
    );
  }


export const getFractionalInvestmentsRetrieveQueryKey = (id: number,) => [`/fractional-investments/${id}/`];

    
export type FractionalInvestmentsRetrieveQueryResult = NonNullable<Awaited<ReturnType<typeof fractionalInvestmentsRetrieve>>>
export type FractionalInvestmentsRetrieveQueryError = AxiosError<unknown>

export const useFractionalInvestmentsRetrieve = <TData = Awaited<ReturnType<typeof fractionalInvestmentsRetrieve>>, TError = AxiosError<unknown>>(
 id: number, options?: { query?:UseQueryOptions<Awaited<ReturnType<typeof fractionalInvestmentsRetrieve>>, TError, TData>, axios?: AxiosRequestConfig}

  ):  UseQueryResult<TData, TError> & { queryKey: QueryKey } => {

  const {query: queryOptions, axios: axiosOptions} = options ?? {}

  const queryKey = queryOptions?.queryKey ?? getFractionalInvestmentsRetrieveQueryKey(id);

  

  const queryFn: QueryFunction<Awaited<ReturnType<typeof fractionalInvestmentsRetrieve>>> = ({ signal }) => fractionalInvestmentsRetrieve(id, { signal, ...axiosOptions });

  const query = useQuery<Awaited<ReturnType<typeof fractionalInvestmentsRetrieve>>, TError, TData>(queryKey, queryFn, {enabled: !!(id), ...queryOptions})

  return {
    queryKey,
    ...query
  }
}

