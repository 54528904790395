import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {PRAP_API_ENDPOINT, USER_HASH_ID} from './constants';

export interface FilterInfo {
  platform?: string[];
  status?: string[];
  totalAmountLow?: number;
  totalAmountHigh?: number;
  minAmountLow?: number;
  minAmountHigh?: number;
  price?: string[];
  size?: string[];
  stockCategory?: string[];
  brand?: string[];
  searchByTitle?: string;
  sortOption?: string[];
}

const getFilteredInvestments = async ({
  page = 1,
  investmentType,
  filterInfo,
}: {
  page?: number;
  investmentType?: string;
  filterInfo?: FilterInfo;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/${investmentType}`;
  console.log(filterInfo);

  const filteredSearchParams = new URLSearchParams();
  if (filterInfo) {
    if (filterInfo.minAmountLow) {
      if (investmentType === 'fractional-investments') {
        filteredSearchParams.append(
          'minimum_price__gte',
          filterInfo.minAmountLow.toString(),
        );
      } else {
        filteredSearchParams.append(
          'minimum_amount__gte',
          filterInfo.minAmountLow.toString(),
        );
      }
    }
    if (filterInfo.minAmountHigh) {
      if (investmentType === 'fractional-investments') {
        filteredSearchParams.append(
          'minimum_price__lte',
          filterInfo.minAmountHigh.toString(),
        );
      } else {
        filteredSearchParams.append(
          'minimum_amount__lte',
          filterInfo.minAmountHigh.toString(),
        );
      }
    }
    if (filterInfo.platform) {
      filterInfo.platform.map(platformName =>
        filteredSearchParams.append('platform', platformName.toString()),
      );
    }
    if (filterInfo.status) {
      filterInfo.status.map(statusState =>
        filteredSearchParams.append('status', statusState.toString()),
      );
    }
    if (filterInfo.totalAmountLow) {
      filteredSearchParams.append(
        '&total_amount__gte',
        filterInfo.totalAmountLow.toString(),
      );
    }
    if (filterInfo.totalAmountHigh) {
      filteredSearchParams.append(
        'total_amount__lte',
        filterInfo.totalAmountHigh.toString(),
      );
    }
    if (filterInfo.stockCategory) {
      filterInfo.stockCategory.map(category =>
        filteredSearchParams.append('category', category.toString()),
      );
    }
    if (filterInfo.brand) {
      filterInfo.brand.map(brand =>
        filteredSearchParams.append('brand', brand.toString()),
      );
    }
    if (filterInfo.size) {
      filterInfo.size.map(size =>
        filteredSearchParams.append('size', size.toString()),
      );
    }
    if (filterInfo.price) {
      filterInfo.price.map(price =>
        filteredSearchParams.append('price', price.toString()),
      );
    }
    if (filterInfo.searchByTitle) {
      if (
        investmentType === 'sneakers' ||
        investmentType === 'unlisted-stocks'
      ) {
        filteredSearchParams.append(
          'name__icontains',
          filterInfo.searchByTitle.toString(),
        );
      } else {
        filteredSearchParams.append(
          'title__icontains',
          filterInfo.searchByTitle.toString(),
        );
      }
    }
    if (filterInfo.sortOption) {
      filteredSearchParams.append('ordering', filterInfo.sortOption.toString());
    }
  }

  const {data} = await axios.get(
    `${ENDPOINT}/?${filteredSearchParams}&page=${page}`,
    {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    },
  );

  return {result: data.results, next: data.next};
};

export function useFilterInvestments(type: string, filterInfo: FilterInfo) {
  return useInfiniteQuery(
    [`${type}_filtered`, type],
    ({pageParam = 1}) =>
      getFilteredInvestments({
        page: pageParam,
        investmentType: type,
        filterInfo: filterInfo,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response.next === null) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
}
