import { useTheme } from '@emotion/react';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgDeletePlatformIcon = props => {
  const theme = useTheme();
  return (
    <Svg
      width={16}
      height={16}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14.429 3.49a.667.667 0 0 1 .082.939l-7.468 8.89L2.195 8.47a.667.667 0 1 1 .943-.942l3.82 3.819 6.531-7.777a.667.667 0 0 1 .94-.082Z"
        fill={theme.colors.prapGray1}
      />
    </Svg>
  );
}

export default SvgDeletePlatformIcon;
