import styled from '@emotion/native';
import {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {useWindowDimensions} from 'react-native';
import {List} from 'react-native-paper';
import BottomSheetModal, {
  BottomSheetBackdrop,
  BottomSheetScrollView,
} from '../BottomSheet/BottomSheet';
import {
  SNEAKERS_BRAND,
  SNEAKERS_PLATFORM,
  SNEAKERS_PRICE,
  SORT_OPTION,
} from '../Investments/InvestmentAPI/constants';
import {
  FilterArea,
  FilterHeadText,
  FilterHeadView,
  FilterItemForm,
  FilterMenuView,
  FilterText,
  FilterViewPressable,
  FixedArea,
  FundedHeader,
  FundedProductView,
  InitiallizeFilterCTA,
  InitiallizeFilterText,
  ScrollItemText,
  SelectProductArea,
  SelectProductCTA,
  SelectProductText,
  SortItemForm,
  SortMenuView,
  SortText,
} from './style/public.style';

interface FilterDataType {
  status?: string[];
  platform?: string[];
  totalAmountLow?: number | string;
  totalAmountHigh?: number | string;
}

import {korToEng} from '../Investments/InvestmentAPI/translate';

const filterData = {
  platform: [
    // SNEAKERS_PLATFORM.REPLE,
    SNEAKERS_PLATFORM.KREAM,
    SNEAKERS_PLATFORM.SOLD_OUT,
  ],
  price: [
    SNEAKERS_PRICE.LE_100K,
    SNEAKERS_PRICE.LE_300K,
    SNEAKERS_PRICE.LE_500K,
    SNEAKERS_PRICE.ME_500K,
  ],
  brand: [
    SNEAKERS_BRAND.ADIDAS,
    SNEAKERS_BRAND.NIKE,
    SNEAKERS_BRAND.JORDAN,
    SNEAKERS_BRAND.VANS,
    SNEAKERS_BRAND.CONVERSE,
    SNEAKERS_BRAND.NEW_BALANCE,
    SNEAKERS_BRAND.ASICS,
  ],
  size: [
    '225',
    '230',
    '235',
    '240',
    '245',
    '250',
    '255',
    '260',
    '265',
    '270',
    '275',
    '280',
    '285',
    '290',
    '295',
    '300',
    '305',
    '310',
    '315',
    '320',
    '325',
    '330',
    '70',
    '75',
    '80',
    '85',
    '90',
    '95',
    '100',
    '105',
    '110',
    '115',
    '120',
    '125',
    '130',
    '135',
    '140',
    '145',
    '150',
    '155',
    '160',
    '165',
    '170',
    '175',
    '180',
    '185',
    '190',
    '195',
    '200',
    '205',
    '210',
    '215',
    '220',
  ],
  sortOption: [
    SORT_OPTION.PRAP_POPULAR,
    SORT_OPTION.PLATFORM_POPULAR,
    SORT_OPTION.L_SNKRS_PRICE,
    SORT_OPTION.H_SNKRS_PRICE,
  ],
};

export const SneakersFilter = ({
  setFilterOption,
  filterOption = {
    platform: [''],
    price: [''],
    size: [''],
    status: [''],
    brand: [''],

    totalAmountLow: '',
    totalAmountHigh: '',
    minAmountLow: '',
    minAmountHigh: '',

    sortOption: [''],
  },
  bottomSheetRef,
  isSort,
  setSort,
}: {
  bottomSheetRef: RefObject<BottomSheetMethods>;

  isSort: string;
  setSort: Dispatch<SetStateAction<string>>;
}) => {
  let copiedFilterOption = {...filterOption};

  const [copiedFilterOptionState, setCopiedFilterOptionState] =
    useState(copiedFilterOption);

  const [savePlatform, setSavePlatform] = useState([]);
  const [savePrice, setSavePrice] = useState([]);
  const [saveSize, setSaveSize] = useState([]);
  const [saveBrand, setSaveBrand] = useState([]);
  const [saveSortOption, setSaveSortOption] = useState([]);

  const resetOptions = () => {
    setSavePrice([]);
    setSavePlatform([]);
    setSaveSize([]);
    setSaveBrand([]);
    setSaveSortOption([]);
  };

  console.log(copiedFilterOption);

  useEffect(() => {
    // setSavePrice(copiedFilterOption.price);
    // setSavePlatform(copiedFilterOption.platform);
    // setSaveSize(copiedFilterOption.size);
    // setSaveBrand(copiedFilterOption.brand);
    // setSaveSortOption(copiedFilterOption.sortOption);
    setCopiedFilterOptionState(copiedFilterOption);
  }, [filterOption]);

  console.log(copiedFilterOptionState);

  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => setExpanded(!expanded);

  const handlePlatform = (platform: string) => {
    const copiedPlatform = [...savePlatform];
    if (copiedPlatform.includes(korToEng[platform])) {
      copiedPlatform.forEach((el, i) => {
        el === korToEng[platform] ? copiedPlatform.splice(i, 1) : null;
      });
    } else {
      copiedPlatform.push(korToEng[platform]);
    }
    setSavePlatform(copiedPlatform);
  };

  const handlePrice = (price: string) => {
    const copiedPrice = [...savePrice];
    if (copiedPrice.includes(korToEng[price])) {
      copiedPrice.forEach((el, i) => {
        el === korToEng[price] ? copiedPrice.splice(i, 1) : null;
      });
    } else {
      copiedPrice.push(korToEng[price]);
    }
    setSavePrice(copiedPrice);
  };

  const handleBrand = (brand: string) => {
    const copiedBrand = [...saveBrand];
    if (copiedBrand.includes(korToEng[brand])) {
      copiedBrand.forEach((el, i) => {
        el === korToEng[brand] ? copiedBrand.splice(i, 1) : null;
      });
    } else {
      copiedBrand.push(korToEng[brand]);
    }
    setSaveBrand(copiedBrand);
  };

  const handleSize = (size: string) => {
    const copiedSize = [...saveSize];
    if (copiedSize.includes(size)) {
      copiedSize.forEach((el, i) => {
        el === size ? copiedSize.splice(i, 1) : null;
      });
    } else {
      copiedSize.push(size);
    }
    setSaveSize(copiedSize);
  };

  const handleSortOption = (option: string) => {
    const copiedSortOption = [...saveSortOption];

    if (copiedSortOption.includes(korToEng[option])) {
      copiedSortOption.forEach((el, i) => {
        el === korToEng[option] ? copiedSortOption.splice(i, 1) : null;
      });
    } else {
      copiedSortOption.pop();
      copiedSortOption.push(korToEng[option]);
    }

    setSaveSortOption(copiedSortOption);
  };

  const renderFilterOptions = () => {
    return (
      <>
        <FilterArea>
          <FilterHeadView>
            <FilterHeadText>플랫폼</FilterHeadText>
          </FilterHeadView>
          <FilterMenuView>
            {filterData.platform.map((platform, i) => {
              return (
                <FilterItemForm
                  key={`${platform}-platform-${i}`}
                  onPress={() => {
                    handlePlatform(platform);
                  }}
                  isActive={savePlatform.includes(korToEng[platform])}>
                  <ScrollItemText
                    isActive={savePlatform.includes(korToEng[platform])}>
                    {platform}
                  </ScrollItemText>
                </FilterItemForm>
              );
            })}
          </FilterMenuView>
        </FilterArea>

        <FilterArea>
          <FilterHeadView>
            <FilterHeadText>가격</FilterHeadText>
          </FilterHeadView>
          <FilterMenuView>
            {filterData.price.map((price, i) => {
              return (
                <FilterItemForm
                  key={`${price}-price-${i}`}
                  onPress={() => {
                    handlePrice(price);
                  }}
                  isActive={savePrice.includes(korToEng[price])}>
                  <ScrollItemText
                    isActive={savePrice.includes(korToEng[price])}>
                    {price}
                  </ScrollItemText>
                </FilterItemForm>
              );
            })}
          </FilterMenuView>
        </FilterArea>

        <FilterArea>
          <FilterHeadView>
            <FilterHeadText>브랜드</FilterHeadText>
          </FilterHeadView>
          <FilterMenuView>
            {filterData.brand.map((brand, i) => {
              return (
                <FilterItemForm
                  key={`${brand}-brand-${i}`}
                  onPress={() => {
                    handleBrand(brand);
                  }}
                  isActive={saveBrand.includes(korToEng[brand])}>
                  <ScrollItemText
                    isActive={saveBrand.includes(korToEng[brand])}>
                    {brand}
                  </ScrollItemText>
                </FilterItemForm>
              );
            })}
          </FilterMenuView>
        </FilterArea>

        <PressableFilterArea>
          <FilterHeadView>
            <FilterHeadText>사이즈</FilterHeadText>
          </FilterHeadView>
          <DropdownArea>
            <StyledDropDown
              title="사이즈를 선택해주세요."
              onPress={handleExpand}
              expanded={expanded}>
              {expanded
                ? filterData.size.map((size, i) => {
                    return (
                      <StyledDropdownItem
                        key={`${size}-size-${i}`}
                        onPress={() => {
                          handleSize(size);
                        }}
                        title={size}
                        isActive={saveSize.includes(size)}
                      />
                    );
                  })
                : null}
            </StyledDropDown>
          </DropdownArea>
        </PressableFilterArea>
      </>
    );
  };

  const snapPoints = useMemo(() => [1, '95%'], []);

  const handleSheetChanges = (index: number) => {
    if (index <= 0) {
      setSavePrice(copiedFilterOption.price);
      setSavePlatform(copiedFilterOption.platform);
      setSaveSize(copiedFilterOption.size);
      setSaveBrand(copiedFilterOption.brand);
      setSaveSortOption(copiedFilterOption.sortOption);

      bottomSheetRef.current?.close();
    }
  };
  const renderSortOptions = () => {
    return (
      <>
        <FilterArea>
          <SortMenuView>
            {filterData.sortOption.map((option, i) => {
              return (
                <SortItemForm
                  key={`${option}-option-${i}`}
                  onPress={() => handleSortOption(option)}
                  isActive={saveSortOption.includes(korToEng[option])}>
                  <ScrollItemText
                    isActive={saveSortOption.includes(korToEng[option])}>
                    {option}
                  </ScrollItemText>
                </SortItemForm>
              );
            })}
          </SortMenuView>
        </FilterArea>
      </>
    );
  };

  const windowHeight = useWindowDimensions().height;
  const titleBarHeight = 64;
  const tabHeight = 48;
  const bottomSheetHandleHeight = 24;

  return (
    <BottomSheetModal
      bottomSheetRef={bottomSheetRef}
      index={0}
      snapPoints={snapPoints}
      backdropComponent={BottomSheetBackdrop}
      onChange={handleSheetChanges}>
      <BottomSheetScrollView
        stickyHeaderIndices={[0]}
        style={{
          height:
            (windowHeight -
              titleBarHeight -
              tabHeight -
              bottomSheetHandleHeight) *
            0.95,
        }}>
        <FundedHeader>
          <FilterViewPressable onPress={() => setSort('정렬')}>
            <SortText isSort={isSort}>정렬</SortText>
          </FilterViewPressable>
          <FilterViewPressable onPress={() => setSort('필터')}>
            <FilterText isSort={isSort}>필터</FilterText>
          </FilterViewPressable>
        </FundedHeader>
        <FundedProductView>
          {isSort === '필터' ? renderFilterOptions() : renderSortOptions()}
        </FundedProductView>
      </BottomSheetScrollView>
      <FixedArea>
        <SelectProductArea>
          <InitiallizeFilterCTA onPress={() => resetOptions()}>
            <InitiallizeFilterText>선택 초기화</InitiallizeFilterText>
          </InitiallizeFilterCTA>
          <SelectProductCTA
            onPress={() => {
              setFilterOption({
                platform: savePlatform,
                brand: saveBrand,
                price: savePrice,
                size: saveSize,
                sortOption: saveSortOption,
              });

              bottomSheetRef.current?.close();
            }}>
            <SelectProductText>선택하기</SelectProductText>
          </SelectProductCTA>
        </SelectProductArea>
      </FixedArea>
    </BottomSheetModal>
  );
};

const DropdownArea = styled(List.Section)`
  width: 100%;
`;

const StyledDropDown = styled(List.Accordion)`
  width: 100%;
`;

const StyledDropdownItem = styled(List.Item)<{isActive: boolean}>`
  background: ${({isActive, theme}) =>
    isActive ? theme.colors.prapBlue4 : '#fff'};

  margin: 4px 0;
`;

const PressableFilterArea = styled.Pressable`
  margin-bottom: 24px;
`;
