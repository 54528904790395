import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import ClosedSoonScreen from './ClosedSoonScreen';
import HomeScreen from './HomeScreen';
import HomeSearchBar from './HomeSearchBar';
import LatestScreen from './LatestScreen';
import OpenSoonScreen from './OpenSoonScreen';
import PopularScreen from './PopularScreen';

export type HomeTopTabParamList = {
  Home: undefined;
  Popular: undefined;
  Latest: undefined;
  ClosedSoon: undefined;
  OpenSoon: undefined;
};

export const homeTopTabPathConfig: PathConfig<HomeTopTabParamList> = {
  screens: {
    Home: '',
    Popular: 'popular',
    Latest: 'latest',
    ClosedSoon: 'closed-soon',
    OpenSoon: 'open-soon',
  },
};

const HomeTopTab = createMaterialTopTabNavigator<HomeTopTabParamList>();

const StyledHomeTobTabNavigator = styled(HomeTopTab.Navigator)``;
const SearchBarArea = styled(SafeAreaView)`
  padding-top: 8px;
  padding-bottom: 8px;
  background: ${process.env.FLAVOR === 'qv' ? '#151F6D' : '#f9f9f9'};
`;

export default function HomeTopTabScreen({navigation}) {
  const theme = useTheme();

  return (
    <>
      <SearchBarArea edges={['top', 'left', 'right']}>
        <HomeSearchBar />
      </SearchBarArea>

      <StyledHomeTobTabNavigator
        screenOptions={{
          tabBarIndicatorStyle: {
            backgroundColor: theme.colors.prapBlue1,
          },
          tabBarStyle: {
            backgroundColor:
              process.env.FLAVOR === 'qv' ? '#151F6D' : '#f9f9f9',
          },
          tabBarLabelStyle: {
            fontSize: 12,
          },
          tabBarActiveTintColor:
            process.env.FLAVOR === 'qv'
              ? theme.colors.prapBlue1
              : theme.colors.prapGray1,
          tabBarInactiveTintColor:
            process.env.FLAVOR === 'qv'
              ? theme.colors.infoWhite
              : theme.colors.prapGray1,
          swipeEnabled: false,
        }}>
        <HomeTopTab.Screen
          name="Home"
          component={HomeScreen}
          options={{title: '홈'}}
        />
        <HomeTopTab.Screen
          name="Popular"
          component={PopularScreen}
          options={{title: '인기'}}
        />
        <HomeTopTab.Screen
          name="Latest"
          component={LatestScreen}
          options={{title: '신규'}}
        />
        <HomeTopTab.Screen
          name="ClosedSoon"
          component={ClosedSoonScreen}
          options={{title: '마감임박'}}
        />
        <HomeTopTab.Screen
          name="OpenSoon"
          component={OpenSoonScreen}
          options={{title: '오픈예정'}}
        />
      </StyledHomeTobTabNavigator>
    </>
  );
}
