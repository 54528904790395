const PRAP_API_ENDPOINT = `https://api.prap.kr`;
const USER_HASH_ID =
  '0F4B6AD6A32958EE3B76252B956D5D535FA1BE8BE0DD0C5A981C4603402528A1';

const CATEGORY = {
  P2P: 'P2P투자',
  SNEAKERS: '스니커즈리셀',
  FRACTIONAL: '조각투자',
  STARTUP: '스타트업펀딩',
  UNLISTED: '비상장주식',
};

// Status constants
const STATUS = {
  DONE: '모집완료',
  PROCEED: '모집중',
  SOON: '모집예정',
};

// ** Platform constants **

const FRACTIONAL_PLATFORM = {
  SOTWO: '소투',
  ART_N_GUIDE: '아트앤가이드',
  ART_TOGOTHER: '아트투게더',
  TESSA: '테사',
  TREASURER: '트레져러',
};

const P2P_PLATFORM = {
  DAILY_FUND: '데일리펀드',
  V_FUNDING: '브이펀딩',
  PEOPLE_FUND: '피플펀드',
  HELLO_FUNDING: '헬로펀딩',
  EIGHT_PERCENT: '에잇퍼센트',
  HONEST_FUND: '어니스트펀드',
};

const STARTUP_PLATFORM = {
  OH_MY_COMPANY: '오마이컴퍼니',
  OPEN_TRADE: '오픈트레이드',
  WADIZ: '와디즈',
  CROWDY: '크라우디',
  FUNDING_4U: '펀딩포유',
};

const SNEAKERS_PLATFORM = {
  REPLE: '리플',
  SOLD_OUT: '솔드아웃',
  KREAM: '크림',
};

const UNLISTED_PLATFORM = {
  SEOUL_EXCHANGE: '서울거래',
  U_STOCK_PLUS: '증권플러스',
  SAMPAL_COMMUNICATIONS: '38커뮤니케이션',
};

// Category
const STARTUP_STOCK_CATEGORY = {
  BOND: '채권형',
  STOCK: '주식형',
};

// Sneakers Brand
const SNEAKERS_BRAND = {
  NIKE: '나이키',
  ADIDAS: '아디다스',
  JORDAN: '조던',
  VANS: '반스',
  NEW_BALANCE: '뉴발란스',
  CONVERSE: '컨버스',
  ASICS: '아식스',
};

// Sneakers Price

const SNEAKERS_PRICE = {
  LE_100K: '10만원 이하',
  LE_300K: '10만원 - 30만원 이하',
  LE_500K: '30만원 - 50만원 이하',
  ME_500K: '50만원 이상 ',
};

//  ** SORT **
// end_date : 마감날짜
// start_date : 오픈날짜
// total_amount : 총 모집 금액
// minimum_price : 최소 투자 금액
// percent : 모집율
// fee : 수수료
// count : 투자자수
// income : 수익률

const SORT_OPTION = {
  TOTAL_AMOUNT: '모집금액 높은 순',
  L_TOTAL_AMOUNT: '모집금액 낮은 순',
  MIN_PRICE: '최소투자금액 높은 순',
  L_MIN_PRICE: '최소투자금액 낮은 순',
  GOAL_PERCENT: '목표투자금액 높은 순',
  L_GOAL_PERCENT: '목표투자금액 낮은 순',
  FEE: '수수료 높은 순',
  L_FEE: '수수료 낮은 순',
  COUNT: '투자인원 많은 순',
  L_COUNT: '투자인원 적은 순',
  INCOME: '수익률 높은 순',
  L_INCOME: '수익률 낮은 순',
  L_SNKRS_PRICE: '즉시구매가 낮은 순',
  H_SNKRS_PRICE: '즉시구매가 높은 순',
  PRAP_POPULAR: '프랩 인기 순',
  PLATFORM_POPULAR: '플랫폼 인기 순',

  END_DATE: '마감일 가까운 순',
  START_DATE: '최근 오픈 순',
};

export {
  STATUS,
  FRACTIONAL_PLATFORM,
  P2P_PLATFORM,
  SNEAKERS_PLATFORM,
  STARTUP_PLATFORM,
  UNLISTED_PLATFORM,
  STARTUP_STOCK_CATEGORY,
  PRAP_API_ENDPOINT,
  SNEAKERS_BRAND,
  SNEAKERS_PRICE,
  SORT_OPTION,
  USER_HASH_ID,
  CATEGORY,
};
