import axios from 'axios';
import {useMutation, useQueryClient} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

interface FollowPlatform {
  platform?: string;
  deleteId?: number;
}

/// POST SCRAP ITEM

const postFollowPlatform = async (addPlatform: FollowPlatform) => {
  console.log(addPlatform);
  const response = await axios
    .post(`${PRAP_API_ENDPOINT}/platform-following/`, addPlatform, {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    })
    .catch(error => {
      console.log('postFollowPlatform:', 'error.config:', error.config);
    });

  return response;
};

export const useFollowPostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(postFollowPlatform, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries([`follow_platforms`]);
      queryClient.invalidateQueries([`platform-ranking`]);
      queryClient.invalidateQueries([`platform-info`]);
    },
    onError: error => {
      console.error(error);
    },
  });
};

/// DELETE SCRAP ITEM

const deleteFollowPlatform = async (deletePlatform: FollowPlatform) => {
  const response = await axios
    .delete(
      `${PRAP_API_ENDPOINT}/platform-following/${deletePlatform.deleteId}/`,
      {
        headers: {
          Authorization: `Bearer ${USER_HASH_ID}`,
        },
      },
    )
    .catch(error => {
      console.log('deleteFollowPlatform:', 'error:', error);
    });

  return response;
};

export const useFollowDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteFollowPlatform, {
    onMutate: async item => {
      await queryClient.cancelQueries(USER_HASH_ID);
    },
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries([`follow_platforms`]);
      queryClient.invalidateQueries([`platform-ranking`]);
      queryClient.invalidateQueries([`platform-info`]);
    },
    onError: error => {
      console.error(error);
    },
  });
};
