import {useTheme} from '@emotion/react';
import {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import {Slider} from '@miblanchard/react-native-slider';
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import BottomSheetModal, {
  BottomSheetBackdrop,
  BottomSheetScrollView,
} from '../BottomSheet/BottomSheet';
import {
  FRACTIONAL_PLATFORM,
  SORT_OPTION,
  STATUS,
} from '../Investments/InvestmentAPI/constants';
import {SliderContainer} from './SliderContainer';
import {
  FilterArea,
  FilterHeadText,
  FilterHeadView,
  FilterItemForm,
  FilterMenuView,
  FilterText,
  FilterViewPressable,
  FixedArea,
  FundedHeader,
  FundedProductView,
  InitiallizeFilterCTA,
  InitiallizeFilterText,
  ScrollItemText,
  SelectProductArea,
  SelectProductCTA,
  SelectProductText,
  SortItemForm,
  SortMenuView,
  SortText,
} from './style/public.style';

interface FilterDataType {
  status?: string[];
  platform?: string[];
  totalAmountLow?: number | string;
  totalAmountHigh?: number | string;
}

import {useWindowDimensions} from 'react-native';
import {korToEng} from '../Investments/InvestmentAPI/translate';

const filterData = {
  status: [STATUS.DONE, STATUS.PROCEED, STATUS.SOON],
  platform: [
    FRACTIONAL_PLATFORM.SOTWO,
    FRACTIONAL_PLATFORM.ART_N_GUIDE,
    FRACTIONAL_PLATFORM.ART_TOGOTHER,
    FRACTIONAL_PLATFORM.TESSA,
    FRACTIONAL_PLATFORM.TREASURER,
  ],
  totalAmountLow: 0,
  totalAmountHigh: 999999999,
  minAmountLow: 0,
  minAmountHigh: 999999999,
  sortOption: [
    SORT_OPTION.PRAP_POPULAR,
    SORT_OPTION.END_DATE,
    SORT_OPTION.START_DATE,
    SORT_OPTION.TOTAL_AMOUNT,
    SORT_OPTION.L_TOTAL_AMOUNT,
    SORT_OPTION.MIN_PRICE,
    SORT_OPTION.L_MIN_PRICE,
    SORT_OPTION.GOAL_PERCENT,
    SORT_OPTION.L_GOAL_PERCENT,
    SORT_OPTION.FEE,
    SORT_OPTION.L_FEE,
    SORT_OPTION.COUNT,
    SORT_OPTION.L_COUNT,
  ],
};

export const FractionalInvestmentsFilter = ({
  setFilterOption,
  filterOption = {
    status: [''],
    platform: [''],
    minAmountLow: '',
    minAmountHigh: '',
    sortOption: [''],
  },
  isSort,
  setSort,
  bottomSheetRef,
}: {
  isSort: string;
  setSort: Dispatch<SetStateAction<string>>;
  bottomSheetRef: RefObject<BottomSheetMethods>;
}) => {
  const theme = useTheme();

  let copiedFilterOption = {...filterOption};
  const [copiedFilterOptionState, setCopiedFilterOptionState] =
    useState(copiedFilterOption);

  const [saveStatus, setSaveStatus] = useState(['']);
  const [savePlatform, setSavePlatform] = useState(['']);
  const [saveMinLow, setSaveMinLow] = useState<string>('');
  const [saveMinHigh, setSaveMinHigh] = useState<string>('');
  const [saveSortOption, setSaveSortOption] = useState(['']);

  const resetOptions = () => {
    setSaveStatus(['모집중', '모집예정']);
    setSavePlatform(['']);
    setSaveMinLow('');
    setSaveMinHigh('');
    setSaveSortOption(['']);
  };

  const handlestatus = (date: string) => {
    const copiedStatus = [...saveStatus];
    if (copiedStatus.includes(date)) {
      copiedStatus.forEach((el, i) => {
        el === date ? copiedStatus.splice(i, 1) : null;
      });
    } else {
      copiedStatus.push(date);
    }

    setSaveStatus(copiedStatus);
  };

  const handlePlatform = (platform: string) => {
    const copiedPlatform = [...savePlatform];
    if (copiedPlatform.includes(korToEng[platform])) {
      copiedPlatform.forEach((el, i) => {
        el === korToEng[platform] ? copiedPlatform.splice(i, 1) : null;
      });
    } else {
      copiedPlatform.push(korToEng[platform]);
    }
    setSavePlatform(copiedPlatform);
  };

  const handleSortOption = (option: string) => {
    const copiedSortOption = [...saveSortOption];

    if (copiedSortOption.includes(korToEng[option])) {
      copiedSortOption.forEach((el, i) => {
        el === korToEng[option] ? copiedSortOption.splice(i, 1) : null;
      });
    } else {
      copiedSortOption.pop();
      copiedSortOption.push(korToEng[option]);
    }

    setSaveSortOption(copiedSortOption);
  };

  const renderFilterOptions = () => {
    return (
      <>
        <FilterArea>
          <FilterHeadView>
            <FilterHeadText>투자 일정</FilterHeadText>
          </FilterHeadView>
          <FilterMenuView>
            {filterData.status.map((date, i) => {
              return (
                <FilterItemForm
                  key={`${date}-date-${i}`}
                  onPress={() => handlestatus(date)}
                  isActive={saveStatus.includes(date)}>
                  <ScrollItemText isActive={saveStatus.includes(date)}>
                    {date}
                  </ScrollItemText>
                </FilterItemForm>
              );
            })}
          </FilterMenuView>
        </FilterArea>

        <FilterArea>
          <FilterHeadView>
            <FilterHeadText>플랫폼</FilterHeadText>
          </FilterHeadView>
          <FilterMenuView>
            {filterData.platform.map((platform, i) => {
              return (
                <FilterItemForm
                  key={`${platform}-platform-${i}`}
                  onPress={() => {
                    handlePlatform(platform);
                  }}
                  isActive={savePlatform.includes(korToEng[platform])}>
                  <ScrollItemText
                    isActive={savePlatform.includes(korToEng[platform])}>
                    {platform}
                  </ScrollItemText>
                </FilterItemForm>
              );
            })}
          </FilterMenuView>
        </FilterArea>

        <FilterArea>
          <FilterHeadView>
            <FilterHeadText>최소 투자 금액</FilterHeadText>
          </FilterHeadView>
          <SliderContainer
            caption="s"
            sliderValue={[filterData.minAmountLow, filterData.minAmountHigh]}>
            <Slider
              animateTransitions
              minimumTrackTintColor={theme.colors.prapBlue3}
              minimumValue={filterData.minAmountLow}
              maximumTrackTintColor={theme.colors.prapGray5}
              maximumValue={filterData.minAmountHigh}
              step={1000}
              thumbTintColor={theme.colors.prapBlue2}
              onSlidingComplete={value => {
                setSaveMinLow(value[0]);
                setSaveMinHigh(value[1]);
              }}
            />
          </SliderContainer>
        </FilterArea>
      </>
    );
  };

  const renderSortOptions = () => {
    return (
      <>
        <FilterArea>
          <SortMenuView>
            {filterData.sortOption.map((option, i) => {
              return (
                <SortItemForm
                  key={`${option}-option-${i}`}
                  onPress={() => handleSortOption(option)}
                  isActive={saveSortOption.includes(korToEng[option])}>
                  <ScrollItemText
                    isActive={saveSortOption.includes(korToEng[option])}>
                    {option}
                  </ScrollItemText>
                </SortItemForm>
              );
            })}
          </SortMenuView>
        </FilterArea>
      </>
    );
  };

  const snapPoints = useMemo(() => [1, '95%'], []);

  const handleSheetChanges = (index: number) => {
    setSaveStatus(copiedFilterOption.status);
    setSavePlatform(copiedFilterOption.platform);
    setSaveMinLow(copiedFilterOption.minAmountLow);
    setSaveMinHigh(copiedFilterOption.minAmountHigh);
    setSaveSortOption(copiedFilterOption.sortOption);

    if (index <= 0) {
      bottomSheetRef.current?.close();
    }
  };

  const windowHeight = useWindowDimensions().height;
  const titleBarHeight = 64;
  const tabHeight = 48;
  const bottomSheetHandleHeight = 24;

  return (
    <BottomSheetModal
      bottomSheetRef={bottomSheetRef}
      index={0}
      snapPoints={snapPoints}
      backdropComponent={BottomSheetBackdrop}
      onChange={handleSheetChanges}>
      <BottomSheetScrollView
        stickyHeaderIndices={[0]}
        style={{
          height:
            (windowHeight -
              titleBarHeight -
              tabHeight -
              bottomSheetHandleHeight) *
            0.95,
        }}>
        <FundedHeader>
          <FilterViewPressable onPress={() => setSort('정렬')}>
            <SortText isSort={isSort}>정렬</SortText>
          </FilterViewPressable>
          <FilterViewPressable onPress={() => setSort('필터')}>
            <FilterText isSort={isSort}>필터</FilterText>
          </FilterViewPressable>
        </FundedHeader>
        <FundedProductView>
          {isSort === '필터' ? renderFilterOptions() : renderSortOptions()}
        </FundedProductView>
      </BottomSheetScrollView>
      <FixedArea>
        <SelectProductArea>
          <InitiallizeFilterCTA onPress={() => resetOptions()}>
            <InitiallizeFilterText>선택 초기화</InitiallizeFilterText>
          </InitiallizeFilterCTA>
          <SelectProductCTA
            onPress={() => {
              setFilterOption({
                status: saveStatus,
                platform: savePlatform,
                minAmountLow: saveMinLow,
                minAmountHigh: saveMinHigh,
                sortOption: saveSortOption,
              });
              bottomSheetRef.current?.close();
            }}>
            <SelectProductText>선택하기</SelectProductText>
          </SelectProductCTA>
        </SelectProductArea>
      </FixedArea>
    </BottomSheetModal>
  );
};
