import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {PRAP_API_ENDPOINT} from '../../Investments/InvestmentAPI/constants';

const getUserClipItem = async ({
  category,
  page = 1,
  userId,
}: {
  category: string;
  page?: number;
  userId?: string;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/scrap/?category=${category}&page=${page}`;

  const {data} = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${userId}`,
    },
  });

  return {result: data, next: null};
};

export function useUserClipItem(category: string, userId: string) {
  return useInfiniteQuery(
    [`${userId}_clip`, category],
    ({pageParam = 1}) =>
      getUserClipItem({
        category,
        page: pageParam,
        userId: userId,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response.next) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
}
