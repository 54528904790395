import styled from '@emotion/native';
import {MaterialTopTabScreenProps} from '@react-navigation/material-top-tabs';
import React from 'react';
import {FlatList} from 'react-native';
import {USER_HASH_ID} from '../Investments/InvestmentAPI/constants';
import {
  ColumnSkeletonView,
  RowSkeletonView,
  SmallInvestmentSkeleton,
} from '../Investments/style/Skeleton';
import {useFollowPlatform} from './api/useFollowPlatform';
import {FollowingTabParamList} from './FollowingTabScreen';
import {PlatformsForm} from './PlatformsForm';
import {PlatformListSkeleton} from './style/Skeleton';

export default function FollowingScreen({
  route,
}: MaterialTopTabScreenProps<FollowingTabParamList>) {
  const followPlatformData = useFollowPlatform(USER_HASH_ID);
  console.log(followPlatformData);

  if (followPlatformData.isLoading) {
    return (
      <>
        <ColumnSkeletonView>
          <PlatformListSkeleton />
          <RowSkeletonView>
            <SmallInvestmentSkeleton />
            <SmallInvestmentSkeleton />
            <SmallInvestmentSkeleton />
          </RowSkeletonView>
          <PlatformListSkeleton />
          <RowSkeletonView>
            <SmallInvestmentSkeleton />
            <SmallInvestmentSkeleton />
            <SmallInvestmentSkeleton />
          </RowSkeletonView>
          <PlatformListSkeleton />
          <RowSkeletonView>
            <SmallInvestmentSkeleton />
            <SmallInvestmentSkeleton />
            <SmallInvestmentSkeleton />
          </RowSkeletonView>
        </ColumnSkeletonView>
      </>
    );
  }

  return (
    <FlatList
      ListHeaderComponent={() => <></>}
      data={followPlatformData.data?.pages.map(page => page.result).flat()}
      renderItem={({item: platform, index: rank}) => (
        <PlatformsForm platform={platform} rank={rank} type="following" />
      )}
      ItemSeparatorComponent={ItemSeparator}
    />
  );
}

const ItemSeparator = styled.View`
  width: 100%;
  height: 1px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;
