import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {PRAP_API_ENDPOINT} from '../../Investments/InvestmentAPI/constants';

const getNotification = async ({
  page = 1,
  userId,
}: {
  page?: number;
  userId?: string;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/notifications/`;
  const {data} = await axios.get(ENDPOINT, {
    headers: {
      Authorization: `Bearer ${userId}`,
    },
  });

  return data;
};

export const useUserNotification = (userId: string) => {
  return useInfiniteQuery(
    [`${userId}_notice`],
    ({pageParam = 1}) =>
      getNotification({
        page: pageParam,
        userId: userId,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response?.next) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
};
