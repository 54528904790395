import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import React from 'react';
import CategoryTabBar from '../Clippings/CategoryTabBar';
import Ranking from './Ranking';

export type RankingTabParamList = {
  FractionalInvestmentRanking: undefined;
  SneakerRanking: undefined;
  StartupRanking: undefined;
  P2PInvestmentRanking: undefined;
  UnlistedStockRanking: undefined;
};

export const rankingTabPathConfig: PathConfig<RankingTabParamList> = {
  path: 'platform-ranking',
  screens: {
    FractionalInvestmentRanking: 'fractional-investments',
    SneakerRanking: 'sneakers',
    StartupRanking: 'startups',
    P2PInvestmentRanking: 'p2p-investments',
    UnlistedStockRanking: 'unlisted-stocks',
  },
};

const RankingTab = createMaterialTopTabNavigator<RankingTabParamList>();

const StyledRankingTabNavigator = styled(RankingTab.Navigator)``;

export default function RankingTabScreen() {
  const theme = useTheme();

  return (
    <>
      <StyledRankingTabNavigator
        screenOptions={{
          swipeEnabled: false,
          tabBarIndicatorStyle: {
            backgroundColor: theme.colors.prapBlue1,
          },
          tabBarLabelStyle: {
            fontSize: 13,
          },
          tabBarContentContainerStyle: {
            paddingTop: 8,
            paddingBottom: 8,
          },
          tabBarScrollEnabled: true,
          tabBarItemStyle: {
            width: 96,
            padding: 0,
            marginRight: 8,

            borderRadius: 40,
            backgroundColor: `${theme.colors.prapBlue4}`,
          },
          tabBarPressColor: `${theme.colors.prapBlue1}`,

          tabBarActiveTintColor: `${theme.colors.prapBlue1}`,
          tabBarInactiveTintColor: `${theme.colors.prapGray2}`,
        }}
        tabBar={CategoryTabBar}>
        <RankingTab.Screen
          name="FractionalInvestmentRanking"
          component={FractionalInvestmentRankingScreen}
          options={{title: '조각투자'}}
        />
        <RankingTab.Screen
          name="SneakerRanking"
          component={SneakerRankingScreen}
          options={{title: '스니커즈리셀'}}
        />
        <RankingTab.Screen
          name="StartupRanking"
          component={StartupRankingScreen}
          options={{title: '스타트업펀딩'}}
        />
        <RankingTab.Screen
          name="P2PInvestmentRanking"
          component={P2PInvestmentRankingScreen}
          options={{title: 'P2P투자'}}
        />

        <RankingTab.Screen
          name="UnlistedStockRanking"
          component={UnlistedStockRankingScreen}
          options={{title: '비상장주식'}}
        />
      </StyledRankingTabNavigator>
    </>
  );
}

function FractionalInvestmentRankingScreen() {
  return <Ranking category="fractional-investments" />;
}

function SneakerRankingScreen() {
  return <Ranking category="sneakers" />;
}

function StartupRankingScreen() {
  return <Ranking category="startups" />;
}

function P2PInvestmentRankingScreen() {
  return <Ranking category="p2p-investments" />;
}

function UnlistedStockRankingScreen() {
  return <Ranking category="unlisted-stocks" />;
}
