import styled from '@emotion/native';
import {CustomText as Text} from '../../Home/CustomText';

export const ExtraFigures = ({
  figures,
}: {
  figures: {label: string; value: string}[];
}) => {
  return (
    <StyledExtraFigures>
      {figures.map(item => (
        <ExtraFigure key={item.label}>
          <ExtraFigureLabel>{item.label}</ExtraFigureLabel>
          <ExtraFigureValue>{item.value}</ExtraFigureValue>
        </ExtraFigure>
      ))}
    </StyledExtraFigures>
  );
};

const StyledExtraFigures = styled.View`
  margin-top: 2px;
  margin-bottom: 2px;
  align-items: stretch;
  border-top-width: 1px;
  border-top-color: ${({theme}) => theme.colors.prapGray5};
  padding-top: 6px;
`;

export const ExtraFigure = styled.View`
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-top: 2px;
  padding-bottom: 2px;
`;

const ExtraFigureLabel = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray2};
`;

const ExtraFigureValue = styled(Text)`
  ${({theme}) => theme.fonts.body14};
  color: ${({theme}) => theme.colors.prapGray1};
`;
