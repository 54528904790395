import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import {MoreStackParamList} from '../More/MoreStackScreen';

const SettingsScreen = ({}: NativeStackScreenProps<
  MoreStackParamList,
  'More'
>) => {
  return (
    <></>
    // <SimpleList
    //   sections={[
    //     {
    //       data: [
    //         {label: '알림 및 소리'},
    //         {
    //           label: '버전 정보',
    //           description: '최신 버전: 1.0.1',
    //           value: '1.0.0',
    //         },
    //       ],
    //     },
    //     {
    //       data: [{label: '로그아웃'}, {label: '탈퇴하기'}],
    //     },
    //   ]}
    // />
  );
};

export default SettingsScreen;
