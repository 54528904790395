import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useEffect, useState} from 'react';
import {Image} from 'react-native';
import {ScrollView} from 'react-native-gesture-handler';
import {StackParamList} from '../App';

const PromotionScreen = ({
  navigation,
  route,
}: NativeStackScreenProps<StackParamList, 'Promotion'>) => {
  const [aspectRatio, setAspectRatio] = useState<number>();

  useEffect(() => {
    navigation.setOptions({title: '이벤트 제목'});
  }, [navigation]);

  useEffect(() => {
    Image.getSize('https://placeimg.com/180/1000/any', (width, height) =>
      setAspectRatio(width / height),
    );
  }, []);

  route.params.id;

  return (
    <ScrollView>
      <StyledImage
        source={{uri: 'https://placeimg.com/180/1000/any'}}
        aspectRatio={aspectRatio}
      />
    </ScrollView>
  );
};

export default PromotionScreen;

export interface PromotionState {
  id: string;
  title: string;
  remaining: string;
  listImage: string;
  image: string;
}

const StyledImage = styled.Image<{aspectRatio?: number}>`
  width: 100%;
  aspect-ratio: ${({aspectRatio}) => aspectRatio};
  resize-mode: contain;
`;
