import * as React from 'react';
import Svg, { Path } from 'react-native-svg';


const SvgUnScrapIcon = props => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="m19 22-7-4-7 4V3a1.09 1.09 0 0 1 1.17-1h11.66A1.09 1.09 0 0 1 19 3v19Z"
      fill={process.env.FLAVOR === 'namuh' ? '#84C13D' : process.env.FLAVOR === 'qv' ? '#151F6D' : '#8295F6'}
    />
  </Svg>
);

export default SvgUnScrapIcon;
