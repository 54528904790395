import styled from '@emotion/native';
import {Animated} from 'react-native';
import {CustomText as Text} from '../../Home/CustomText';

const CTACombo = styled.View`
  flex-direction: row;
  padding: 21px 0;
  z-index: 999;
  background: #fff;
`;

const StyledCategoryView = styled.View`
  flex: 1;
`;

const CategoryHeader = styled.View`
  padding: 0 15px;

  background: #fff;
`;

const FilterArea = styled.View`
  flex-direction: row;
  flex: 0.5;
  justify-content: flex-end;
`;

const FilterCTA = styled.Pressable`
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-left: 8px;
`;

const FilterText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray2};
  margin-right: 4px;
`;
const SearchArea = styled.View`
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

const SearchCTA = styled.Pressable`
  flex-direction: row;

  align-items: center;
`;

const FilterContainer = styled(Animated.View)`
  flex-direction: row;

  align-items: center;
  width: 100%;
  padding-bottom: 21px;
  z-index: 99;
`;

const RemoveIconArea = styled.Pressable`
  align-items: center;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
`;

export {
  CTACombo,
  StyledCategoryView,
  CategoryHeader,
  FilterArea,
  FilterCTA,
  FilterText,
  SearchArea,
  SearchCTA,
  FilterContainer,
  RemoveIconArea,
};
