import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgMoreRecentlyIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8 13.333A5.333 5.333 0 1 0 8 2.667a5.333 5.333 0 0 0 0 10.666Zm0 1.334A6.667 6.667 0 1 0 8 1.333a6.667 6.667 0 0 0 0 13.334Z"
      fill="#666"
    />
    <Path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.56 5.739a.667.667 0 0 0-.936-.113L7.7 7.92 5.947 6.672a.667.667 0 1 0-.773 1.086l2.565 1.828 3.708-2.911a.667.667 0 0 0 .113-.936Z"
      fill="#666"
    />
  </Svg>
);

export default SvgMoreRecentlyIcon;
