import styled from '@emotion/native';
import ContentLoader, {Rect} from 'react-content-loader/native';
import {Dimensions} from 'react-native';

const windowWidth = Dimensions.get('window').width;

export const SmallInvestmentSkeleton = () => {
  return (
    <SmallSkeletonWrap>
      <ContentLoader
        speed={2}
        width={140}
        height={230}
        viewBox="0 0 140 230"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <Rect x="0" y="0" rx="2" ry="2" width="140" height="140" />
        <Rect x="0" y="150" rx="2" ry="2" width="140" height="10" />
        <Rect x="0" y="170" rx="2" ry="2" width="140" height="10" />
      </ContentLoader>
    </SmallSkeletonWrap>
  );
};

export const MediumInvestmentSkeleton = () => {
  return (
    <MediumSkeletonWrap>
      <ContentLoader
        speed={2}
        width={130}
        height={145}
        viewBox={`0 0 ${130} 145`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <Rect x="0" y="0" rx="2" ry="2" width="115" height="115" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={windowWidth - 130}
        height={145}
        viewBox={`0 0 ${windowWidth - 130} 145`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <Rect x="0" y="0" rx="2" ry="2" width={40} height="15" />
        <Rect x="0" y="30" rx="2" ry="2" width={170} height="15" />
        <Rect
          x="0"
          y="60"
          rx="2"
          ry="2"
          width={windowWidth - 130}
          height="50"
        />
        <Rect
          x="0"
          y="120"
          rx="2"
          ry="2"
          width={windowWidth - 130}
          height="15"
        />
      </ContentLoader>
    </MediumSkeletonWrap>
  );
};

export const CarouselSkeleton = () => {
  return (
    <CarouselSkeletonWrap>
      <ContentLoader
        speed={2}
        width={windowWidth}
        height={250}
        viewBox={`0 0 ${windowWidth} 250`}
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb">
        <Rect x="0" y="0" rx="2" ry="2" width={windowWidth} height="250" />
      </ContentLoader>
    </CarouselSkeletonWrap>
  );
};

export const RowSkeletonView = styled.View`
  flex-direction: row;
  padding: 0 15px;
`;

export const ColumnSkeletonView = styled.View`
  padding: 0 15px;
`;

const SmallSkeletonWrap = styled.View`
  margin-right: 16px;
`;
const MediumSkeletonWrap = styled.View`
  flex-direction: row;
`;
const CarouselSkeletonWrap = styled.View`
  margin-bottom: 8px;
`;
