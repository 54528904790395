import React, {ReactElement, useState} from 'react';
import {FlatList} from 'react-native';
import MediumInvestment from '../Investments/MediumInvestment';
import {useHomeSegmentation} from './api/useHomeSegmentation';

export const InvestmentSegment = ({
  segment,
  ListHeaderComponent,
}: {
  segment: string;
  ListHeaderComponent?: ReactElement;
}) => {
  const [toggle, setToggle] = useState(false);
  const onToggleSnackbar = () => setToggle(!toggle);
  const onDismissSnackbar = () => setToggle(false);
  const homeSegmentData = useHomeSegmentation(segment);

  if (homeSegmentData.isLoading) {
    return <></>;
  }
  if (homeSegmentData.isError) {
    return <></>;
  }

  return (
    <>
      <FlatList
        ListHeaderComponent={ListHeaderComponent}
        data={homeSegmentData?.data?.pages.map(page => page.result).flat()}
        renderItem={({item: investment, index: rank}) => (
          <MediumInvestment
            investment={investment}
            rank={rank}
            snackbarVisible={onToggleSnackbar}
          />
        )}
        ListEmptyComponent={<></>}
        onEndReached={() =>
          homeSegmentData.hasNextPage ? homeSegmentData.fetchNextPage() : null
        }
        onEndReachedThreshold={0.5}
      />
    </>
  );
};
