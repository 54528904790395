import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgMoreClippingIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M11.334 2.667v9.7L8.667 10.84 8 10.467l-.667.373-2.666 1.527v-9.7h6.667Zm.553-1.334H4.113a.727.727 0 0 0-.78.667v12.667L8 12l4.667 2.667V2a.727.727 0 0 0-.78-.667Z"
      fill="#666"
    />
  </Svg>
);

export default SvgMoreClippingIcon;
