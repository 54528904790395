import * as React from 'react';
import Svg, {G, Circle, Defs, ClipPath, Path} from 'react-native-svg';

const SvgFocusedMoreIcon = props => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <G clipPath="url(#FocusedMoreIcon_svg__a)" fill="#27332F">
      <Circle cx={3} cy={12} r={3} />
      <Circle cx={12} cy={12} r={3} />
      <Circle cx={21} cy={12} r={3} />
    </G>
    <Defs>
      <ClipPath id="FocusedMoreIcon_svg__a">
        <Path fill="#fff" transform="rotate(90 12 12)" d="M0 0h24v24H0z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgFocusedMoreIcon;
