import * as React from 'react';
import Svg, {
  Path,
  G,
  Circle,
  Defs,
  LinearGradient,
  Stop,
} from 'react-native-svg';
/* SVGR has dropped some elements not supported by react-native-svg: filter */

const SvgEmptyComp = props => (
  <Svg
    width={204}
    height={180}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="m12 121.564 85.954-24.54L192 121.565l-94.046 36.525L12 121.564Z"
      fill="#B3B4B7"
    />
    <Path
      d="m12 121.564 85.954-24.54L192 121.565l-94.046 36.525L12 121.564Z"
      fill="url(#EmptyComp_svg__a)"
    />
    <G filter="url(#EmptyComp_svg__b)">
      <Path
        d="m12 118.182 85.954-24.538L192 118.182l-94.046 36.526L12 118.182Z"
        fill="#B3B4B7"
      />
      <Path
        d="m12 118.182 85.954-24.538L192 118.182l-94.046 36.526L12 118.182Z"
        fill="url(#EmptyComp_svg__c)"
      />
    </G>
    <G filter="url(#EmptyComp_svg__d)">
      <Path
        d="m12 114.885 85.954-24.539L192 114.885l-94.046 36.526L12 114.885Z"
        fill="#B3B4B7"
      />
      <Path
        d="m12 114.885 85.954-24.539L192 114.885l-94.046 36.526L12 114.885Z"
        fill="url(#EmptyComp_svg__e)"
      />
    </G>
    <G filter="url(#EmptyComp_svg__f)">
      <Path
        d="m12 111.678 85.954-24.539L192 111.678l-94.046 36.526L12 111.678Z"
        fill="#B3B4B7"
      />
      <Path
        d="m12 111.678 85.954-24.539L192 111.678l-94.046 36.526L12 111.678Z"
        fill="url(#EmptyComp_svg__g)"
      />
    </G>
    <G filter="url(#EmptyComp_svg__h)">
      <Path
        d="m12 108.297 85.954-24.54L192 108.298l-94.046 36.525L12 108.297Z"
        fill="#B3B4B7"
      />
      <Path
        d="m12 108.297 85.954-24.54L192 108.298l-94.046 36.525L12 108.297Z"
        fill="url(#EmptyComp_svg__i)"
      />
    </G>
    <G filter="url(#EmptyComp_svg__j)">
      <Path
        d="M12 104.999 97.954 80.46 192 105l-94.046 36.526L12 104.999Z"
        fill="#B3B4B7"
      />
      <Path
        d="M12 104.999 97.954 80.46 192 105l-94.046 36.526L12 104.999Z"
        fill="url(#EmptyComp_svg__k)"
      />
    </G>
    <G filter="url(#EmptyComp_svg__l)">
      <Path
        d="m12 103.426 85.954-24.539L192 103.426l-53.506 20.781a106.974 106.974 0 0 1-80.567-1.265L12 103.426Z"
        fill="#F3F3F3"
      />
    </G>
    <G filter="url(#EmptyComp_svg__m)">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M70.936 15c-8.284 0-15 6.716-15 15v7.996c0 8.284 6.716 15 15 15h2.85l4.743 8.215 4.743-8.215h51.114c8.284 0 15-6.716 15-15V30c0-8.284-6.716-15-15-15h-63.45Z"
        fill="#FEFEFE"
      />
    </G>
    <Circle cx={87.77} cy={33.485} r={3.081} fill="#77787A" />
    <Circle cx={103.174} cy={33.485} r={3.081} fill="#77787A" />
    <Circle cx={118.579} cy={33.485} r={3.081} fill="#77787A" />
    <Defs>
      <LinearGradient
        id="EmptyComp_svg__a"
        x1={61.723}
        y1={200.691}
        x2={130.852}
        y2={150.606}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="EmptyComp_svg__c"
        x1={61.723}
        y1={197.31}
        x2={130.852}
        y2={147.224}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="EmptyComp_svg__e"
        x1={61.723}
        y1={194.013}
        x2={130.852}
        y2={143.927}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="EmptyComp_svg__g"
        x1={61.723}
        y1={190.806}
        x2={130.852}
        y2={140.72}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="EmptyComp_svg__i"
        x1={61.723}
        y1={187.424}
        x2={130.852}
        y2={137.339}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
      <LinearGradient
        id="EmptyComp_svg__k"
        x1={61.723}
        y1={184.127}
        x2={130.852}
        y2={134.041}
        gradientUnits="userSpaceOnUse">
        <Stop stopColor="#fff" />
        <Stop offset={1} stopColor="#fff" stopOpacity={0} />
      </LinearGradient>
    </Defs>
  </Svg>
);

export default SvgEmptyComp;
