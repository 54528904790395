import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgPieceIcon = props => (
  <Svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M35.095 22.825a5.238 5.238 0 0 1 5.238-5.238v-7.071a2.54 2.54 0 0 0-2.527-2.528H11.432a2.54 2.54 0 0 0-2.527 2.528v26.373a2.54 2.54 0 0 0 2.527 2.528h7.949a5.238 5.238 0 1 1 10.476 0h7.949a2.54 2.54 0 0 0 2.527-2.528v-8.826a5.238 5.238 0 0 1-5.238-5.238Z"
      fill="#C4AEE2"
    />
    <Path d="M29.858 7.988a5.238 5.238 0 1 1-10.477 0h10.477Z" fill="#AE89E8" />
    <Path d="M19.381 7.988a5.238 5.238 0 0 1 10.476 0H19.381Z" fill="#F5D1D2" />
    <Path d="M8.905 17.587a5.239 5.239 0 0 1 0 10.476V17.587Z" fill="#AE89E8" />
    <Path d="M8.905 17.587a5.239 5.239 0 0 0 0 10.476V17.587Z" fill="#F5D1D2" />
  </Svg>
);

export default SvgPieceIcon;
