import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import React from 'react';
import CategoryTabBar from './CategoryTabBar';
import Clippings from './Clippings';

export type InvestmentClippingsTabParamList = {
  FractionalInvestmentClippings: undefined;
  SneakerClippings: undefined;
  StartupClippings: undefined;
  P2PInvestmentClippings: undefined;
  UnlistedStockClippings: undefined;
};

export const investmentClippingsTabPathConfig: PathConfig<InvestmentClippingsTabParamList> =
  {
    path: 'investment-clippings',
    screens: {
      FractionalInvestmentClippings: 'fractional-investments',
      SneakerClippings: 'sneakers',
      StartupClippings: 'startups',
      P2PInvestmentClippings: 'p2p-investments',
      UnlistedStockClippings: 'unlisted-stocks',
    },
  };

const investmentClippingsTab =
  createMaterialTopTabNavigator<InvestmentClippingsTabParamList>();

const StyledCategoryTabNavigator = styled(investmentClippingsTab.Navigator)``;

export default function InvestmentClippingsTabScreen({}) {
  const theme = useTheme();
  return (
    <>
      <StyledCategoryTabNavigator
        screenOptions={{
          swipeEnabled: false,
          tabBarIndicatorStyle: {
            backgroundColor: theme.colors.prapBlue1,
          },
          tabBarLabelStyle: {
            fontSize: 13,
          },
          tabBarContentContainerStyle: {
            paddingTop: 8,
            paddingBottom: 8,
          },
          tabBarScrollEnabled: true,
          tabBarItemStyle: {
            width: 96,
            padding: 0,
            marginRight: 8,

            borderRadius: 40,
            backgroundColor: `${theme.colors.prapBlue4}`,
          },
          tabBarPressColor: `${theme.colors.prapBlue1}`,

          tabBarActiveTintColor: `${theme.colors.prapBlue1}`,
          tabBarInactiveTintColor: `${theme.colors.prapGray2}`,
        }}
        tabBar={CategoryTabBar}>
        <investmentClippingsTab.Screen
          name="FractionalInvestmentClippings"
          component={FractionalInvestmentClippingsScreen}
          options={{title: '조각투자'}}
        />
        <investmentClippingsTab.Screen
          name="SneakerClippings"
          component={SneakerClippingsScreen}
          options={{title: '스니커즈리셀'}}
        />
        <investmentClippingsTab.Screen
          name="StartupClippings"
          component={StartupClippingsScreen}
          options={{title: '스타트업펀딩'}}
        />
        <investmentClippingsTab.Screen
          name="P2PInvestmentClippings"
          component={P2PInvestmentClippingsScreen}
          options={{title: 'P2P투자'}}
        />

        <investmentClippingsTab.Screen
          name="UnlistedStockClippings"
          component={UnlistedStockClippingsScreen}
          options={{title: '비상장주식'}}
        />
      </StyledCategoryTabNavigator>
    </>
  );
}

function FractionalInvestmentClippingsScreen() {
  return <Clippings category="fractional-investments" />;
}

function SneakerClippingsScreen() {
  return <Clippings category="sneakers" />;
}

function StartupClippingsScreen() {
  return <Clippings category="startups" />;
}

function P2PInvestmentClippingsScreen() {
  return <Clippings category="p2p-investments" />;
}

function UnlistedStockClippingsScreen() {
  return <Clippings category="unlisted-stocks" />;
}
