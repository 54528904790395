import styled from '@emotion/native';
import React from 'react';
import {FlatList} from 'react-native';
import {CustomText as Text} from '../Home/CustomText';
import SvgEmptyComp from './EmptyComp';

import {BidOrAsk} from './style/BidOrAsk';
import {EmptyCompArea, EmptyCompText} from './style/public.style';

export function Asks({
  category,
  platform,
  prices,
}: {
  category: string;
  platform: string;
  prices: any;
}) {
  return (
    <>
      <TabInfoContainer>
        <TabInfoTextWrap>
          <TabInfo>
            {category === 'unlisted-stocks'
              ? '주식을 구매하고 싶은 사람이 제시한 가격'
              : '스니커즈를 구매하고 싶은 사람이 제시한 가격'}
          </TabInfo>
        </TabInfoTextWrap>
      </TabInfoContainer>
      <FlatList
        data={prices}
        renderItem={({item}) => {
          console.log(item);
          return (
            <BidOrAsk price={item} category={category} platform={platform} />
          );
        }}
        ListEmptyComponent={
          <EmptyCompArea>
            <SvgEmptyComp />
            <EmptyCompText>등록된 상품이 없어요.</EmptyCompText>
          </EmptyCompArea>
        }
      />
    </>
  );
}

export function Bids({
  category: category,
  platform: platform,
  prices: prices,
}: {
  category: string;
  platform: string;
  prices: any;
}) {
  return (
    <>
      <TabInfoContainer>
        <TabInfoTextWrap>
          <TabInfo>
            {category === 'unlisted-stocks'
              ? '주식을 판매하고 싶은 사람이 제시한 가격'
              : '스니커즈를 판매하고 싶은 사람이 제시한 가격'}
          </TabInfo>
        </TabInfoTextWrap>
      </TabInfoContainer>
      <FlatList
        data={prices}
        renderItem={({item}) => {
          console.log(item);
          return (
            <BidOrAsk price={item} category={category} platform={platform} />
          );
        }}
        ListEmptyComponent={
          <EmptyCompArea>
            <SvgEmptyComp />
            <EmptyCompText>등록된 상품이 없어요.</EmptyCompText>
          </EmptyCompArea>
        }
      />
    </>
  );
}
const TabInfoContainer = styled.View``;
const TabInfoTextWrap = styled.View`
  background: ${({theme}) => theme.colors.prapGray6};
  padding: 12px;
  margin: 0 16px;
  border-radius: 8px;
  align-items: center;
`;
const TabInfo = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray2};
  font-size: ${({theme}) => theme.fonts.body12};
`;
