import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React from 'react';
import {MoreStackParamList} from '../More/MoreStackScreen';

const PrivacyAndTermsScreen = ({}: NativeStackScreenProps<
  MoreStackParamList,
  'More'
>) => {
  return (
    <></>
    // <SimpleList
    //   sections={[
    //     {
    //       data: [{label: '이용약관'}, {label: '개인정보처리방침'}],
    //     },
    //   ]}
    // />
  );
};

export default PrivacyAndTermsScreen;
