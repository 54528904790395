import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import React from 'react';
import {UseInfiniteQueryResult} from 'react-query';
import CategoryTabBar from '../Clippings/CategoryTabBar';
import {useSearchAll} from '../Home/api/useSearchAll';
import SearchScreen from '../Search/SearchScreen';

export type SearchTabParamList = {
  P2PInvestments: {
    category: string;
    data: UseInfiniteQueryResult;
  };
  FractionalInvestments: {
    category: string;
    data: UseInfiniteQueryResult;
  };
  Startups: {
    category: string;
    data: UseInfiniteQueryResult;
  };
  UnlistedStocks: {
    category: string;
    data: UseInfiniteQueryResult;
  };
  Sneakers: {
    category: string;
    data: UseInfiniteQueryResult;
  };
};

export const SearchTabPathConfig: PathConfig<SearchTabParamList> = {
  path: 'Search',
  screens: {
    P2PInvestments: 'p2p-investments',
    FractionalInvestments: 'fractional-investments',
    Startups: 'startups',
    UnlistedStocks: 'unlisted-stocks',
    Sneakers: 'sneakers',
  },
};

const SearchTab = createMaterialTopTabNavigator<SearchTabParamList>();

const StyledSearchTabNavigator = styled(SearchTab.Navigator)``;

export default function SearchTabScreen({route}) {
  const theme = useTheme();
  const {value} = route.params;
  const searchAllData = useSearchAll({searchByTitle: value});

  console.log(searchAllData);

  if (searchAllData.isLoading) {
    return <></>;
  }

  return (
    <>
      <StyledSearchTabNavigator
        screenOptions={{
          swipeEnabled: false,
          tabBarIndicatorStyle: {
            backgroundColor: theme.colors.prapBlue1,
          },
          tabBarLabelStyle: {
            fontSize: 13,
          },
          tabBarContentContainerStyle: {
            paddingTop: 8,
            paddingBottom: 8,
          },
          tabBarScrollEnabled: true,
          tabBarItemStyle: {
            width: 96,
            padding: 0,
            marginRight: 8,

            borderRadius: 40,
            backgroundColor: `${theme.colors.prapBlue4}`,
          },
          tabBarPressColor: `${theme.colors.prapBlue1}`,

          tabBarActiveTintColor: `${theme.colors.prapBlue1}`,
          tabBarInactiveTintColor: `${theme.colors.prapGray2}`,
        }}
        tabBar={CategoryTabBar}>
        <SearchTab.Screen
          name="조각투자"
          component={SearchScreen}
          options={{title: '조각투자'}}
          initialParams={{
            category: 'fractional-investments',
            data: searchAllData,
          }}
          params
        />
        <SearchTab.Screen
          name="Sneakers"
          component={SearchScreen}
          options={{title: '스니커즈리셀'}}
          initialParams={{
            category: 'sneakers',
            data: searchAllData,
          }}
        />
        <SearchTab.Screen
          name="스타트업펀딩"
          component={SearchScreen}
          options={{title: '스타트업펀딩'}}
          initialParams={{
            category: 'startups',
            data: searchAllData,
          }}
        />
        <SearchTab.Screen
          name="P2P투자"
          component={SearchScreen}
          options={{title: 'P2P투자'}}
          initialParams={{
            category: 'p2p-investments',
            data: searchAllData,
          }}
        />
        <SearchTab.Screen
          name="UnlistedStocks"
          component={SearchScreen}
          options={{title: '비상장주식'}}
          initialParams={{
            category: 'unlisted-stocks',
            data: searchAllData,
          }}
        />
      </StyledSearchTabNavigator>
    </>
  );
}
