/** @see https://github.com/react-navigation/react-navigation/blob/main/packages/native/src/Link.tsx */
import type {NavigationAction} from '@react-navigation/core';
import {useLinkProps} from '@react-navigation/native';
import {To} from '@react-navigation/native/lib/typescript/src/useLinkTo';
import * as React from 'react';
import {
  GestureResponderEvent,
  Platform,
  Pressable,
  PressableProps,
} from 'react-native';

type Props<ParamList extends ReactNavigation.RootParamList> = {
  to: To<ParamList>;
  action?: NavigationAction;
  target?: string;
  onPress?: (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent,
  ) => void;
} & (PressableProps & {children: React.ReactNode});

/**
 * Component to render link to another screen using a path.
 * Uses an anchor tag on the web.
 *
 * @param props.to Absolute path to screen (e.g. `/feeds/hot`).
 * @param props.action Optional action to use for in-page navigation. By default, the path is parsed to an action based on linking config.
 * @param props.children Child elements to render the content.
 */
export default function LinkPressable<
  ParamList extends ReactNavigation.RootParamList,
>({to, action, ...rest}: Props<ParamList>) {
  const props = useLinkProps<ParamList>({to, action});

  const onPress = (
    e: React.MouseEvent<HTMLAnchorElement, MouseEvent> | GestureResponderEvent,
  ) => {
    if ('onPress' in rest) {
      rest.onPress?.(e);
    }

    props.onPress(e);
  };

  return React.createElement(Pressable, {
    ...props,
    ...rest,
    ...Platform.select({
      web: {onClick: onPress} as any,
      default: {onPress},
    }),
  });
}
