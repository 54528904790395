import axios from 'axios';
import {useMutation, useQueryClient} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

interface InvestmentView {
  id?: number;
  category?: string;
}

const postInvestmentView = async (investmentView: InvestmentView) => {
  const response = await axios
    .post(`${PRAP_API_ENDPOINT}/investment-views/`, investmentView, {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    })
    .catch(error => {
      console.log('postInvestmentView:', 'error.config:', error.config);
    });

  return response;
};

export const useInvestmentViewMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(postInvestmentView, {
    onMutate: async item => {
      await queryClient.cancelQueries(USER_HASH_ID);
    },
    onSuccess: (data, variable) => {},
    onError: error => {
      console.error(error);
    },
  });
};
