import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {PRAP_API_ENDPOINT} from '../../Investments/InvestmentAPI/constants';

export const useCurations = (optionId: number) => {
  return useInfiniteQuery(
    [`curation_${optionId}`],
    ({pageParam = 1}) => getCurations({page: pageParam, optionId: optionId}),
    {
      getNextPageParam: (response, pages) => {
        // if (response.next === null) {
        //   return undefined;
        // } else {
        return pages.length + 1;
        // }
      },
    },
  );
};

const getCurations = async ({
  page = 1,
  optionId,
}: {
  page?: number;
  optionId?: number;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/curations/${optionId}/`;
  const {data} = await axios.get(ENDPOINT);

  console.log(data);
  return data;
};
