import { useTheme } from '@emotion/react';
import * as React from 'react';
import Svg, { Path } from 'react-native-svg';

const SvgBellIcon = props => {
  const theme = useTheme();
  return (
    <Svg
      width={24}
      height={24}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}>
      <Path d="M20 18H4a1 1 0 1 0 0 2h16a1 1 0 1 0 0-2Z" fill={process.env.FLAVOR === 'qv' ? theme.colors.infoWhite: '#666'} />
      <Path
        d="M12 7a5 5 0 0 1 5 5v6H7v-6a5 5 0 0 1 5-5Zm0-2a7 7 0 0 0-7 7v8h14v-8a7 7 0 0 0-7-7Z"
        fill={process.env.FLAVOR === 'qv' ? theme.colors.infoWhite: '#666'}
      />
      <Path d="M13 4a1 1 0 1 0-2 0v2a1 1 0 1 0 2 0V4Z" fill={process.env.FLAVOR === 'qv' ? theme.colors.infoWhite: '#666'} />
      <Path
        d="M15 19a3 3 0 0 1-6 0h6Z"
        stroke={process.env.FLAVOR === 'qv' ? theme.colors.infoWhite: '#666'}
        strokeWidth={2}
        strokeMiterlimit={10}
      />
    </Svg>
  );
}

export default SvgBellIcon;
