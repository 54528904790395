import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgHomeIcon = props => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M4 21V9.17l8-5.926 8 5.926V21H4Z"
      stroke="#939498"
      strokeWidth={2}
    />
    <Path d="M15 20v-8H9v8h6Z" fill="#939498" />
  </Svg>
);

export default SvgHomeIcon;
