import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgStartupIcon = props => (
  <Svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M19.605 11.804a2.734 2.734 0 0 1 1.014-.98 2.834 2.834 0 0 1 2.762 0c.42.235.77.573 1.014.98l7.33 12.255 7.256 12.269a2.607 2.607 0 0 1 .015 2.654 2.704 2.704 0 0 1-.99.98 2.805 2.805 0 0 1-1.361.371H7.355a2.807 2.807 0 0 1-1.381-.35c-.42-.232-.77-.57-1.014-.976a2.608 2.608 0 0 1 0-2.679l7.258-12.27 7.387-12.254Z"
      fill="#F8D494"
    />
    <Path
      d="M28.967 40.333a37.929 37.929 0 0 0 2.54-13.764c.143-7.772-2.306-15.381-6.982-21.69a3.16 3.16 0 0 0-1.116-.893 3.255 3.255 0 0 0-2.818 0 3.16 3.16 0 0 0-1.117.894c-4.675 6.308-7.125 13.917-6.98 21.69-.017 4.699.844 9.362 2.54 13.763h13.933Z"
      fill="#F4A544"
    />
    <Path
      d="M22 23.396c2.621 0 4.746-2.065 4.746-4.612 0-2.546-2.125-4.611-4.746-4.611-2.621 0-4.746 2.065-4.746 4.611 0 2.547 2.125 4.612 4.746 4.612Z"
      fill="#F8D494"
    />
  </Svg>
);

export default SvgStartupIcon;
