import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgAddPlatformIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M1.333 7.333v1.334h13.333V7.333H1.333Z" fill="#FEFEFE" />
    <Path d="M7.333 1.333v13.334h1.333V1.333H7.333Z" fill="#FEFEFE" />
  </Svg>
);

export default SvgAddPlatformIcon;
