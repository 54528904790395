import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgPlatformsIcon = props => (
  <Svg
    width={24}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path d="M4 20V10h16v10H4Z" stroke="#939498" strokeWidth={2} />
    <Path
      d="M3 4h6v6a3 3 0 1 1-6 0V4ZM9 4h6v6a3 3 0 1 1-6 0V4ZM15 4h6v6a3 3 0 1 1-6 0V4Z"
      fill="#FEFEFE"
      stroke="#939498"
      strokeWidth={2}
    />
  </Svg>
);

export default SvgPlatformsIcon;
