import * as React from 'react';
import Svg, {Circle, Path} from 'react-native-svg';

const SvgPlatformInfo = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Circle cx={8} cy={8} r={7} fill="#DBDCDF" />
    <Path
      d="M7.14 9.889h1.254c-.333-2.52 1.843-1.71 1.843-4.118 0-1.332-1.01-2.087-2.32-2.143-1.099-.055-2.42.578-2.542 2.542h1.387c.056-.888.489-1.31 1.11-1.31.622 0 .888.444.888 1.022 0 1.443-1.986 1.11-1.62 4.007Zm-.189 2.486h1.62v-1.576h-1.62v1.576Z"
      fill="#FEFEFE"
    />
  </Svg>
);

export default SvgPlatformInfo;
