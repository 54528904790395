import {useTheme} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useState} from 'react';
import {asyncScrapItemAtom} from '../Clippings/clippingApi/atoms';
import {useInvestmentViewMutation} from '../Clippings/clippingApi/useInvestmentViewMutation';
import {useRecentlyPostMutation} from '../Clippings/clippingApi/useRecentlyItemMutation';
import {
  useScrapDeleteMutation,
  useScrapPostMutation,
} from '../Clippings/clippingApi/useScrapItemMutation';
import SvgScrapIcon from '../Clippings/ScrapIcon';
import SvgUnScrapIcon from '../Clippings/UnScrapIcon';
import {CustomText as Text} from '../Home/CustomText';
import LinkPressable from '../LinkPressable/LinkPressable';
import {priceToKor} from './InvestmentAPI/priceToKor';
import {engToKor} from './InvestmentAPI/translate';
import {
  ExtraFigures,
  Fluctuation,
  LongFigures,
  Progress,
  ShortFigures,
  Thumbnail,
} from './style/index';
import {
  ClippingCTA,
  ClipView,
  FilterItemForm,
  PlatformInfoView,
  PlatformProductBodyWrap,
  ProductAuthor,
  ProductClippingWrap,
  ProductImageWrap,
  ProductInfoContainer,
  ProductInfoWrap,
  ProductName,
  ProductNameWrap,
  ScrollItemText,
  StyledProductContainer,
} from './style/public.style';

export default function MediumInvestment({
  investment,
  rank,
  productType,
  snackbarVisible,
  setClipping,
  clippedItem,
  scrap = false,
}: {
  investment: any;
  // | FractionalInvestment
  // | Sneakers
  // | P2PInvestment
  // | UnlistedStock
  // | Startup;
  rank?: number;
  productType?: string;
  snackbarVisible?: () => void;
  setClipping?: any;
  clippedItem?: any;
  scrap?: boolean;
}) {
  const theme = useTheme();

  const [scrapData, setScrapData] = useAtom(asyncScrapItemAtom);

  const postScrapItem = useScrapPostMutation();
  const deleteScrapItem = useScrapDeleteMutation();
  const postRecentlyItem = useRecentlyPostMutation();
  const investmentView = useInvestmentViewMutation();

  const [hasSameScrapItem, setHasSameScrapItem] = useState(
    scrapData[investment.category]?.data?.pages
      .map(page => page.result)
      .map(page => page.results)
      .flat()
      .map(product => product.scrapId)
      .includes(investment.scrapId),
  );

  const addScrapItem = (category: string, id: number) => {
    setHasSameScrapItem(true);
    snackbarVisible();
    postScrapItem.mutate({category: category, id: id});
  };

  const removeScrapItem = (deleteId: number, category: string) => {
    setHasSameScrapItem(false);
    deleteScrapItem.mutate({deleteId: deleteId, category: category});
  };

  const addRecentlyItem = (category: string, id: number, url?: string) => {
    postRecentlyItem.mutate({category: category, id: id});
    investmentView.mutate({category: category, id: id});
    console.log(category, id);
  };

  if (productType) {
    if (productType === 'fractional-investments') {
      return (
        <FractionalMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (productType === 'startups') {
      return (
        <StartupMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (productType === 'sneakers') {
      return (
        <SneakersMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (productType === 'unlisted-stocks') {
      return (
        <UnlistedStockMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (productType === 'p2p-investments') {
      return (
        <P2PMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
  } else if (investment.category) {
    if (investment.category === 'fractional-investments') {
      return (
        <FractionalMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (investment.category === 'startups') {
      return (
        <StartupMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (investment.category === 'sneakers') {
      return (
        <SneakersMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (investment.category === 'unlisted-stocks') {
      return (
        <UnlistedStockMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
    if (investment.category === 'p2p-investments') {
      return (
        <P2PMediumInvestment
          investment={investment}
          rank={rank}
          addRecentlyItem={addRecentlyItem}
          hasSameScrapItem={hasSameScrapItem}
          removeScrapItem={removeScrapItem}
          addScrapItem={addScrapItem}
        />
      );
    }
  }

  return <Text>...</Text>;
}

const FractionalMediumInvestment = ({
  investment,
  rank,
  addRecentlyItem,
  hasSameScrapItem,
  removeScrapItem,
  addScrapItem,
}: {
  investment: any;
  rank?: number;
  addRecentlyItem: (category: string, id: number, url?: string) => void;
  hasSameScrapItem: any;
  removeScrapItem: (deleteId: number, category: string) => void;
  addScrapItem: (category: string, id: number) => void;
}) => {
  return (
    <StyledProductContainer>
      <LinkPressable
        to={{
          screen: 'Bridge',
          params: {
            category: investment.category,
            id: investment.id,
            url: investment.url,
            platform: engToKor[investment.platform][0],
          },
        }}>
        <PlatformProductBodyWrap>
          <ProductImageWrap>
            <Thumbnail image={investment.image} rank={rank} />
          </ProductImageWrap>
          <ProductInfoContainer>
            <ProductClippingWrap>
              <PlatformInfoView>
                <FilterItemForm>
                  <ScrollItemText>
                    {engToKor[investment.platform]}
                  </ScrollItemText>
                </FilterItemForm>
              </PlatformInfoView>
              {hasSameScrapItem ? (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      removeScrapItem(investment.scrapId, investment.category);
                      event.preventDefault();
                    }}>
                    <SvgUnScrapIcon />
                  </ClippingCTA>
                </ClipView>
              ) : (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      addScrapItem(investment.category, investment.id);
                      event.preventDefault();
                    }}>
                    <SvgScrapIcon />
                  </ClippingCTA>
                </ClipView>
              )}
            </ProductClippingWrap>
            <ProductNameWrap>
              <ProductName numberOfLines={2}>{investment.title}</ProductName>
              <ProductAuthor>{investment.author}</ProductAuthor>
            </ProductNameWrap>
            <ProductInfoWrap>
              <ShortFigures
                figures={[
                  {
                    label: '최소투자금액',
                    value: `${priceToKor(investment.minimumPrice)}원`,
                  },
                  {
                    label: '모집금액',
                    value: `${priceToKor(investment.totalAmount)}원`,
                  },
                  {label: '수수료', value: `${Math.round(investment.fee)}`},
                ]}
              />
            </ProductInfoWrap>
            <Progress
              value={investment.percent}
              extra="148명이 투자"
              status={`${investment.period}일 남음`}
            />
          </ProductInfoContainer>
        </PlatformProductBodyWrap>
      </LinkPressable>
    </StyledProductContainer>
  );
};

const StartupMediumInvestment = ({
  investment,
  rank,
  addRecentlyItem,
  hasSameScrapItem,
  removeScrapItem,
  addScrapItem,
}: {
  investment: any;
  rank?: number;
  addRecentlyItem: (category: string, id: number, url?: string) => void;
  hasSameScrapItem: any;
  removeScrapItem: (deleteId: number, category: string) => void;
  addScrapItem: (category: string, id: number) => void;
}) => {
  return (
    <StyledProductContainer>
      <LinkPressable
        to={{
          screen: 'Bridge',
          params: {
            category: investment.category,
            id: investment.id,
            url: investment.url,
            platform: engToKor[investment.platform][0],
          },
        }}>
        <PlatformProductBodyWrap>
          <ProductImageWrap>
            <Thumbnail image={investment.image} rank={rank} />
          </ProductImageWrap>
          <ProductInfoContainer>
            <ProductClippingWrap>
              <PlatformInfoView>
                <FilterItemForm>
                  <ScrollItemText>
                    {engToKor[investment.platform]}
                  </ScrollItemText>
                </FilterItemForm>
              </PlatformInfoView>
              {hasSameScrapItem ? (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      removeScrapItem(investment.scrapId, investment.category);
                      event.preventDefault();
                    }}>
                    <SvgUnScrapIcon />
                  </ClippingCTA>
                </ClipView>
              ) : (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      addScrapItem(investment.category, investment.id);
                      event.preventDefault();
                    }}>
                    <SvgScrapIcon />
                  </ClippingCTA>
                </ClipView>
              )}
            </ProductClippingWrap>
            <ProductNameWrap>
              <ProductName numberOfLines={2}>{investment.title}</ProductName>
              <ProductAuthor>{investment.author}</ProductAuthor>
            </ProductNameWrap>
            <ProductInfoWrap>
              <ShortFigures
                figures={[
                  {
                    label: '목표금액',
                    value: `${priceToKor(investment.totalAmount)}원`,
                  },
                  {
                    label: '최소투자금액',
                    value: `${priceToKor(investment.minimumAmount)}원`,
                  },
                  {
                    label: '상품유형',
                    value: `${priceToKor(investment.subcategory)}형`,
                  },
                ]}
              />
            </ProductInfoWrap>
            <Progress
              value={investment.percent}
              extra="148명이 투자"
              status={`${investment.period}일 남음`}
            />
          </ProductInfoContainer>
        </PlatformProductBodyWrap>
      </LinkPressable>
    </StyledProductContainer>
  );
};

const SneakersMediumInvestment = ({
  investment,
  rank,
  addRecentlyItem,
  hasSameScrapItem,
  removeScrapItem,
  addScrapItem,
}: {
  investment: any;
  rank?: number;
  addRecentlyItem: (category: string, id: number, url?: string) => void;
  hasSameScrapItem: any;
  removeScrapItem: (deleteId: number, category: string) => void;
  addScrapItem: (category: string, id: number) => void;
}) => {
  return (
    <StyledProductContainer>
      <LinkPressable
        to={{
          screen: 'InvestmentDetails',
          params: {
            category: investment.category,
            id: investment.id,
          },
        }}>
        <PlatformProductBodyWrap>
          <ProductImageWrap>
            <Thumbnail image={investment.image} rank={rank} />
          </ProductImageWrap>
          <ProductInfoContainer>
            <ProductClippingWrap>
              <PlatformInfoView>
                <FilterItemForm>
                  <ScrollItemText>
                    {engToKor[investment.platform]}
                  </ScrollItemText>
                </FilterItemForm>
              </PlatformInfoView>
              {hasSameScrapItem ? (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      removeScrapItem(investment.scrapId, investment.category);
                      event.preventDefault();
                    }}>
                    <SvgUnScrapIcon />
                  </ClippingCTA>
                </ClipView>
              ) : (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      addScrapItem(investment.category, investment.id);
                      event.preventDefault();
                    }}>
                    <SvgScrapIcon />
                  </ClippingCTA>
                </ClipView>
              )}
            </ProductClippingWrap>
            <ProductNameWrap>
              <ProductName numberOfLines={2}>{investment.name}</ProductName>
              <ProductAuthor>{investment.author}</ProductAuthor>
            </ProductNameWrap>
            <ProductInfoWrap>
              <LongFigures
                figures={[
                  {
                    label: '발매가',
                    value: `${Math.round(investment.price).toLocaleString()}원`,
                  },
                  {label: '리셀 평균가', value: '546,000원'},
                ]}
              />
            </ProductInfoWrap>
          </ProductInfoContainer>
        </PlatformProductBodyWrap>
      </LinkPressable>
    </StyledProductContainer>
  );
};

const UnlistedStockMediumInvestment = ({
  investment,
  rank,
  addRecentlyItem,
  hasSameScrapItem,
  removeScrapItem,
  addScrapItem,
}: {
  investment: any;
  rank?: number;
  addRecentlyItem: (category: string, id: number, url?: string) => void;
  hasSameScrapItem: any;
  removeScrapItem: (deleteId: number, category: string) => void;
  addScrapItem: (category: string, id: number) => void;
}) => {
  return (
    <StyledProductContainer>
      <LinkPressable
        to={{
          screen: 'InvestmentDetails',
          params: {
            category: investment.category,
            id: investment.id,
          },
        }}>
        <PlatformProductBodyWrap>
          <ProductImageWrap>
            <Thumbnail image={investment.image} rank={rank} />
          </ProductImageWrap>
          <ProductInfoContainer>
            <ProductClippingWrap>
              <PlatformInfoView>
                <FilterItemForm>
                  <ScrollItemText>
                    {engToKor[investment.platform]}
                  </ScrollItemText>
                </FilterItemForm>
              </PlatformInfoView>
              {hasSameScrapItem ? (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      removeScrapItem(investment.scrapId, investment.category);
                      event.preventDefault();
                    }}>
                    <SvgUnScrapIcon />
                  </ClippingCTA>
                </ClipView>
              ) : (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      addScrapItem(investment.category, investment.id);
                      event.preventDefault();
                    }}>
                    <SvgScrapIcon />
                  </ClippingCTA>
                </ClipView>
              )}
            </ProductClippingWrap>
            <ProductNameWrap>
              <ProductName numberOfLines={2}>{investment.name}</ProductName>
              <ProductAuthor>{investment.author}</ProductAuthor>
            </ProductNameWrap>
            <ProductInfoWrap>
              <Fluctuation value={investment.changes} />
              <LongFigures
                figures={[
                  {
                    label: '주당 최저가',
                    value: `${Math.round(investment.price).toLocaleString()}원`,
                  },
                ]}
              />
            </ProductInfoWrap>
          </ProductInfoContainer>
        </PlatformProductBodyWrap>
      </LinkPressable>
    </StyledProductContainer>
  );
};

const P2PMediumInvestment = ({
  investment,
  rank,
  addRecentlyItem,
  hasSameScrapItem,
  removeScrapItem,
  addScrapItem,
}: {
  investment: any;
  rank?: number;
  addRecentlyItem: (category: string, id: number, url?: string) => void;
  hasSameScrapItem: any;
  removeScrapItem: (deleteId: number, category: string) => void;
  addScrapItem: (category: string, id: number) => void;
}) => {
  return (
    <StyledProductContainer>
      <LinkPressable
        to={{
          screen: 'Bridge',
          params: {
            category: investment.category,
            id: investment.id,
            url: investment.url,
            platform: engToKor[investment.platform][0],
          },
        }}>
        <PlatformProductBodyWrap>
          <ProductImageWrap>
            <Thumbnail image={investment.image} rank={rank} />
          </ProductImageWrap>
          <ProductInfoContainer>
            <ProductClippingWrap>
              <PlatformInfoView>
                <FilterItemForm>
                  <ScrollItemText>
                    {engToKor[investment.platform]}
                  </ScrollItemText>
                </FilterItemForm>
              </PlatformInfoView>
              {hasSameScrapItem ? (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      removeScrapItem(investment.scrapId, investment.category);
                      event.preventDefault();
                    }}>
                    <SvgUnScrapIcon />
                  </ClippingCTA>
                </ClipView>
              ) : (
                <ClipView>
                  <ClippingCTA
                    onPress={event => {
                      addScrapItem(investment.category, investment.id);
                      event.preventDefault();
                    }}>
                    <SvgScrapIcon />
                  </ClippingCTA>
                </ClipView>
              )}
            </ProductClippingWrap>
            <ProductNameWrap>
              <ProductName numberOfLines={2}>{investment.title}</ProductName>
              <ProductAuthor>{investment.author}</ProductAuthor>
            </ProductNameWrap>
            <ProductInfoWrap>
              <ShortFigures
                figures={[
                  {label: '연수익률', value: `${investment.income}%`},
                  {
                    label: '모집금액',
                    value: `${priceToKor(investment.totalAmount)}원`,
                  },
                  {label: '투자기간', value: '12개월'},
                ]}
              />

              <ExtraFigures
                figures={[
                  {label: '최소투자금액', value: '15,000원'},
                  {label: '수수료', value: '1.1%'},
                ]}
              />
              <Progress
                value={investment.percent}
                status={investment.period + '일 남음'}
              />
            </ProductInfoWrap>
          </ProductInfoContainer>
        </PlatformProductBodyWrap>
      </LinkPressable>
    </StyledProductContainer>
  );
};
