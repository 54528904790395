import GorhomBottomSheet from '@gorhom/bottom-sheet';
import {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import {PropsWithChildren, RefObject} from 'react';
import {ScrollView} from 'react-native';

export {BottomSheetModalProvider} from '@gorhom/bottom-sheet';

function BottomSheet({
  bottomSheetRef,
  ...props
}: PropsWithChildren<{
  bottomSheetRef: RefObject<BottomSheetMethods>;
}>) {
  return <GorhomBottomSheet ref={bottomSheetRef} {...props} />;
}

export default BottomSheet;

export const BottomSheetScrollView = ScrollView;

export function BottomSheetBackdrop() {
  return <></>;
}
