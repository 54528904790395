import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import {useAtom} from 'jotai';
import React, {useEffect} from 'react';
import {USER_HASH_ID} from '../Investments/InvestmentAPI/constants';
import CategoryTabBar from './CategoryTabBar';
import {asyncRecentlyItemAtom} from './clippingApi/atoms';
import {useUserRecentlyItem} from './clippingApi/useUserRecentViewItem';
import RecentlyViewed from './RecentlyViewed';

export type RecentlyViewedTabParamList = {
  P2PInvestments: undefined;
  FractionalInvestments: undefined;
  Startups: undefined;
  UnlistedStocks: undefined;
  Sneakers: undefined;
};

export const recentlyViewedTabPathConfig: PathConfig<RecentlyViewedTabParamList> =
  {
    path: 'recently-viewed',
    screens: {
      P2PInvestments: 'p2p-investments',
      FractionalInvestments: 'fractional-investments',
      Startups: 'startups',
      UnlistedStocks: 'unlisted-stocks',
      Sneakers: 'sneakers',
    },
  };

const RecentlyViewedTab =
  createMaterialTopTabNavigator<RecentlyViewedTabParamList>();

const StyledRecentlyViewedTabNavigator = styled(RecentlyViewedTab.Navigator)``;

export default function RecentlyViewedTabScreen({}) {
  const theme = useTheme();
  const userRecentItem = useUserRecentlyItem(USER_HASH_ID);
  const [data, setData] = useAtom(asyncRecentlyItemAtom);

  console.log(userRecentItem);

  useEffect(() => {
    setData(userRecentItem);
  }, []);

  useEffect(() => {
    setData(userRecentItem);
  }, [userRecentItem.data]);

  if (userRecentItem.isLoading) {
    return <></>;
  }
  if (userRecentItem.isError) {
    return <></>;
  }
  if (userRecentItem.isSuccess) {
    return (
      <>
        <StyledRecentlyViewedTabNavigator
          screenOptions={{
            swipeEnabled: false,
            tabBarIndicatorStyle: {
              backgroundColor: theme.colors.prapBlue1,
            },
            tabBarLabelStyle: {
              fontSize: 13,
            },
            tabBarContentContainerStyle: {
              paddingTop: 8,
              paddingBottom: 8,
            },
            tabBarScrollEnabled: true,
            tabBarItemStyle: {
              width: 96,
              padding: 0,
              marginRight: 8,

              borderRadius: 40,
              backgroundColor: `${theme.colors.prapBlue4}`,
            },
            tabBarPressColor: `${theme.colors.prapBlue1}`,

            tabBarActiveTintColor: `${theme.colors.prapBlue1}`,
            tabBarInactiveTintColor: `${theme.colors.prapGray2}`,
          }}
          tabBar={CategoryTabBar}>
          <RecentlyViewedTab.Screen
            name="FractionalInvestments"
            component={FractionalInvestmentRecentlyViewedScreen}
            options={{title: '조각투자'}}
          />
          <RecentlyViewedTab.Screen
            name="Sneakers"
            component={SneakerRecentlyViewedScreen}
            options={{title: '스니커즈리셀'}}
          />
          <RecentlyViewedTab.Screen
            name="Startups"
            component={StartupRecentlyViewedScreen}
            options={{title: '스타트업펀딩'}}
          />
          <RecentlyViewedTab.Screen
            name="P2PInvestments"
            component={P2PInvestmentRecentlyViewedScreen}
            options={{title: 'P2P투자'}}
          />

          <RecentlyViewedTab.Screen
            name="UnlistedStocks"
            component={UnlistedStockRecentlyViewedScreen}
            options={{title: '비상장주식'}}
          />
        </StyledRecentlyViewedTabNavigator>
      </>
    );
  }
}

function FractionalInvestmentRecentlyViewedScreen() {
  return <RecentlyViewed category="fractional-investments" />;
}

function SneakerRecentlyViewedScreen() {
  return <RecentlyViewed category="sneakers" />;
}

function StartupRecentlyViewedScreen() {
  return <RecentlyViewed category="startups" />;
}

function P2PInvestmentRecentlyViewedScreen() {
  return <RecentlyViewed category="p2p-investments" />;
}

function UnlistedStockRecentlyViewedScreen() {
  return <RecentlyViewed category="unlisted-stocks" />;
}
