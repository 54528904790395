import styled from '@emotion/native';
import React, {useState} from 'react';
import {USER_HASH_ID} from '../Investments/InvestmentAPI/constants';
import {korToEng} from '../Investments/InvestmentAPI/translate';
import MediumInvestment from '../Investments/MediumInvestment';
import {useUserRecentlyItem} from './clippingApi/useUserRecentViewItem';

export default function RecentlyViewed({category}: {category: string}) {
  const recentlyViewed = useUserRecentlyItem(USER_HASH_ID);

  const [toggle, setToggle] = useState(false);
  const onToggleSnackbar = () => setToggle(!toggle);
  const onDismissSnackbar = () => setToggle(false);

  if (recentlyViewed.isLoading) {
    return <></>; // Skeleton
  }
  if (recentlyViewed.isError) {
    return <></>;
  }

  return (
    <StyledFlatList
      data={recentlyViewed.data[category]}
      renderItem={({item: investment, index: rank}) => (
        <MediumInvestment
          investment={investment}
          rank={rank}
          productType={korToEng[category]}
          snackbarVisible={onToggleSnackbar}
        />
      )}
      ListEmptyComponent={<></>}
    />
  );
}

const StyledFlatList = styled.FlatList`
  padding-bottom: 80px;
`;
