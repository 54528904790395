import styled from '@emotion/native';
import React, {PropsWithChildren} from 'react';
import {CustomText as Text} from '../Home/CustomText';
import {
  FractionalInvestment,
  P2PInvestment,
  Sneakers,
  Startup,
  UnlistedStock,
} from '../Models/index';
import {engToKor} from './InvestmentAPI/translate';
import {Progress} from './style/Progress';

export const SmallInvestment = ({
  investment,
  productType,
  carouselType,
}: {
  investment:
    | Sneakers
    | P2PInvestment
    | UnlistedStock
    | Startup
    | FractionalInvestment;
  productType: string;
  carouselType?: string;
}) => {
  if (productType === 'p2p-investments') {
    return (
      <StyledSmallInvestment>
        <SmallThumbnail image={investment.image} />
        <SmallTexts>
          <SmallTitle>{investment.name}</SmallTitle>
          <SmallDescription>{investment.author}</SmallDescription>
          <SmallProgress value={Math.ceil(investment.percent) / 100} />
        </SmallTexts>
        <SmallPlatform
          image={
            'https://upload.wikimedia.org/wikipedia/commons/thumb/2/24/Samsung_Logo.svg/800px-Samsung_Logo.svg.png'
          }
        />
      </StyledSmallInvestment>
    );
  }

  if (productType === 'sneakers') {
    return (
      <StyledSmallInvestment>
        <SmallThumbnail image={investment.image} />
        <SmallTexts>
          <SmallTitle>{investment.name}</SmallTitle>
          <SmallDescription>
            {investment.platform.map(item => engToKor[item])}
          </SmallDescription>
          <SmallTitle>{investment.price.toLocaleString()}원</SmallTitle>
        </SmallTexts>
      </StyledSmallInvestment>
    );
  }

  if (productType === 'unlisted-stocks') {
    return (
      <StyledSmallInvestment>
        <SmallThumbnail image={investment.image} />
        <SmallTexts>
          <SmallTitle>{investment.name}</SmallTitle>
          <SmallDescription>{engToKor[investment.platform]}</SmallDescription>
          <SmallTitle>{investment.price.toLocaleString()}원</SmallTitle>
        </SmallTexts>
      </StyledSmallInvestment>
    );
  }
  if (productType === 'startups') {
    return (
      <StyledSmallInvestment>
        <SmallThumbnail image={investment.image} />
        <SmallTexts>
          <SmallTitle>{investment.title}</SmallTitle>
          <SmallDescription>{investment.author}</SmallDescription>
          <SmallProgress value={Math.ceil(investment.percent) / 100} />
        </SmallTexts>
        {carouselType ? null : (
          <SmallPlatform platformName={engToKor[investment.platform]} />
        )}
      </StyledSmallInvestment>
    );
  }
  if (productType === 'fractional-investments') {
    return (
      <StyledSmallInvestment>
        <SmallThumbnail image={investment.image} />
        <SmallTexts>
          <SmallTitle>{investment.title}</SmallTitle>
          <SmallDescription>{investment.author}</SmallDescription>
          <SmallProgress value={Math.ceil(investment.percent) / 100} />
        </SmallTexts>
        {carouselType ? null : (
          <SmallPlatform platformName={engToKor[investment.platform]} />
        )}
      </StyledSmallInvestment>
    );
  }
};

export const StyledSmallInvestment = styled.View``;

export const SmallThumbnail = ({image}: {image: string}) => {
  return (
    <StyledSmallThumbnail
      source={{uri: image === '' ? null : image}}
      // defaultSource={{uri: 'https://via.placeholder.com/150'}}
    />
  );
};

const StyledSmallThumbnail = styled.Image`
  border-radius: 4px;
  width: 100%;
  aspect-ratio: 1;
  resize-mode: contain;
  background-color: ${({theme}) => theme.colors.infoWhite};
`;

export const SmallTexts = styled.View`
  margin-top: 8px;
`;

export const SmallTitle = ({children}: PropsWithChildren<{}>) => {
  return (
    <StyledSmallTitle numberOfLines={2} ellipsizeMode="tail">
      {children}
    </StyledSmallTitle>
  );
};

const StyledSmallTitle = styled(Text)`
  ${({theme}) => theme.fonts.body13};
  color: ${({theme}) => theme.colors.prapGray1};
`;

export const SmallDescription = ({children}: PropsWithChildren<{}>) => {
  return (
    <StyledSmallDescription numberOfLines={1}>
      {children}
    </StyledSmallDescription>
  );
};

const StyledSmallDescription = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray3};
  margin-top: 4px;
`;

export const SmallProgress = ({value}: {value: number}) => {
  return (
    <StyledSmallProgress>
      <Progress value={value} />
    </StyledSmallProgress>
  );
};

const StyledSmallProgress = styled.View`
  margin-top: 16px;
`;

// export const SmallPlatform = ({image}: {image: string}) => {
//   const [aspectRatio, setAspectRatio] = useState<number>();

//   useEffect(() => {
//     Image.getSize(image, (width, height) => setAspectRatio(width / height));
//   }, []);

//   return (
//     <StyledSmallPlatform>
//       <SmallPlatformImage
//         source={{uri: image === '' ? null : image}}
//         aspectRatio={aspectRatio}
//       />
//     </StyledSmallPlatform>
//   );
// };
// const StyledSmallPlatform = styled.View`
//   margin-top: 4px;
//   border-top: 1px solid ${({theme}) => theme.colors.prapGray5};
//   padding-top: 4px;
// `;

// const SmallPlatformImage = styled.Image<{aspectRatio?: number}>`
//   height: 20px;
//   aspect-ratio: ${({aspectRatio}) => aspectRatio};
//   resize-mode: contain;
// `;

export const SmallPlatform = ({platformName}: {platformName: string}) => {
  return <PlatformText>{platformName}</PlatformText>;
};

const FilterItemForm = styled.TouchableOpacity`
  font-size: 12px;
  background: ${({theme}) => theme.colors.prapBlue4};

  padding: 6px 16px;
  margin-right: 8px;
  border-radius: 20px;
  margin-top: 12px;
`;
const ScrollItemText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray4};
`;
const PlatformText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray4};
  margin-top: 8px;
`;
