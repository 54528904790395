import { atom } from 'jotai';
import { focusAtom } from 'jotai/optics';

const userData = {
    id: 10519,
    clipping: {
        platform: [],
        product: [],
    },
    recently: {
        platform: [],
        product: [],
    }
};

const clipProductData = {
    category: '',
    id: '',
}

const userClipping = atom(userData.clipping);

const clipProductAtom = atom(clipProductData);

const clipPlatform = focusAtom(userClipping, (optics) => optics.prop('platform'));
const clipProduct = focusAtom(userClipping, (optics) => optics.prop('product'));

export { clipPlatform, clipProduct, clipProductAtom };

