import {BottomSheetMethods} from '@gorhom/bottom-sheet/lib/typescript/types';
import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useMemo,
  useState,
} from 'react';
import {useWindowDimensions} from 'react-native';
import BottomSheetModal, {
  BottomSheetBackdrop,
  BottomSheetScrollView,
} from '../BottomSheet/BottomSheet';
import {UNLISTED_PLATFORM} from '../Investments/InvestmentAPI/constants';
import {
  FilterArea,
  FilterHeadText,
  FilterHeadView,
  FilterItemForm,
  FilterMenuView,
  FilterText,
  FilterViewPressable,
  FixedArea,
  FundedHeader,
  FundedProductView,
  InitiallizeFilterCTA,
  InitiallizeFilterText,
  ScrollItemText,
  SelectProductArea,
  SelectProductCTA,
  SelectProductText,
  SortItemForm,
  SortMenuView,
  SortText,
} from './style/public.style';

interface FilterDataType {
  status?: string[];
  platform?: string[];
  totalAmountLow?: number | string;
  totalAmountHigh?: number | string;
}

const platformKorToEng = {
  [UNLISTED_PLATFORM.SEOUL_EXCHANGE]: 'seoulexchange',
  [UNLISTED_PLATFORM.U_STOCK_PLUS]: 'ustockplus',
};

const filterData = {
  platform: [UNLISTED_PLATFORM.SEOUL_EXCHANGE, UNLISTED_PLATFORM.U_STOCK_PLUS],

  sortOption: [],
};

export const UnlistedStocksFilter = ({
  setFilterOption,
  filterOption = {
    platform: [''],
    sortOption: [''],
  },
  bottomSheetRef,
  setSort,
  isSort,
}: {
  bottomSheetRef: RefObject<BottomSheetMethods>;
  isSort: string;
  setSort: Dispatch<SetStateAction<string>>;
}) => {
  let copiedFilterOption = {...filterOption};
  const [copiedFilterOptionState, setCopiedFilterOptionState] =
    useState(copiedFilterOption);

  const [savePlatform, setSavePlatform] = useState(['']);

  const resetOptions = () => {
    setSavePlatform(['']);
  };

  const [expanded, setExpanded] = useState(false);
  const handleExpand = () => setExpanded(!expanded);

  const handlePlatform = (platform: string) => {
    const copiedPlatform = [...savePlatform];
    if (copiedPlatform.includes(platformKorToEng[platform])) {
      copiedPlatform.forEach((el, i) => {
        el === platformKorToEng[platform] ? copiedPlatform.splice(i, 1) : null;
      });
    } else {
      copiedPlatform.push(platformKorToEng[platform]);
    }
    setSavePlatform(copiedPlatform);
  };

  const renderFilterOptions = () => {
    return (
      <>
        <FilterArea>
          <FilterHeadView>
            <FilterHeadText>플랫폼</FilterHeadText>
          </FilterHeadView>
          <FilterMenuView>
            {filterData.platform.map((platform, i) => {
              return (
                <FilterItemForm
                  key={`${platform}-platform-${i}`}
                  onPress={() => {
                    handlePlatform(platform);
                  }}
                  isActive={savePlatform.includes(platformKorToEng[platform])}>
                  <ScrollItemText
                    isActive={savePlatform.includes(
                      platformKorToEng[platform],
                    )}>
                    {platform}
                  </ScrollItemText>
                </FilterItemForm>
              );
            })}
          </FilterMenuView>
        </FilterArea>
      </>
    );
  };

  const renderSortOptions = () => {
    return (
      <>
        <FilterArea>
          <SortMenuView>
            {filterData.sortOption.map((option, i) => {
              return (
                <SortItemForm
                  key={`${option}-option-${i}`}
                  onPress={() => handleSortOption(option)}>
                  <ScrollItemText
                    isActive={saveSortOption.includes(korToEng[option])}>
                    {option}
                  </ScrollItemText>
                </SortItemForm>
              );
            })}
          </SortMenuView>
        </FilterArea>
      </>
    );
  };

  const snapPoints = useMemo(() => [1, '95%'], []);

  const handleSheetChanges = (index: number) => {
    setSavePlatform(copiedFilterOption.platform);

    if (index <= 0) {
      bottomSheetRef.current?.close();
    }
  };

  const windowHeight = useWindowDimensions().height;
  const titleBarHeight = 64;
  const tabHeight = 48;
  const bottomSheetHandleHeight = 24;

  return (
    <BottomSheetModal
      bottomSheetRef={bottomSheetRef}
      index={0}
      snapPoints={snapPoints}
      backdropComponent={BottomSheetBackdrop}
      onChange={handleSheetChanges}>
      <BottomSheetScrollView
        stickyHeaderIndices={[0]}
        style={{
          height:
            (windowHeight -
              titleBarHeight -
              tabHeight -
              bottomSheetHandleHeight) *
            0.95,
        }}>
        <FundedHeader>
          <FilterViewPressable onPress={() => setSort('정렬')}>
            <SortText isSort={isSort}>정렬</SortText>
          </FilterViewPressable>
          <FilterViewPressable onPress={() => setSort('필터')}>
            <FilterText isSort={isSort}>필터</FilterText>
          </FilterViewPressable>
        </FundedHeader>
        <FundedProductView>
          {isSort === '필터' ? renderFilterOptions() : renderSortOptions()}
        </FundedProductView>
      </BottomSheetScrollView>
      <FixedArea>
        <SelectProductArea>
          <InitiallizeFilterCTA onPress={() => resetOptions()}>
            <InitiallizeFilterText>선택 초기화</InitiallizeFilterText>
          </InitiallizeFilterCTA>
          <SelectProductCTA
            onPress={() => {
              setFilterOption({
                platform: savePlatform,
              });

              bottomSheetRef.current?.close();
            }}>
            <SelectProductText>선택하기</SelectProductText>
          </SelectProductCTA>
        </SelectProductArea>
      </FixedArea>
    </BottomSheetModal>
  );
};
