import styled from '@emotion/native';
import {Modal} from 'react-native';
import {CustomText as Text} from '../../Home/CustomText';
import {useReviewDeleteMutation} from '../api/useReviewWriteMutation';

export const DeleteModal = ({
  visible,
  hideModal,
  deleteInfo,
}: {
  visible: boolean;
  hideModal: () => void;
  deleteInfo: {deleteId: number; name: string};
}) => {
  const deleteUserReview = useReviewDeleteMutation();

  const removePlatform = (deleteId: number, platform: string) => {
    deleteUserReview.mutate({deleteId: deleteId, platform: platform});
    hideModal();
  };
  return (
    <StyledModal
      visible={visible}
      onDismiss={hideModal}
      animationType="none"
      transparent={true}>
      <ModalArea onPress={hideModal}>
        <ModalBox>
          <ModalHeadText>내가 작성한 리뷰를 삭제할까요?</ModalHeadText>
          <ModalContentText>삭제한 리뷰는 복구할 수 없습니다.</ModalContentText>

          <ModalCTACombo>
            <CTAArea>
              <ModalCTA onPress={hideModal}>
                <ModalCTAText isCancle="cancle">취소</ModalCTAText>
              </ModalCTA>
              <ModalCTA
                onPress={() =>
                  removePlatform(deleteInfo.deleteId, deleteInfo.name)
                }>
                <ModalCTAText>삭제</ModalCTAText>
              </ModalCTA>
            </CTAArea>
          </ModalCTACombo>
        </ModalBox>
      </ModalArea>
    </StyledModal>
  );
};

const StyledModal = styled(Modal)`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalArea = styled.Pressable`
  background-color: rgba(0, 0, 0, 0.5);
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const ModalBox = styled.View`
  padding: 20px;
  width: 294px;
  height: 150px;
  border-radius: 15px;
  background: ${({theme}) => theme.colors.infoWhite};
`;

const ModalHeadText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.body16};
  font-family: 'SpoqaHanSansNeo-Bold';

  margin-bottom: 10px;
`;

const ModalContentText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray2};
  font-size: ${({theme}) => theme.fonts.body13};
`;

const ModalCTACombo = styled.View`
  flex: 1;
  justify-content: flex-end;
  flex-direction: row;
`;
const CTAArea = styled.View`
  align-items: flex-end;
  flex-direction: row;
`;

const ModalCTA = styled.Pressable`
  margin-left: 24px;
`;
const ModalCTAText = styled(Text)<{isCancle?: string}>`
  color: ${({theme, isCancle}) =>
    isCancle === 'cancle' ? theme.colors.prapGray4 : theme.colors.prapBlue1};
  font-size: ${({theme}) => theme.fonts.body16};
`;
