import axios from 'axios';
import {useQuery} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

const getInvestmentDetails = async (category: string, id: string) => {
  const {data} = await axios.get(`${PRAP_API_ENDPOINT}/${category}/${id}/`, {
    headers: {
      Authorization: `Bearer ${USER_HASH_ID}`,
    },
  });

  return data;
};

export function useInvestmentDetails(category: string, id: string) {
  return useQuery(['investmentDetails', category, id], () =>
    getInvestmentDetails(category, id),
  );
}
