import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {useNavigation} from '@react-navigation/native';
import {useAtom} from 'jotai';
import React, {useState} from 'react';
import {FlatList} from 'react-native';
import {Snackbar} from 'react-native-paper';
import {useInfiniteQuery} from 'react-query';
import {fractionalInvestmentsList} from '../API/fractional-investments/fractional-investments';
import CarouselView from '../CarouselView/CarouselView';
import {clipPlatform} from '../Clippings/userData';
import ClippingIcon from '../ClippingSnackbar/ClippingIcon';
import {CustomText as Text} from '../Home/CustomText';
import {FractionalInvestment} from '../Models/FractionalInvestment';
import MediumInvestment from './MediumInvestment';

const Investments = ({
  ListHeaderComponent,
  productType,
  investmentData,
}: {
  ListHeaderComponent?:
    | React.ComponentType<any>
    | React.ReactElement
    | null
    | undefined;
  productType?: string;
  investmentData?: any;
}) => {
  const theme = useTheme();
  const [toggle, setToggle] = useState(false);
  const onToggleSnackbar = () => setToggle(!toggle);
  const onDismissSnackbar = () => setToggle(false);

  const [clippedProduct, setClippedProduct] = useState({category: '', id: ''});
  const [clippedPlatform, setClippedPlatform] = useAtom(clipPlatform);
  const navigation = useNavigation();
  console.log(productType, investmentData.hasNextPage);

  return (
    <>
      <FlatList
        data={investmentData.data.pages.map(page => page.result).flat()}
        renderItem={({item: investment, index: rank}) => (
          <MediumInvestment
            investment={investment}
            rank={rank}
            productType={productType}
            snackbarVisible={onToggleSnackbar}
            // clippedItem={clippedSneakersData}
            setClipping={setClippedProduct}
          />
        )}
        keyExtractor={item => `${item.category}-${item.id}`}
        onEndReached={() =>
          investmentData.hasNextPage ? investmentData.fetchNextPage() : null
        }
        onEndReachedThreshold={0.5}
        ListHeaderComponent={ListHeaderComponent ?? <CarouselView />}
        ListFooterComponent={<BottomEmptySpace />}
      />
      <Snackbar
        visible={toggle}
        onDismiss={onDismissSnackbar}
        duration={1500}
        action={{
          label: '스크랩 바로가기',
          onPress: () => {
            // Do something
            navigation.navigate('NHMoreTab');
          },
          color: theme.colors.infoWhite,
          labelStyle: {
            fontSize: 11,
            fontFamily: 'SpoqaHanSansNeo-Bold',
            color: theme.colors.infoWhite,
          },
        }}
        style={{
          backgroundColor: theme.colors.prapGray2,
          width: 270,
        }}
        wrapperStyle={{
          marginBottom: 130,
        }}>
        <SnackbarIconView>
          <ClippingIcon />
        </SnackbarIconView>
        <SnackbarLabelText>스크랩 했습니다.</SnackbarLabelText>
      </Snackbar>
    </>
  );
};

export default Investments;

const SnackbarLabelText = styled(Text)`
  color: ${({theme}) => theme.colors.infoWhite};
`;

const getInvestments = async ({
  page = 1,
}: {
  page?: number;
}): Promise<FractionalInvestment[]> => {
  const {data} = await fractionalInvestmentsList();

  return data.results!;
};

export function useInvestments() {
  return useInfiniteQuery(
    ['investments', {}],
    ({pageParam = 1}) =>
      getInvestments({
        page: pageParam,
      }),
    {
      getNextPageParam: (_, pages) => pages.length + 1,
    },
  );
}

const BottomEmptySpace = styled.View`
  flex: 1;
  height: 80px;
`;
const SnackbarIconView = styled.View`
  margin-right: 8px;
`;
