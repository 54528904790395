import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useState} from 'react';
import {AirbnbRating} from 'react-native-ratings';
import {StackParamList} from '../App';
import {CustomText as Text} from '../Home/CustomText';
import LinkPressable from '../LinkPressable/LinkPressable';
import {usePlatformInfo} from './api/usePlatformInfo';
import {useReviewList} from './api/useReviewList';

import SvgReviewIcon from './icon/ReviewIcon';
import {DeleteModal} from './style/ReviewDeleteModal';

export default function ReviewScreen({
  route,
}: NativeStackScreenProps<StackParamList, 'Review'>) {
  // platform-reviews/?platform=platformName
  const {id, name} = route.params;
  const platformInfoData = usePlatformInfo(parseInt(id));
  const platformReviewList = useReviewList(name);

  const [visible, setVisible] = useState(false);
  const [deleteInfo, setDeleteInfo] = useState({
    deleteId: NaN,
    name: '',
  });

  const showModal = () => setVisible(true);
  const hideModal = () => setVisible(false);

  if (platformInfoData.isLoading || platformReviewList.isLoading) return <></>;

  if (platformInfoData.isError || platformReviewList.isError) return <></>;

  const platformData = platformInfoData.data?.pages[0].results;

  const renderReviews = ({item}) => {
    console.log(item);
    return (
      <ReviewForm>
        <ReviewHeadArea>
          <NameArea>
            <ReviewereName>{item.nickname}</ReviewereName>
          </NameArea>
          <DataArea>
            <AirbnbRating
              count={5}
              defaultRating={parseInt(item.star)}
              size={15}
              isDisabled={true}
              showRating={false}
            />
          </DataArea>
          {item.isMine ? (
            <DeleteButtonArea>
              <DeleteCTA
                onPress={() => {
                  setDeleteInfo({deleteId: item.id, name: name});
                  showModal();
                }}>
                <DeleteCTAText>삭제</DeleteCTAText>
              </DeleteCTA>
            </DeleteButtonArea>
          ) : null}
        </ReviewHeadArea>
        <ReviewFeedbackArea>
          <ReviewFeedbackText>{item.content}</ReviewFeedbackText>
        </ReviewFeedbackArea>
      </ReviewForm>
    );
  };

  return (
    <>
      <StyledReviewScreen stickyHeaderIndices={[0]}>
        <ReviewStickyHeader>
          <ReviewRatingArea>
            <ReviewRatingText>{platformData.star}</ReviewRatingText>
            <AirbnbRating
              count={5}
              defaultRating={platformData.star}
              size={20}
              isDisabled={true}
              showRating={false}
            />
          </ReviewRatingArea>
          <ReviewTotal>후기 {platformData.reviewCount}개</ReviewTotal>
        </ReviewStickyHeader>

        <ReviewFlatList
          data={platformReviewList.data?.pages.map(page => page.results).flat()}
          renderItem={item => renderReviews(item)}
          keyExtractor={item => item.id}
          ItemSeparatorComponent={ItemSeparatorThin}
        />
      </StyledReviewScreen>
      <StyledLink to={{screen: 'WriteReview', params: {name: name}}}>
        <StyledLinkToReviewButton>
          <SvgReviewIcon />
        </StyledLinkToReviewButton>
      </StyledLink>

      {visible ? (
        <DeleteModal
          visible={visible}
          hideModal={hideModal}
          deleteInfo={deleteInfo}
        />
      ) : null}
    </>
  );
}

const DismissArea = styled.Pressable``;

const DeleteButtonArea = styled.View`
  flex: 1;
  align-items: flex-end;
`;
const DeleteCTA = styled.Pressable`
  padding: 4px 12px;
  background: ${({theme}) => theme.colors.prapGray6};
  border-radius: 15px;
`;
const DeleteCTAText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray2};
  font-size: ${({theme}) => theme.fonts.body11};
`;
const StyledLink = styled(LinkPressable)`
  position: absolute;
  bottom: 70px;
  right: 15px;
  z-index: 99;
`;
const StyledLinkToReviewButton = styled.View`
  width: 56px;
  height: 56px;

  border-radius: 40px;

  background: ${({theme}) => theme.colors.prapBlue2};

  padding: 12px;
`;
const ReviewFlatList = styled.FlatList``;

const ReviewForm = styled.View`
  padding: 15px;
  margin-bottom: 8px;
`;
const ReviewHeadArea = styled.View`
  flex-direction: row;
  align-items: center;
`;
const NameArea = styled.View`
  margin-right: 8px;
`;
const DataArea = styled.View``;
const ReviewereName = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
  color: ${({theme}) => theme.colors.prapGray1};
`;
const ReviewData = styled(Text)`
  align-self: flex-end;
  font-size: ${({theme}) => theme.fonts.body10};
  color: ${({theme}) => theme.colors.prapGray2};
  margin-left: 8px;
`;
const ProductImageRatingArea = styled.View`
  margin: 8px;
  align-self: flex-start;
`;
const StyledImage = styled.Image`
  width: 100px;
  height: 100px;
  margin-top: 8px;
`;
const ReviewInfoArea = styled.View`
  flex: 1;
  flex-direction: row;
`;
const ReviewInfoLabelArea = styled.View`
  margin-right: 24px;
`;
const ReviewInfoLabel = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
  color: ${({theme}) => theme.colors.prapGray2};
  margin-bottom: 4px;
`;
const ReviewInfoValueArea = styled.View``;
const ReviewInfoValue = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
  color: ${({theme}) => theme.colors.prapGray1};
  margin-bottom: 4px;
`;
const ReviewFeedbackArea = styled.View`
  margin-top: 16px;
`;
const ReviewFeedbackText = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
  color: ${({theme}) => theme.colors.prapGray1};
`;

const ReviewTagsForm = styled.View`
  padding: 6px;
  align-self: flex-start;
  background-color: ${({theme}) => theme.colors.prapGray6};
  border-radius: 4px;
  margin-bottom: 16px;
`;
const ReviewTagText = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body14};
  color: ${({theme}) => theme.colors.prapGray1};
`;

const ReviewValueForm = styled(ReviewTagsForm)`
  background: #fff;
`;
const ReivewValueText = styled(ReviewTagText)`
  font-size: ${({theme}) => theme.fonts.body16};
`;
const ReviewTagsTitle = styled.View`
  margin-right: 44px;
`;
const ReviewTagsValue = styled.View`
  flex: 1;
  align-items: center;
  justify-content: center;
`;

const StyledReviewScreen = styled.ScrollView`
  flex: 1;
`;

const ReviewStickyHeader = styled.View`
  padding: 15px;
  background: #fff;
`;

const ReviewRatingArea = styled.View`
  flex-direction: row;
`;
const ReviewRatingText = styled(Text)`
  font-size: ${({theme}) => theme.fonts.headline36};
  color: ${({theme}) => theme.colors.prapGray1};
  margin-right: 12px;
`;
const ReviewTotal = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
  color: ${({theme}) => theme.colors.prapGray2};
`;

const ReviewTagsArea = styled.View`
  padding: 15px;
  flex-direction: row;
  flex: 1;
  align-items: center;
`;

const ItemSeparatorBold = styled.View`
  height: 12px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;
const ItemSeparatorThin = styled.View`
  height: 1px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;
