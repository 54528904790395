import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgReviewIcon = props => (
  <Svg
    width={32}
    height={32}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="m24.485 9.4-.943.943-1.885-1.885.943-.943L24.485 9.4Zm3.771 0L22.6 3.745l-4.714 4.714 5.656 5.657L28.256 9.4Z"
      fill="#FEFEFE"
    />
    <Path
      d="m21.657 12.229-9.74 9.739-2.205.32.32-2.206 9.74-9.739 1.885 1.886Zm3.771 0-5.657-5.657L7.515 18.828l-.943 6.6 6.6-.943L25.428 12.23Z"
      fill="#FEFEFE"
    />
  </Svg>
);

export default SvgReviewIcon;
