import styled from '@emotion/native';
import {CustomText as Text} from '../../Home/CustomText';

export const Progress = ({
  value,
  extra,
  status,
}: {
  value: number;
  extra?: string;
  status?: string;
}) => {
  return (
    <StyledProgress>
      <ProgressMax>
        <ProgressValue value={value / 100} />
      </ProgressMax>
      <ProgressCaption>
        <ProgressCaptionValue>{Math.round(value)}%</ProgressCaptionValue>
        <ProgressCaptionExtra>{extra}</ProgressCaptionExtra>
        <ProgressCaptionStatus>{status}</ProgressCaptionStatus>
      </ProgressCaption>
    </StyledProgress>
  );
};

const StyledProgress = styled.View`
  align-items: stretch;
  overflow: hidden;
`;

const ProgressMax = styled.View`
  height: 4px;
  width: 100%;
  border-radius: 2px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;

const ProgressValue = styled.View<{value: number}>`
  height: 4px;
  width: ${({value}) => String(value * 100)}%;
  border-radius: 2px;
  background-color: ${({theme}) => theme.colors.prapBlue3};
`;

const ProgressCaption = styled.View`
  margin-top: 4px;
  flex-direction: row;
  align-items: flex-start;
`;

const ProgressCaptionValue = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapBlue2};
`;

const ProgressCaptionExtra = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray3};
  margin-left: 8px;
  margin-right: 8px;
  flex: 1;
`;

const ProgressCaptionStatus = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  color: ${({theme}) => theme.colors.prapGray3};
`;
