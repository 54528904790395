import axios from 'axios';
import {useMutation, useQueryClient} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

interface ScrapItem {
  id?: number;
  category?: string;
  deleteId?: number;
}

/// POST SCRAP ITEM

const postScrapItem = async (addItem: ScrapItem) => {
  const response = await axios
    .post(`${PRAP_API_ENDPOINT}/scrap/`, addItem, {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    })
    .catch(error => {
      console.log('postScrapItem:', 'error.config:', error.config);
    });

  return response;
};

export const useScrapPostMutation = () => {
  const queryClient = useQueryClient();
  return useMutation(postScrapItem, {
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries([
        `${USER_HASH_ID}_clip`,
        variable.category,
      ]);
      queryClient.invalidateQueries([
        `${variable.category}_filtered`,
        variable.category,
      ]);
    },
    onError: error => {
      console.error(error);
    },
  });
};

/// DELETE SCRAP ITEM

const deleteScrapItem = async (deleteItem: ScrapItem) => {
  console.log(deleteItem);
  const response = await axios
    .delete(`${PRAP_API_ENDPOINT}/scrap/${deleteItem.deleteId}/`, {
      headers: {
        Authorization: `Bearer ${USER_HASH_ID}`,
      },
    })
    .catch(error => {
      console.log('deleteScrapItem:', 'error:', error);
    });

  return response;
};

export const useScrapDeleteMutation = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteScrapItem, {
    onMutate: async item => {
      await queryClient.cancelQueries(USER_HASH_ID);
    },
    onSuccess: (data, variable) => {
      queryClient.invalidateQueries([
        `${USER_HASH_ID}_clip`,
        variable.category,
      ]);
      queryClient.invalidateQueries([
        `${variable.category}_filtered`,
        variable.category,
      ]);

      return;
    },
    onError: error => {
      console.error(error);
    },
  });
};
