import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useEffect} from 'react';
import {Linking, View} from 'react-native';
import CircularProgress from 'react-native-circular-progress-indicator';
import {StackParamList} from '../App';
import {useInvestmentViewMutation} from '../Clippings/clippingApi/useInvestmentViewMutation';
import {useRecentlyPostMutation} from '../Clippings/clippingApi/useRecentlyItemMutation';
import {CustomText as Text} from '../Home/CustomText';
export default function BridgeScreen({
  route,
  navigation,
}: NativeStackScreenProps<StackParamList, 'Bridge'>) {
  const {params} = route;

  const postRecentlyItem = useRecentlyPostMutation();
  const investmentView = useInvestmentViewMutation();

  useEffect(() => {
    postRecentlyItem.mutate({
      category: params.category,
      id: parseInt(params.id),
    });
    investmentView.mutate({
      category: params.category,
      id: parseInt(params.id),
    });
  }, []);

  const onProgressEnd = () => {
    params.url ? Linking.openURL(params.url) : null;
    navigation.goBack();
  };

  console.log(params.platform);

  return (
    <StyledLoadingScreen>
      <ProgressContainer>
        <CircularProgress
          value={100}
          radius={70}
          duration={2000}
          activeStrokeWidth={20}
          activeStrokeColor={'#3A56E9'}
          inActiveStrokeWidth={20}
          inActiveStrokeColor={'#000'}
          inActiveStrokeOpacity={0.1}
          progressValueColor={'#3A56E9'}
          progressValueFontSize={20}
          valueSuffix={'%'}
          onAnimationComplete={onProgressEnd}
        />
      </ProgressContainer>
      <RedirectMessageContainer>
        <HeaderMessageWrap>
          <HeaderMessage>{params.platform}로 이동 중입니다.</HeaderMessage>
        </HeaderMessageWrap>
        <DetailMessageWrap>
          <DetailMessage>
            프랩은 투자 상품 판매에 직접 관여하지 않습니다.{'\n'}
            프랩에서 제공하는 상품 정보와 가격은 플랫폼과 일치하지{'\n'}
            않을 수 있으므로 주문 시 확인해주세요.
          </DetailMessage>
        </DetailMessageWrap>
      </RedirectMessageContainer>
    </StyledLoadingScreen>
  );
}

const StyledLoadingScreen = styled(View)`
  flex: 1;
  background: ${({theme}) => theme.colors.prapGray6};
`;
const ProgressContainer = styled.View`
  flex: 1.5;
  align-items: center;
  justify-content: center;
`;

const RedirectMessageContainer = styled.View`
  flex: 1;
  padding: 0 40px 0 30px;
`;
const HeaderMessageWrap = styled.View``;
const HeaderMessage = styled(Text)`
  color: ${({theme}) => theme.colors.prapBlue2};
  font-size: ${({theme}) => theme.fonts.headline24};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const DetailMessageWrap = styled.View`
  padding: 12px 0;
  flex-direction: row;
`;
const DetailMessage = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray3};
  font-size: ${({theme}) => theme.fonts.body12};
  flex-wrap: wrap;
  flex: 1;
`;
