import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {
  PRAP_API_ENDPOINT,
  USER_HASH_ID,
} from '../../Investments/InvestmentAPI/constants';

const getPlatformRanking = async ({
  page = 1,
  userId,
}: {
  page?: number;
  userId?: string;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/platform-ranking/`;

  const {data} = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${USER_HASH_ID}`,
    },
  });

  return {result: data, next: null};
};

export function usePlatformList() {
  return useInfiniteQuery(
    [`platform-ranking`],
    ({pageParam = 1}) =>
      getPlatformRanking({
        page: pageParam,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response?.next) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
}
