import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgClippingIcon = props => (
  <Svg
    width={16}
    height={16}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M12.667 14.666 8 12l-4.667 2.666V2a.727.727 0 0 1 .78-.667h7.774a.727.727 0 0 1 .78.667v12.666Z"
      fill="#8295F6"
    />
  </Svg>
);

export default SvgClippingIcon;
