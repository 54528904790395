import styled from '@emotion/native';
import {MaterialTopTabBarProps} from '@react-navigation/material-top-tabs';
import {CustomText as Text} from '../Home/CustomText';

export default function CategoryTabBar({
  state,
  descriptors,
  navigation,
}: MaterialTopTabBarProps) {
  const menu = state.routes.map((route, index) => {
    const {options} = descriptors[route.key];
    const label =
      options.tabBarLabel !== undefined
        ? options.tabBarLabel
        : options.title !== undefined
        ? options.title
        : route.name;
    const isFocused = state.index === index;

    const onPress = () => {
      const event = navigation.emit({
        type: 'tabPress',
        target: route.key,
        canPreventDefault: true,
      });

      if (!isFocused && !event.defaultPrevented) {
        navigation.navigate(route.name);
      }
    };

    return (
      <ScrollTabItemForm
        onPress={onPress}
        isActive={isFocused}
        key={route.name}>
        <ScrollItemText isActive={isFocused}>{label}</ScrollItemText>
      </ScrollTabItemForm>
    );
  });
  return (
    <ScrollTabWrap>
      <ScrollTab horizontal showsHorizontalScrollIndicator={false}>
        {menu}
      </ScrollTab>
    </ScrollTabWrap>
  );
}

const ScrollTabWrap = styled.View`
  padding: 16px 0 8px 16px;
  background: #fff;
`;

const ScrollTab = styled.ScrollView`
  flex-direction: row;
`;

const ScrollTabItemForm = styled.TouchableOpacity<{isActive: boolean}>`
  font-size: 12px;
  background: ${({theme, isActive}) =>
    isActive ? theme.colors.prapBlue4 : theme.colors.prapGray6};

  padding: 6px 16px;
  margin-right: 8px;
  border-radius: 20px;
`;
const ScrollItemText = styled(Text)<{isActive: boolean}>`
  color: ${({theme, isActive}) =>
    isActive
      ? process.env.FLAVOR === 'namuh' || process.env.FLAVOR === 'qv'
        ? theme.colors.infoWhite
        : theme.colors.prapBlue1
      : theme.colors.prapGray2};
`;
