import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {useEffect, useState} from 'react';
import {Pressable} from 'react-native';
import {Snackbar} from 'react-native-paper';
import {AirbnbRating} from 'react-native-ratings';
import {StackParamList} from '../App';
import ClippingIcon from '../ClippingSnackbar/ClippingIcon';
import {CustomText as Text} from '../Home/CustomText';
import {engToKor} from '../Investments/InvestmentAPI/translate';
import MediumInvestment from '../Investments/MediumInvestment';
import LinkPressable from '../LinkPressable/LinkPressable';
import {
  useFollowDeleteMutation,
  useFollowPostMutation,
} from './api/useFollowPlatformMutation';
import {usePlatformInfo} from './api/usePlatformInfo';
import SvgAddPlatformIcon from './icon/AddPlatformIcon';
import SvgDeletePlatformIcon from './icon/DeletePlatformIcon';
import PlatformInfo from './icon/PlatformInfo';

const StyledLinkToReview = styled(LinkPressable)`
  margin-top: 12px;
`;

const LinkToReviewArea = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 135px;
  padding: 10px 0;
  background: ${({theme}) => theme.colors.prapGray5};
  border-radius: 30px;
  margin: 0 8px;
`;

const LinkToReviewText = styled(Text)`
  font-size: ${({theme}) => theme.fonts.body12};
  color: ${({theme}) => theme.colors.prapGray1};
  margin-right: 2px;
  font-family: 'SpoqaHanSansNeo-Bold';
`;

export default function PlatformDetailScreen({
  route,
}: NativeStackScreenProps<StackParamList, 'PlatformDetails'>) {
  const {id} = route.params;
  const platformInfoData = usePlatformInfo(parseInt(id));
  const postFollow = useFollowPostMutation();
  const deleteFollow = useFollowDeleteMutation();

  const theme = useTheme();

  const [toggle, setToggle] = useState(false);
  const onToggleSnackbar = () => setToggle(!toggle);
  const onDismissSnackbar = () => setToggle(false);
  const [isFollow, setFollow] = useState(
    platformInfoData.data?.pages[0].results.followId ? true : false,
  );

  console.log(platformInfoData.data?.pages[0].results.followId ? true : false);

  useEffect(() => {
    setFollow(platformInfoData.data?.pages[0].results.followId ? true : false);
  }, [platformInfoData]);

  if (platformInfoData.isLoading) {
    return <></>;
  }
  if (platformInfoData.isError) {
    return <></>;
  }

  const {name, descriptions, image, followId, category, star, reviewCount} =
    platformInfoData.data?.pages[0].results;
  const depthData = platformInfoData.data?.pages[0].results.data;
  const platformData = platformInfoData.data?.pages[0].results;

  const addPlatformFollow = (platformId: string) => {
    console.log(platformData);
    postFollow.mutate({platform: platformId});
  };

  const deletePlatformFollow = (deleteId: number) => {
    deleteFollow.mutate({deleteId: deleteId});
  };

  return (
    <>
      <StyledPlatformDetailView>
        <PlatformInfoContainer>
          <PlatformInfoWrap>
            <PlatformLogoWrap>
              <PlatformLogo source={{uri: image}} />
            </PlatformLogoWrap>
            <PlatformNameArea>
              <PlatformName>{engToKor[name]}</PlatformName>

              <PlatformInfoButtonArea>
                <Pressable>
                  <PlatformInfo />
                </Pressable>
              </PlatformInfoButtonArea>
            </PlatformNameArea>
            <PlatformRatingWrap>
              <AirbnbRating
                count={1}
                defaultRating={1}
                size={20}
                isDisabled={true}
                showRating={false}
              />
              <RatingText>
                {star} <ReviewCount>({reviewCount})</ReviewCount>
              </RatingText>
            </PlatformRatingWrap>
            <PlatformCTACombo>
              <StyledLinkToReview
                to={{
                  screen: 'Review',
                  params: {name: name, id: id},
                }}>
                <LinkToReviewArea>
                  <LinkToReviewText>리뷰 보기</LinkToReviewText>
                </LinkToReviewArea>
              </StyledLinkToReview>
              <FollowPlatformArea isFollow={isFollow}>
                {isFollow ? (
                  <Pressable
                    onPress={() => deletePlatformFollow(platformData.followId)}
                    style={{flexDirection: 'row'}}>
                    <SvgDeletePlatformIcon />
                    <FollowPlatformText isFollow={isFollow}>
                      팔로잉
                    </FollowPlatformText>
                  </Pressable>
                ) : (
                  <Pressable
                    onPress={() => addPlatformFollow(platformData.name)}
                    style={{flexDirection: 'row'}}>
                    <SvgAddPlatformIcon />
                    <FollowPlatformText isFollow={isFollow}>
                      팔로우
                    </FollowPlatformText>
                  </Pressable>
                )}
              </FollowPlatformArea>
            </PlatformCTACombo>
          </PlatformInfoWrap>
          <PlatformDescWrap>
            <PlatformDescHead>
              <PlatformDescHeadText>플랫폼 소개</PlatformDescHeadText>
              <PlatformCategoryForm>
                <PlatformCategoryText>
                  {engToKor[category]}
                </PlatformCategoryText>
              </PlatformCategoryForm>
            </PlatformDescHead>
            <PlatformDesc>{descriptions}</PlatformDesc>
          </PlatformDescWrap>
        </PlatformInfoContainer>
        <ItemSeparatorBold />

        <PlatformProductHead>플랫폼 상품</PlatformProductHead>

        <PlatformItemView
          data={depthData}
          renderItem={({item, index}) => {
            return (
              <MediumInvestment
                investment={item}
                productType={item.category}
                snackbarVisible={onToggleSnackbar}
              />
            );
          }}
          keyExtractor={item => item.id}
          ListEmptyComponent={<></>}
          ItemSeparatorComponent={ItemSeparatorThin}
          onEndReached={() =>
            platformInfoData.hasNextPage
              ? platformInfoData.fetchNextPage()
              : null
          }
          onEndReachedThreshold={0.5}
        />
      </StyledPlatformDetailView>

      <Snackbar
        visible={toggle}
        onDismiss={onDismissSnackbar}
        action={{
          label: '스크랩 바로가기',
          onPress: () => {
            // Do something
          },
          color: theme.colors.infoWhite,
          labelStyle: {
            fontSize: 11,
          },
        }}
        style={{
          backgroundColor: theme.colors.prapGray2,
          width: 270,
        }}
        wrapperStyle={{
          marginBottom: 60,
        }}>
        <SnackbarIconView>
          <ClippingIcon />
        </SnackbarIconView>
        스크랩 했습니다.
      </Snackbar>
    </>
  );
}

const PlatformCTACombo = styled.View`
  flex-direction: row;
`;

const FollowPlatformArea = styled.View<{isFollow: boolean}>`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 135px;
  padding: 10px 0;
  background: ${({theme, isFollow}) =>
    isFollow ? theme.colors.bgGray : theme.colors.prapBlue1};
  border-radius: 30px;
  margin: 12px 8px 0 8px;
  border: ${({theme, isFollow}) =>
    isFollow
      ? `1px solid ${theme.colors.prapGray5}`
      : `1px solid ${theme.colors.prapBlue1}`};
`;
const FollowPlatformText = styled(Text)<{isFollow: boolean}>`
  font-size: ${({theme}) => theme.fonts.body12};
  color: ${({theme, isFollow}) =>
    isFollow ? theme.colors.prapGray1 : theme.colors.infoWhite};
  margin-left: 4px;
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const SnackbarContentView = styled.View`
  position: absolute;
  top: 300px;
  z-index: 999;
`;

const SnackbarIconView = styled.View`
  margin-right: 8px;
`;

const PlatformNameArea = styled.View`
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
`;

const PlatformInfoButtonArea = styled.View`
  position: absolute;
  right: -15px;
`;

const StyledPlatformDetailView = styled.ScrollView`
  flex: 1;
`;

const PlatformInfoContainer = styled.View`
  margin-bottom: 24px;
`;

const PlatformInfoWrap = styled.View`
  align-items: center;
`;

const PlatformLogoWrap = styled.View`
  width: 92px;
  height: 92px;
  align-items: center;
  justify-content: center;
  border-radius: 60px;
  border: ${({theme}) => theme.colors.prapGray6};
  background: #fff;
  margin-top: 16px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
`;
const PlatformLogo = styled.Image`
  width: 50%;
  height: 50%;
`;
const PlatformCategoryForm = styled.View`
  padding: 4px 10px;
  background: ${({theme}) => theme.colors.prapBlue4};
  border-radius: 20px;
  margin-left: 12px;
`;
const PlatformCategoryText = styled(Text)`
  color: ${({theme}) => theme.colors.infoWhite};
  font-size: ${({theme}) => theme.fonts.body11};
`;
const PlatformName = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.headline18};
  margin-right: 4px;
`;
const PlatformRatingWrap = styled.View`
  margin-top: 8px;
  align-items: center;
  flex-direction: row;
`;
const RatingText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.body16};
  margin-right: 4px;
`;
const ReviewCount = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray3};
  font-size: ${({theme}) => theme.fonts.body13};
`;
const PlatformDescWrap = styled.View`
  margin: 0 15px;
  margin-top: 20px;
`;
const PlatformDescHead = styled.View`
  flex-direction: row;
  align-items: center;
`;
const PlatformDescHeadText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.headline18};
`;
const PlatformProductHead = styled(PlatformDescHeadText)`
  margin: 8px 15px;
`;
const PlatformDesc = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.body13};
  margin-top: 16px;
`;

const ItemSeparatorBold = styled.View`
  height: 12px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;
const ItemSeparatorThin = styled.View`
  height: 1px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;

const PlatformItemView = styled.FlatList``;
