import axios from 'axios';
import {useInfiniteQuery} from 'react-query';
import {PRAP_API_ENDPOINT} from '../../Investments/InvestmentAPI/constants';

const getFollowPlatform = async ({
  page = 1,
  userId,
}: {
  page?: number;
  userId?: string;
}) => {
  const ENDPOINT = `${PRAP_API_ENDPOINT}/platform-following/`;

  const {data} = await axios.get(`${ENDPOINT}`, {
    headers: {
      Authorization: `Bearer ${userId}`,
    },
  });

  return {result: data, next: null};
};

export function useFollowPlatform(userId: string) {
  return useInfiniteQuery(
    [`follow_platforms`],
    ({pageParam = 1}) =>
      getFollowPlatform({
        page: pageParam,
        userId: userId,
      }),
    {
      getNextPageParam: (response, pages) => {
        if (response?.next) {
          return undefined;
        } else {
          return pages.length + 1;
        }
      },
    },
  );
}
