import styled from '@emotion/native';
import {createMaterialTopTabNavigator} from '@react-navigation/material-top-tabs';
import {PathConfig} from '@react-navigation/native';
import React from 'react';
import {SafeAreaView} from 'react-native-safe-area-context';
import FollowingScreen from './FollowingScreen';
import RankingTabScreen, {
  RankingTabParamList,
  rankingTabPathConfig,
} from './RankingTabScreen';

export type PlatformsTabParamList = {
  Following: undefined;
  RankingTab: RankingTabParamList;
};

export const platformsTabPathConfig: PathConfig<PlatformsTabParamList> = {
  screens: {
    Following: 'platform-following',
    RankingTab: rankingTabPathConfig,
  },
};

const PlatformsTab = createMaterialTopTabNavigator<PlatformsTabParamList>();

export default function PlatformsTabScreen() {
  return (
    <StyledSafeAreaView edges={['left', 'right']}>
      <PlatformsTab.Navigator>
        <PlatformsTab.Screen
          name="Following"
          component={FollowingScreen}
          options={{title: '팔로잉'}}
        />
        <PlatformsTab.Screen
          name="RankingTab"
          component={RankingTabScreen}
          options={{title: '랭킹'}}
        />
      </PlatformsTab.Navigator>
    </StyledSafeAreaView>
  );
}

const StyledSafeAreaView = styled(SafeAreaView)`
  flex: 1;
`;
