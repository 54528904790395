import * as React from 'react';
import Svg, {Path} from 'react-native-svg';

const SvgP2PIcon = props => (
  <Svg
    width={44}
    height={44}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <Path
      d="M29.203 21.294c2.762 0 5.002-2.232 5.002-4.985s-2.24-4.985-5.002-4.985c-2.763 0-5.003 2.232-5.003 4.985s2.24 4.985 5.003 4.985ZM37.714 40.333c.695 0 1.361-.275 1.852-.764.491-.49.767-1.154.767-1.846 0-4.189-.248-10.975-1.218-11.849-1.244-1.11-6.861-2.336-9.9-2.336-3.038 0-8.655 1.227-9.9 2.336-.969.874-1.178 7.66-1.217 11.85 0 .692.276 1.355.767 1.845.49.49 1.157.764 1.852.764h16.997Z"
      fill="#B8C9F2"
    />
    <Path
      d="M14.798 16.387c2.763 0 5.003-2.232 5.003-4.986 0-2.753-2.24-4.984-5.003-4.984-2.762 0-5.002 2.231-5.002 4.985 0 2.753 2.24 4.985 5.002 4.985Z"
      fill="#C1D3FF"
    />
    <Path
      d="M23.31 40.333c.694 0 1.36-.275 1.852-.765.49-.49.767-1.153.767-1.845 0-5.338-.21-15.869-1.218-16.782-1.244-1.11-6.862-2.323-9.9-2.323-3.038 0-8.656 1.213-9.9 2.323-1.021.9-1.244 11.431-1.244 16.782 0 .692.276 1.356.767 1.845.491.49 1.157.765 1.852.765H23.31Z"
      fill="#B8C9F2"
    />
    <Path
      d="M19.302 25.848c-1.23 1.109-1.23 11.745-1.23 14.485h7.856c0-2.114 0-11.51-.537-16.43-2.605.457-5.277 1.227-6.089 1.945Z"
      fill="#ABA5E3"
    />
  </Svg>
);

export default SvgP2PIcon;
