import styled from '@emotion/native';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import {useAtom, useSetAtom} from 'jotai';

import React, {useEffect, useState} from 'react';
import CarouselView from '../CarouselView/CarouselView';
import {
  asyncFractionalItemAtom,
  asyncP2PItemAtom,
  asyncScrapItemAtom,
  asyncSneakersItemAtom,
  asyncStartupItemAtom,
  asyncUnlistedItemAtom,
} from '../Clippings/clippingApi/atoms';
import {useUserClipItem} from '../Clippings/clippingApi/useUserClipItem';
import {CustomText as Text} from '../Home/CustomText';
import {USER_HASH_ID} from '../Investments/InvestmentAPI/constants';
import LinkPressable from '../LinkPressable/LinkPressable';
import {Curation} from './Curation';
import {HomeTopTabParamList} from './HomeTopTabScreen';
import P2PIcon from './icon/P2PIcon';
import PieceIcon from './icon/PieceIcon';
import SneakersIcon from './icon/SneakersIcon';
import StartupIcon from './icon/StartupIcon';
import UnlistedStockIcon from './icon/UnlistedStockIcon';
import {InvestmentSegment} from './InvestmentSegment';

export default function HomeScreen({}: NativeStackScreenProps<
  HomeTopTabParamList,
  'Home'
>) {
  // const fetchScrapItems = () => {
  const [loading, setLoading] = useState(true);

  const sneakers = useUserClipItem('sneakers', USER_HASH_ID);
  const p2p = useUserClipItem('p2p-investments', USER_HASH_ID);
  const fractional = useUserClipItem('fractional-investments', USER_HASH_ID);
  const startup = useUserClipItem('startups', USER_HASH_ID);
  const unlisted = useUserClipItem('unlisted-stocks', USER_HASH_ID);

  const [scrap] = useAtom(asyncScrapItemAtom);
  const setSneakers = useSetAtom(asyncSneakersItemAtom);
  const setP2P = useSetAtom(asyncP2PItemAtom);
  const setFractional = useSetAtom(asyncFractionalItemAtom);
  const setStartup = useSetAtom(asyncStartupItemAtom);
  const setUnlisted = useSetAtom(asyncUnlistedItemAtom);

  console.log(scrap);

  useEffect(() => {
    // console.log(sneakers);
    // setSneakers(sneakers);
    // setP2P(p2p);
    // setFractional(fractional);
    // setStartup(startup);
    // setUnlisted(unlisted);
  }, []);

  useEffect(() => {
    setSneakers(sneakers);
  }, [sneakers.data]);

  useEffect(() => {
    setP2P(p2p);
  }, [p2p.data]);

  useEffect(() => {
    setFractional(fractional);
  }, [fractional.data]);

  useEffect(() => {
    setStartup(startup);
  }, [startup.data]);

  useEffect(() => {
    setUnlisted(unlisted);
  }, [unlisted.data]);

  if (
    sneakers.isSuccess &&
    p2p.isSuccess &&
    fractional.isSuccess &&
    startup.isSuccess &&
    unlisted.isSuccess
  ) {
    return (
      <StyledInvestmentSegment
        segment="popular"
        ListHeaderComponent={
          <>
            <CarouselView auto={true} />
            <Heading>
              <Accent>관심 가는 투자상품</Accent>을 선택해보세요!
            </Heading>
            <Categories>
              <StyledCategoryLink to="/fractional-investments">
                <PieceIcon />
                <CategoryName>조각투자</CategoryName>
              </StyledCategoryLink>
              <StyledCategoryLink to="/sneakers">
                <SneakersIcon />
                <CategoryName>스니커즈리셀</CategoryName>
              </StyledCategoryLink>
              <StyledCategoryLink to="/startups">
                <StartupIcon />
                <CategoryName>스타트업펀딩</CategoryName>
              </StyledCategoryLink>
              <StyledCategoryLink to="/p2p-investments">
                <P2PIcon />
                <CategoryName>P2P투자</CategoryName>
              </StyledCategoryLink>
              <StyledCategoryLink to="/unlisted-stocks">
                <UnlistedStockIcon />
                <CategoryName>비상장주식</CategoryName>
              </StyledCategoryLink>
            </Categories>
            {/* <Curations>
          <Heading>
            실시간 <Accent>TOP10</Accent>
          </Heading>
          <CarouselView pagesPerView={2.75} gap={16} indicatorDisabled />
        </Curations> */}

            <Curation
              heading={
                <>
                  따끈따끈,{'\n'}
                  <Accent>새로 등록</Accent>된 조각투자 상품
                </>
              }
              fetchType={'fractional-investments'}
              id={3}
            />
            <Curation
              heading={
                <>
                  라이징 스타,{'\n'}
                  <Accent>떠오르는 </Accent>유니콘 기업
                </>
              }
              fetchType={'unlisted-stocks'}
              id={1}
            />
            <Curation
              heading={
                <>
                  100만 원 이하,{'\n'}
                  <Accent>스니커즈리셀</Accent> 인기 상품
                </>
              }
              fetchType={'sneakers'}
              id={2}
            />
            <Heading>투자상품 별 인기 상품</Heading>
          </>
        }
      />
    );
  } else {
    return <></>;
  }
}

const StyledInvestmentSegment = styled(InvestmentSegment)`
  flex: 1;
  background-color: #f7f8f9;
`;

const Categories = styled.View`
  flex-direction: row;
  justify-content: space-evenly;
`;

const StyledCategoryLink = styled(LinkPressable)`
  margin-top: 12px;
  align-items: center;
`;

const CategoryName = styled(Text)`
  ${({theme}) => theme.fonts.body11};
  ${({theme}) => theme.colors.prapGray1};
  margin-top: 8px;
`;

const Heading = styled(Text)`
  ${({theme}) => theme.fonts.headline18};
  ${({theme}) => theme.colors.prapGray1};
  margin: 0 16px;
  margin-top: 24px;
  font-family: 'SpoqaHanSansNeo-Bold';
`;

const Accent = styled(Text)`
  color: ${({theme}) => theme.colors.prapBlue2};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
