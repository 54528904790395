import styled from '@emotion/native';
import {useTheme} from '@emotion/react';
import {NativeStackScreenProps} from '@react-navigation/native-stack';
import React, {Dispatch, SetStateAction, useState} from 'react';
import {AirbnbRating} from 'react-native-ratings';
import {StackParamList} from '../App';
import {CustomText as Text} from '../Home/CustomText';
import {useReviewPostMutation} from './api/useReviewWriteMutation';

export default function WriteReviewScreen({
  route,
  navigation,
}: NativeStackScreenProps<StackParamList, 'WriteReview'>) {
  const {name} = route.params;

  const [rating, setRating] = useState(4);
  const [feedback, setFeedback] = useState('');

  const postUserReview = useReviewPostMutation();

  const addReview = async () => {
    await postUserReview.mutate({
      platform: name,
      content: feedback,
      star: rating,
    });
    navigation.navigate('Review', {name});
  };
  console.log(postUserReview);

  const theme = useTheme();
  return (
    <StyledWriteReviewView>
      <SelectProductForRating rating={rating} setRating={setRating} />
      <FeedbackForm feedback={feedback} setFeedback={setFeedback} />
      <SubmitArea>
        <SubmitCTA onPress={() => addReview()}>
          <SubmitText>리뷰 작성 완료</SubmitText>
        </SubmitCTA>
      </SubmitArea>
    </StyledWriteReviewView>
  );
}

const DismissBackground = styled.Pressable`
  background: #000;
  flex: 1;
  width: 100%;
`;

const SubmitCTA = styled.TouchableOpacity`
  height: 48px;
  padding: 14px 40px;
  background-color: ${({theme}) => theme.colors.prapBlue2};
  border-radius: 50px;
  align-items: center;

  width: 200px;
`;
const SubmitText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray6};
  font-size: ${({theme}) => theme.fonts.body16};
  font-family: 'SpoqaHanSansNeo-Bold';
`;

const SubmitArea = styled.View`
  margin-top: 24px;
  margin-bottom: 40px;
  align-items: center;
`;
const ItemSeparatorBold = styled.View`
  height: 12px;
  background-color: ${({theme}) => theme.colors.prapGray6};
`;

const StyledWriteReviewView = styled.ScrollView`
  flex: 1;
`;

const SelectProductForRating = ({
  rating,
  setRating,
}: {
  rating: number;
  setRating: Dispatch<SetStateAction<number>>;
}) => {
  return (
    <SetProductRatingArea>
      <StyledText>투자에 만족하셨나요?</StyledText>
      <AirbnbRating
        count={5}
        size={28}
        isDisabled={false}
        showRating={false}
        onFinishRating={rating => setRating(rating)}
        ratingContainerStyle={{
          marginBottom: 20,
          marginTop: 12,
        }}
      />
    </SetProductRatingArea>
  );
};

const FeedbackForm = ({
  feedback,
  setFeedback,
}: {
  feedback: string;
  setFeedback: Dispatch<SetStateAction<string>>;
}) => {
  return (
    <ReviewOpinionArea>
      <ReviewOpinionText>투자 상품에 대한 의견을 남겨주세요!</ReviewOpinionText>
      <ReviewOpinionTextInput
        multiline
        numberOfLines={7}
        onChangeText={text => setFeedback(text)}
        autoFocus={false}
        autoCorrect={false}
      />
    </ReviewOpinionArea>
  );
};

const SetProductRatingArea = styled.View`
  align-items: center;
`;
const SelectProduct = styled.Pressable`
  width: 330px;
  padding: 13px 0;
  padding-left: 16px;

  margin-top: 24px;
  border-radius: 4px;
  background-color: ${({theme}) => theme.colors.prapGray6};
  flex-direction: row;
`;
const SelectDropdownArea = styled.View`
  position: absolute;
  right: 0;

  padding: 12px 0;
  padding-right: 16px;
`;
const SelectedProduct = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray3};
  font-size: ${({theme}) => theme.fonts.body13};
`;
const StyledText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.body16};

  margin-top: 32px;
`;

const ReviewQuestionArea = styled.View`
  padding: 0 20px;
  padding-top: 24px;
`;
const ReviewQuestionText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.headline18};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const ReviewAnswerText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.body13};
`;
const ReviewRadioButtonArea = styled.View`
  flex-direction: row;
  justify-content: center;
  margin-top: 14px;
`;
const ReviewAnswerArea = styled.View`
  align-items: center;
  padding: 0 16px;
  margin: 0 16px;
`;

const ReviewOpinionArea = styled.View`
  padding: 0 20px;
  margin-top: 24px;
`;
const ReviewOpinionText = styled(Text)`
  color: ${({theme}) => theme.colors.prapGray1};
  font-size: ${({theme}) => theme.fonts.body16};
  font-family: 'SpoqaHanSansNeo-Bold';
`;
const ReviewOpinionTextInput = styled.TextInput`
  margin-top: 16px;
  background-color: ${({theme}) => theme.colors.prapGray6};
  border-radius: 7px;
  padding: 16px;
`;
